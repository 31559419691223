import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import { UserContext } from "../Contexts/userContext";
import Cookies from "js-cookie";
import { partnerListTabs, partnersTabMap } from "../utils/constants";
import { useGridNavigation } from "../CustomHooks/useGridNavigation";
import Image from "../Components/Image";
import Loader from "../Components/loader";
import { activeBtnColor, activeElementBorderColor } from "../utils/helper";
import Title from "../Components/titleHeading";

const PartnerList = ({ type = "partners" }) => {
  const { end_point = '', section = '', template_name = '', title = '', seo_url = '',pin_it=true } = window?.history?.state;
  let pageName;
  let providerApi = Cookies.get('providerApi')
  const [page, setPage] = useState(1);
  const currentRef = useRef(false);
  let cur = useRef(0);
  const [activeTab, setactiveTab] = useState("your_plan_ott");
  const User = useContext(UserContext);
  const [clientId, setClientId] = useState(Cookies.get("clientId") || "");
  const [planName, setPlanName] = useState(User?.user?.planName || "Subscribe");
  const [data, setData] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tabNo, setTabNo] = useState(0);
  const loadMoreRef = useRef(false);
  loadMoreRef.current = loadMore;
  const activeTabRef = useRef(partnerListTabs[tabNo]);
  const tabNoRef = useRef(tabNo);
  tabNoRef.current = tabNo;

  function handleTabChange(tabDir) {
    if (tabDir === "right") {
      setData([]);
      setPage(1);
      tabNo <= partnerListTabs.length - 1 &&
        setTabNo((prev) => parseInt(prev) + 1);
    } else if (tabDir === "left") {
      setData([]);
      setPage(1);
      tabNo >= 0 && setTabNo((prev) => parseInt(prev) - 1);
    }
  }
  activeTabRef.current = partnerListTabs[tabNo];
  useGridNavigation(
    pageName='partnerlist',
    cur,
    tabNoRef.current,
    partnerListTabs.length,
    handleTabChange,
    setPage,
    activeTabRef.current,
    data.length ? data.length : 20
  );

  useEffect(() => {
    ( () => {
       getPlanData();
      document.addEventListener("keydown", scrollIntoSreenView);
    })();
    return () => {
      document.removeEventListener("keydown", scrollIntoSreenView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, activeTabRef.current]);

  function scrollIntoSreenView(e) {
    let ele = document.querySelector("[nav-selected=true]");
    ele.scrollIntoView(false);
  }

  const getPlanData =  (value) => {
    setLoading(true);
    setLoadMore(false);
    fetch(
      providerApi,
      clientId && activeTabRef.current === "your_plan_ott"
        ? { headers: { client_id: clientId ,authorization:'bearer 34B7FBD24B97D7A4CF717D414F2A8'} }
        : { headers: { authorization:'bearer 34B7FBD24B97D7A4CF717D414F2A8',key:"AllOtt"} }
    ).then((r) => r.json())
    .then(function (results) {
      console.log("planDetails",results);
      if (results && results?.data?.length && Array.isArray(results?.data)) {
        if (results?.data[results?.data.length-1].hasOwnProperty('upgrade_plan')) {
          results && results.data.pop();
          // results?.data.pop();
        }
        setData([...data, ...results.data]);
        setLoading(false);
      } else {
        setData([]);
      }
    }).catch(function (err) {
      console.warn('Something went wrong.', err);
    });
  };

  return (
    <>
      {/* <Header /> */}
      <div className="partnerWrap">
        <ul className="searchTabs">
          {partnerListTabs.map((tab, id) => (
            <li
              key={id}
              nav-selectable="true"
              className={`tabsList ${
                activeTabRef.current === tab ? "activetab" : ""
              }`}
            >
              {partnersTabMap[tab]}
            </li>
          ))}
        </ul>
        <Title
          title={
            activeTabRef.current === "your_plan_ott"
              ? planName
              : "All OTT Partners"
          }
        />
        {data.length ? (
          <ul className="partnerReasult">
            {data?.map((data, id) => (
              <>
                <li
                  id={`item_${id}`}
                  contenttype={data?.content_type || ""}
                  nav-selectable="true"
                  ottplay_id={data?.ottplay_id || ""}
                  to={!data?.name?.includes('ABP')?
                    `/provider-details?seo_url=${data?.seo_url}`:
                    '/livetv-home'
                  }
                  key={id}
                  data-content_type={data?.content_type}
                  className=""
                >
                  <Image src={data?.logo_url} width="71" height="45" className="landscapeImg" />
                </li>
              </>
            ))}
          </ul>
        ) : null}
        {loading ? <Loader /> : null}
        {/* {loadMore ? (
          <button
            id={`${activeTabRef.current === 'your_plan_ott' ? 'upgradebtn' : 'loadmrbtn' }`}
            nav-selectable="true"
            style={{ color: "black", marginBottom: "30px" }}
          >
            Upgrade Now
          </button>
        ) : null} */}
      </div>

      {/* <Footer /> */}

      <style jsx="true">{`
        .partnerWrap * {
          box-sizing: border-box;
        }
        .partnerWrap {
          background: #270048;
          width: 100%;
          height: calc(100vh - 31px);
          padding: 40px 0px 80px 0px;
          box-sizing: border-box;
          overflow: scroll;
          overflow-x: hidden;
        }
        .partnerWrap {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .partnerWrap::-webkit-scrollbar {
          display: none;
        }

        .partnerWrap .tabsList {
          font-size: 12px;
          font-weight: 600;
          color: #ffffff;
          line-height: 13px;
          padding: 9px 4px;
          position: relative;
          width: 50%;
          text-align: center;
        }
        .partnerWrap .searchTabs {
          margin: -13px 0px 0;
          margin-bottom: 4px;
          padding: 0;
          list-style-type: none;
          background-color: #551787;
          display: flex;
          align-items: center;
          justify-content: space-around;
          overflow-x: hidden;
          border-top: 1px solid;
          border-color: rgba(112, 112, 112, 0.3);
        }
        .partnerWrap .searchTabs::-webkit-scrollbar {
          display: none;
        }
        .partnerWrap .partnerReasult {
          margin: 0;
          padding: 0 5px;
          list-style: none;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
        .partnerWrap .partnerReasult li {
          margin-bottom: 6px;
          border-radius: 3px;
          overflow: hidden;
          margin-right: 5px;
        }
        .partnerWrap .partnerReasult li > span {
          display: inline !important ;
        }
        .partnerWrap .partnerReasult li a {
          width: 71px;
          height: ${type === "partners" ? "45px" : "104px"};
          display: block;
          border-radius: 3px;
        }
        .partnerWrap .partnerReasult li img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .partnerWrap .partnerReasult li img.landscapeImg{
          height:40px;
        }
        .partnerWrap .partnerReasult li img.potraitImg{
            height:107px;
        } 
        .partnerReasult li[nav-selected="true"] {
          outline: 2px solid ${activeElementBorderColor};
          outline-offset: -2px;
        }
        .partnerReasult li[nav-selected="true"] > span {
          display: inline !important;
        }
        .partnerWrap .searchTabs li[nav-selected="true"],
        .activetab {
          color: #29f87e;
          font-weight: bold;
        }
        .partnerWrap .searchTabs li[nav-selected="true"]::after,
        .activetab::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
          border-bottom: 1px solid ${activeElementBorderColor};
        }
        .partnerWrap button {
          margin: 0;
          height: 30px;
          width: 100%;
          background-color: #03f87e;
          padding: 4px 7px;
          border-radius: 2px;
          line-height: 14px;
          font-size: 12px;
          font-weight: 700;
          color: #270048 !important;
          border: 0;
        }
        .partnerWrap button[nav-selected="true"] {
          border: 2px solid ${activeBtnColor};
        }
      `}</style>
    </>
  );
};

export default PartnerList;
