import React, { useEffect, useRef, useState } from "react";
import { activeElementBorderColor } from "../utils/helper";

const SeasonDropdown = ({ seasons, setSeasonsNumber, seasonsNumber, setSeasonDropdown,showSeasondrop }) => {
    let seasonList;
    const renderedtopten = useRef(false);
    const cur = useRef(seasonsNumber-1);
    const [seasonsData, setSeasonsData] = useState(seasons)
    const checkAnswer = (e) => {
        document.getElementById(e.target.id).classList.add('activeEpisode')
        setSeasonsNumber(e.target.id)
        setSeasonDropdown(false)
    }

    function seasonEpisodeEvents() {
        seasonList = document.querySelectorAll("[episode-selectable='true']");
        if (seasonList && renderedtopten) {
            renderedtopten.current = false;
            seasonList[cur.current].classList.add('activeEpisode')
            document.addEventListener("keydown", handleSelectSeasonKeyDown);
        }
    }


    useEffect(() => {
        ( () => {
            setTimeout(() => {
                renderedtopten.current = true;
                renderedtopten.current && seasonEpisodeEvents();
            }, 500);
        })();
        return () => {
            document.removeEventListener("keydown", handleSelectSeasonKeyDown);
        };
    }, [])

    function handleSelectSeasonKeyDown(e) {

        switch (e.key) {
            case "ArrowUp":
                if (cur.current == 0) return

                seasonList[cur.current].classList.remove('activeEpisode');
                seasonList[cur.current - 1].classList.add('activeEpisode');
                if (cur.current != 0) cur.current = cur.current - 1
                break;
        
            case "ArrowDown":
                if (cur.current == seasonList.length - 1) return;
                seasonList[cur.current].classList.remove('activeEpisode');
                if (cur.current != seasonList.length - 1) cur.current = cur.current + 1;
                seasonList[cur.current].classList.add('activeEpisode');
                break;
        
            case "Enter":
                setSeasonsNumber(seasonList[cur.current].id.split('_')[1])
                setSeasonDropdown(false)
                showSeasondrop.current = false;
                return;
                break;
        
            default:
                break;
        }
    }
    return (
        <>
            <div className="seasonDropdownWrap">
                <ul className='quizAnsList'>
                    {seasonsData.length && seasonsData.map((item, id) => {
                        return (
                            <li className="" id={`season_${item.season_number}`} episode-selectable="true">
                                <label className='rightAns' id={item.season_number} htmlFor={`radio${id + 1}`}>{`Season ${item?.season_number}`}</label>
                            </li>

                        )
                    })}
                </ul>
            </div>
            <style jsx="true">
                {`
                .seasonDropdownWrap{
                    position:fixed;
                    top:30px;
                    left:0;
                    width:100%;
                    height:100%;
                    background:#270048;
                    z-index:99;
                    overflow-y:auto
                }
                .quizAnsList{ 
                    padding: 10px 8px 30px; 
                    display: flex; 
                    flex-direction: column;
                    margin: 0;
                    height:100vh;
                }
                .quizAnsList li { 
                    display: inline-flex; 
                    width: 100%; 
                }
                .activeEpisode {
                    background-image: url(https://images.ottplay.com/static/jioImages/greenPlayIcon.svg);
                    background-repeat: no-repeat;
                    background-size: 12px;
                    background-position: 6px 12px;
                    background-color: #551787;
                    border:${`2px solid ${activeElementBorderColor}`}}
                  }
                .quizAnsList li &:last-child { margin-bottom: 0;}

                .quizAnsList li label { 
                    font-size: 16px; 
                    line-height: 24px; 
                    color: #fff; 
                    padding:8px 30px 12px 30px; 
                    width: 100%; 
                    border-radius: 6px; 
                    position: relative;
                    font-weight:700;
                }
                
                .quizAnsList [type="radio"]:checked,
                .quizAnsList [type="radio"]:not(:checked) {
                    position: absolute;
                    left: -9999px;
                }
                .quizAnsList [type="radio"]:checked + label,
                .quizAnsList [type="radio"]:not(:checked) + label
                {
                    position: relative;
                    padding-left: 28px;
                    cursor: pointer;
                    line-height: 20px;
                    display: inline-block;
                    color: #ffffff;
                }
                .quizAnsList [type="radio"]:checked + label:before,
                .quizAnsList [type="radio"]:not(:checked) + label:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 10px;
                    width: 16px;
                    height: 16px;
                    border: 1px solid #ddd;
                    border-radius: 100%;
                    background: #fff;
                }
                .quizAnsList [type="radio"]:checked + label:after,
                .quizAnsList [type="radio"]:not(:checked) + label:after {
                    content: '';
                    width: 14px;
                    height: 14px;
                    background: #03F87E;
                    position: absolute;
                    top: 12px;
                    left: 2px;
                    border-radius: 100%;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }
                .quizAnsList [type="radio"]:not(:checked) + label:after {
                    opacity: 0;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
                .quizAnsList [type="radio"]:checked + label:after {
                    opacity: 1;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            
       
            `}
            </style>
        </>
    );
}
export default SeasonDropdown;