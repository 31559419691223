import { useState, useEffect, useRef } from "react";
import { navigate } from "../navigate";
import { activeBtnColor } from "../utils/helper";
import Cookies from "js-cookie";

export const useGridNavigation = (
  pageName='',
  cur = 0,
  tabNo = 0,
  totalTabs = false,
  setTabNo = () => { },
  setPage = () => { },
  activeTab,
  page
) => {
  const istabActiveRef = useRef(totalTabs ? true : false);
  const tabNoRef = useRef(tabNo);
  tabNoRef.current = tabNo;
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    setNavigation(0);
    return () => document.removeEventListener("keydown", onKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [current, setCurrent] = useState({ type: null, index: null });

  const getAllElements = () => {
    return document.querySelectorAll("[nav-selectable]");
  };

  const getTheIndexOfTheSelectedElement = () => {
    const element = document.querySelector("[nav-selected=true]");
    return element ? parseInt(element.getAttribute("nav-index")) : 0;
  };

  const setNavigation = (index) =>
    selectElement(getAllElements()[index] || document.body);
  const onKeyDown = (evt) => {
    // if (evt.key !== 'ArrowDown' && evt.key !== 'ArrowUp') return;
    const allElements = getAllElements();
    let
      currentIndex = getTheIndexOfTheSelectedElement();
    let setIndex;

    switch (evt.key) {
      case "ArrowLeft":
        if (cur.current === -2) {
          let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
          let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
          headerNavProfile.style.removeProperty('box-shadow')
          headerNavSubs.style.boxShadow = `0px 0px 6px #ffffff`;
          cur.current += 1;
          return;
        }
        if (
          currentIndex === 0 ||
          !totalTabs
        ) {
          return;
        }
        if (currentIndex == totalTabs) return;
        setIndex = currentIndex - 1;
        selectElement(allElements[setIndex] || allElements[0], setIndex);
        istabActiveRef.current && setTabNo("left");
        break;
      case "ArrowRight":
        if (cur.current === -2) return;
        if (cur.current === -1) {
          let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
          let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
          headerNavSubs.style.removeProperty('box-shadow')
          headerNavSubs.style.removeProperty('outline')
          headerNavProfile.style.boxShadow = `0px 0px 6px #ffffff`;
          cur.current -= 1;
          return;
        }
        if (
          !totalTabs ||
          currentIndex === totalTabs - 1 ||
          currentIndex === allElements.length - 1
        )
          return;
        setIndex = currentIndex + 1;
        selectElement(allElements[setIndex] || allElements[0], setIndex);
        istabActiveRef.current && setTabNo("right");
        break;
      case "ArrowDown":
        if (cur.current === -1 || cur.current === -2) {
          let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
          if (headerNav) {
            headerNav.style.removeProperty("box-shadow");
            headerNav.style.removeProperty("outline");
          };
          cur.current = 0;
          setIndex =  tabNoRef.current;
          istabActiveRef.current = false;

          return selectElement(allElements[setIndex] || allElements[0], setIndex);
        };
        if (totalTabs && allElements.length === totalTabs) return;
        if (currentIndex === allElements.length - 1) return;
        // || currentIndex + 3 > allElements.length
        else if (istabActiveRef?.current) {
          setIndex = totalTabs;
          istabActiveRef.current = false;
        } else if (currentIndex >= totalTabs - 1) {
          if (currentIndex === allElements.length - 3) {
            setIndex = allElements.length - 1;
          } else if (currentIndex === allElements.length - 2) {
            setIndex = allElements.length - 1;
          }
          else if (allElements.length - 4 && currentIndex === allElements.length - 4 && allElements[allElements.length - 1].nodeName === 'BUTTON') {
            setIndex = allElements.length - 1;
          }

          else {
            setIndex = currentIndex + 3;
          }
          //currentIndex+=3
        } else {
          setIndex = totalTabs;
        }
        return selectElement(allElements[setIndex] || allElements[0], setIndex);
      case "ArrowUp":
        if (cur.current === -1) return
        if (cur.current === 0 && istabActiveRef?.current) {

          let headerNav = document.querySelectorAll('[head-selectable]')[0];
          setIndex = tabNoRef.current;
          istabActiveRef.current = false;
          headerNav.style.outline = `2px solid ${activeBtnColor}`
          cur.current -= 1;
          return ;
        }
        if (currentIndex === 0 || istabActiveRef?.current) return;
        if (currentIndex === totalTabs) {
          istabActiveRef.current = true;
          setIndex = tabNoRef.current;
        }
        else if (currentIndex === allElements.length - 1 && allElements[allElements.length - 1].nodeName === 'BUTTON')
          setIndex = currentIndex - 1;
        else if (currentIndex === allElements.length - 1)
          setIndex = currentIndex - 3;
        else if (currentIndex - 3 > totalTabs - 1) {
          setIndex = currentIndex - 3;
        }
        else {
          istabActiveRef.current = true;
          setIndex = tabNoRef.current;
        }
        return selectElement(allElements[setIndex] || allElements[0], setIndex);

      case "Enter":
        if(cur.current==-1){
          let headerNavSubs =   document.querySelectorAll('[head-selectable]')[0].title;
          if(!pageName?.includes('partnerlist')){
            navigate(evt,`${headerNavSubs}`)
          }
          return;
          }
          else if(cur.current ===-2){
            let headerNavProfile =   document.querySelectorAll('[head-selectable]')[1].title;
          if(headerNavProfile){
            navigate(evt,`${headerNavProfile}`)
          }
          return;
        }

        if (document.querySelector("[nav-selected=true]")?.id === 'loadmrbtn') {
          setPage(Number(Cookies.get('pageNo')||1)+1);
          Cookies.set('pageNo',Number(Cookies.get('pageNo')||1)+1)

          setIndex = currentIndex - 1;
          selectElement(allElements[setIndex] || allElements[0], setIndex);
          return;
        }
        else if (document.querySelector("[nav-selected=true]")?.getAttribute('contenttype') === 'provider') {
          navigate(evt,document.querySelector("[nav-selected=true]")?.getAttribute('to'))
          return;
        }
        else if (document.querySelector("[nav-selected=true]")?.getAttribute('contenttype') !== 'provider') {
          Cookies.set('langContentPlayed',true)
          navigate(evt,document.querySelector("[nav-selected=true]")?.getAttribute('to'))
          return;
        }
        break;
      default:
        break;
    }
  };
  const selectElement = (selectElement, setIndex = 0) => {
    if (selectElement) {
      [].forEach.call(getAllElements(), (element, index) => {
        const selectThisElement = element === selectElement;
        element.setAttribute("nav-selected", selectThisElement);
        element.setAttribute("nav-index", index);
        // element.scrollIntoView(false)
        if (element.nodeName === "INPUT" || element.nodeName === "BUTTON") {
          selectThisElement ? element.focus() : element.blur();
        }
      });
      setCurrent({ type: selectElement.tagName, index: setIndex });
    } else {
      setNavigation(0);
    }
  };

  return [current, setNavigation];
};
