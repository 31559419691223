import React, { useEffect, useState } from "react";
import Title from "./titleHeading";
import Image from "./Image";
import { apiUrl } from "../site.config";
import Cookies from "js-cookie";
function FeaturedCarousel(props) {

  let { template_name, pin_it, section, title, end_point, content_type } = props.item;
  let uniqueId = props?.uniqueId;
  let client_id = Cookies.get("clientId") || ''

  let seo_url = props?.seo_url;
  const [featuredVideos, setFeaturedVideos] = useState([])
  useEffect(() => {
    fetchFeaturedVideos();
  }, [])

  const fetchFeaturedVideos = () => {
    end_point = end_point.slice(0, 1) == '/' ? end_point : `/${end_point}`
    fetch(`${apiUrl}${end_point.trim()}?limit=4&pin_it=${pin_it}&template_name=${template_name}&section=${section}&page=1&module_name=${props?.page === "live" ? 'livetv' : props?.page == 'provider' ? seo_url : 'Home'}`, 
    { headers: { 'client_id': client_id||'', 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } }).then(function (response) {
      // The API call was successful
      return response.json();
    }).then(function (data) {
      // This is the JSON from our response
      console.log("featuredCarouselData",data);
      let filtearry = data?.data?.filter((item, id) => {
        return item.hasOwnProperty('where_to_watch')
      })
      if (filtearry?.length && Array.isArray(filtearry)) { setFeaturedVideos(filtearry) }
      else {
        setFeaturedVideos([])
      }
      if (!data?.length) return null;
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });
  }
  return (
    <>
      <Title title={title} template_name={template_name} content_type={content_type} />
      <div id={uniqueId} className="featuredSlideWrap" home-selectable="true">
        <ul className="featuredSlideList">
          {featuredVideos?.map((data, id) => {
            let to = data?.content_type == 'movie' || data?.content_type == 'live_tv' || data?.content_type == 'sport' ? `/player?content_type=${data?.content_type}&ottplay_id=${data?.ottplay_id}&provider=${data?.where_to_watch[0]?.provider}&subscriptionType=${data?.where_to_watch[0]?.subscription_type}&_id=${data._id || ''}&name=${data?.name}` : `/show-details?seo_url=${data?.seo_url}&subscriptionType=${data?.where_to_watch[0]?.subscription_type}&name=${data?.name}`

            return <li key={`fc${id}`} className="featuredSlideListItems" widget-selectable={uniqueId} _id={data._id || ' '} name={data?.name} content-type={data?.content_type || ''} ottplay_id={data?.ottplay_id || ''} providers={data?.content_type == 'movie' || data?.content_type == 'live_tv' || data?.content_type == 'sport' ? data?.where_to_watch[0]?.provider || '' : ''} seo_url={data?.seo_url || ''} to={to}>
              <Image src={data?.backdrops} height="110" width="220" intial="render" className="posterImg" />
            </li>

          })}
        </ul>
        <button type="button" className="prevArrow" id="prevArrow">
          <img src="https://images.ottplay.com/static/jioImages/sliderLeftArrow.svg" />
        </button>
        <button type="button" className="nextArrow" id="nextArrow">
          <img src="https://images.ottplay.com/static/jioImages/sliderRightArrrow.svg" />
        </button>
      </div>
      <style jsx="true">{`
        .featuredSlideWrap{
          display:flex;
          position:relative;
          padding-bottom:8px;
        }
        .featuredSlideList{
          display:flex;
          position:relative;
          gap:8px;
          padding: 0px;
          width:100%;
          margin:0px;
          overflow:auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .featuredSlideList::-webkit-scrollbar {
          display: none;
        }
        .prevArrow{
          position:absolute;
          top:50%;
          left:5px;
          transform:translateY(-50%);
          width:auto;
          height:auto;
          background:none;
          border:none;
          outline:none;
          padding:0
        }
        .nextArrow{
          position:absolute;
          top:50%;
          right:5px;
          transform:translateY(-50%);
          width:auto;
          height:auto;
          background:none;
          border:none;
            outline:none;
          padding:0
        }
        .featuredSlideListItems{
          height: 125px;
          width: 100%;
          margin: 0 10px;
          overflow:hidden
        }
        .featuredSlideListItems img.posterImg{
          height:125px;
          width:220px;
      }
        `}

      </style>
    </>
  );
}

export default React.memo(FeaturedCarousel);