import React, { memo, useContext } from 'react'
import Image from './Image'
import OTTLogo from '../Assets/images/Group 7.svg'
import Line from '../Assets/images/Line 1186.svg'
import PremiumIcon from '../Assets/images/premiumIcon.svg'
import UserLogo from '../Assets/images/usericon.svg'
import { UserContext } from '../Contexts/userContext'
import { getCookies } from '../utils/cookieHelper'
function Header({page='home'}) {
  const User = useContext(UserContext);
  let {logged_in} =  getCookies(['logged_in']);
  return (
    <>
    <div className='header' id='HeaderId'>
       <div className='logoContainer'>
       <Image src={OTTLogo} height='100%' width="100%"/>
       <Image src={Line} height='100%' width='1' />
       </div>
       {page==='home'?<div className='PremiumLogoContainer' >
        <Image src={PremiumIcon}  width='100%'/>
        {User?.user?.planName ? <span head-selectable='true' title={`${logged_in? User?.user?.planName !== 'Subscribe'? '/partner-list':  '/plans' : '/login'}`} className='headerSubscribe'>{User?.user?.planName||'Subscribe'}</span> : 
        <span head-selectable='true' title={`${logged_in? '/plans' : '/login'}`} className='headerSubscribe'>{'Subscribe'}</span>
        }
       </div>:null}
       {page==='home'?<div className='userIcon' head-selectable='true' title={`${logged_in? '/profile' : '/login'}`} >
          <Image src={UserLogo}  height='19' width='19' className="userIconLogo"/>

       </div>:null}


       
    </div>
    <style jsx="true" >
      {`
      .header {
        position:fixed;
        width: 100%;
        background: #551787;
        opacity: 1;
        padding: 7px 8px 7px 7px ;
        display: flex;
        z-index:999;
        top:0;
    }
    
    .logoContainer{
        height:16px;
        width:77px;
        display: flex;
        justify-content: space-between;
    }
    
    .PremiumLogoContainer{
      margin-left: 3px;
      margin-right: 3px;
      display: flex;
      align-items: flex-end;
    }
    .headerSubscribe{
      font-size:12px;
      color:#270048;
      padding:4px 4px;
      background-color:#29F87E;
      border-radius:2px;
      font-weight:500;
      margin-left:6px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 87px;
      letter-spacing:0.7px;
    }

      .userIcon {
        height: 22px;

        display: flex;
        justify-content: end;
        position: fixed;
        right: 5px;
        border-radius: 50%;
    
    }
    .userIconLogo{
      width:22px;
      height:22px;
    }
      `}
    </style>
    </>
  )
}

export default memo(Header);