import React from "react";

 const DetailList =({
  name = "",
  searchQuery='',
  page='',
  content_type = "",
  language = "",
  release_year = "",
  id = "",
  ottplay_id='',
  provider='',
  seo_url='',
  _id=''
})=> {

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <>
      <a href={(content_type === 'movie' || content_type === 'live_tv'|| content_type === 'sport')?`/player?content_type=${content_type}&ottplay_id=${ottplay_id}&provider=${provider}&_id=${_id}`:`/show-details?seo_url=${seo_url}`}>
        <li className="" key={`vid_${id}`} 
         id={id}
         nav-selectable='true'
         content_type={content_type}
         ottplay_id={ottplay_id}
         provider={provider}
         seo_url={seo_url}
         name={name}
         page={page}
         _id= {_id}
         query={searchQuery}
         to={(content_type === 'movie' || content_type === 'live_tv'|| content_type === 'sport')?`/player?content_type=${content_type}&ottplay_id=${ottplay_id}&provider=${provider}&_id=${_id}`:`/show-details?seo_url=${seo_url}`}>
          <h4>{name}</h4>
          <div className="searchInfo">
            {content_type?<span>{capitalizeFirstLetter(content_type)}</span>:null}
            {language?<span>{capitalizeFirstLetter(language)}</span>:null}
            {release_year?<span>{release_year}</span>:null}
          </div>
        </li>
      </a>
    </>
  );
}
export default DetailList;