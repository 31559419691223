import React, { useEffect, useState } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import DetailList from "../Components/cards/detaillists";
import { useDebounce } from "../CustomHooks/useDebounce";
import { subsApi } from "../site.config";
import { fetchData } from "../Api/client";
import { navigate, navigateBack } from "../navigate";
import { useNavigation } from "../CustomHooks/useNavigation";
import { activeElementBorderColor, handlekeyDown, logEvent } from "../utils/helper";
import Loader from "../Components/loader";

const Search = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [resultsNotFound, setResultsNotFound] = useState(false);
  const resValue = useDebounce(query, 1000);
  const [current, setNavigation] = useNavigation();
  useEffect(() => {
    let inputBox = document.querySelector(".inputSearch");
    inputBox.focus();
    if (inputBox) {
      inputBox.addEventListener("keydown", (event) => {
        if (event.key !== "Enter") { return; };
        let state = {
          query: inputBox?.value || ''
        }
        logEvent('search_usage', inputBox.value, null, null)
        navigate({},`/results?query=${inputBox.value}`, state);
        // if(!query.length && event.key =="Backspace"){
        //   event.preventDefault();
        //   navigateBack();
        // }

      });
    }
    document.addEventListener('keydown', handlekeyDown)
    document.addEventListener('keydown', inView)
    return () => {
      document.removeEventListener('keydown', handlekeyDown)
      document.removeEventListener('keydown', inView)
    }
  }, []);


  function inView(e) {
    let ele = document.querySelector("[nav-selected='true']")
    let id = ele.getAttribute("nav-index");
    if (id && id > 1) {
      ele.scrollIntoView(true)
    }
    if (e.key == 'ArrowUp' && id < 2) {
      ele.scrollIntoView(false)
    }

  }
  useEffect(() => {
    searchForQuery();
  }, [resValue]);


  const searchForQuery = () => {
    if (resValue && query.length > 2) {
      setLoading(true);
      let end_point = `${subsApi}search/jio-titles?type=all&query=${query}&listing=false&limit=5&page=1`
      let queryResult = fetch(end_point,{headers:{}}).then(function (response) {
        return response.json();
      }).then(function (data) {
        console.log("search", data);
        if (data?.result?.length) {
          setSearchResult(data.result);
          setLoading(false);
        } else {
          setResultsNotFound(true)
          setSearchResult([]);
          setLoading(false);
        }
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      }) || '';
      // if (queryResult?.result?.length) {
      //   setSearchResult(queryResult.result);
      //   setLoading(false);
      // } else {
      //   setResultsNotFound(true)
      //   setSearchResult([]);
      //   setLoading(false);
      // }
    } else {
      setSearchResult([]);
    }
  };
  return (
    <>
      {/* <Header /> */}
      <div className="searchWrap">
        <input type="text" className="inputSearch" onChange={(e) => setQuery(e.target.value)} nav-selectable='true' />
        <ul className="sarchReasult">
          {searchResult?.length
            ? searchResult?.map((vid, id) => (
              <DetailList
                page="search"
                searchQuery={query}
                name={vid.name}
                content_type={vid.content_type === 'live TV' ? 'live_tv' : vid.content_type}
                language={vid.language}
                release_year={vid.release_year}
                ottplay_id={vid.ottplay_id || ''}
                provider={vid.provider || ''}
                seo_url={vid.seo_url || ''}
                _id={vid._id || ''}
              />
            ))
            : null}
          {resultsNotFound && !searchResult?.length ? <div className="noResultsSearch">No Results found for "{query}"</div> : null}
        </ul>

        {loading ? <Loader /> : null}
      </div>
      {/* <Footer /> */}

      <style jsx="true">{`
        .searchWrap * {
          box-sizing: border-box;
        }
        .searchWrap {
          background: #270048;
          width: 100%;
         height: calc(100vh - 31px)
            ;
          padding: 32px 8px;
          box-sizing: border-box;
          overflow:auto
        }
        .searchWrap input {
          border: 0;
          border-radius: 3px;
          padding: 8px 9px;
          width: 100%;
          font-size: 10px;
          font-weight: 600;
          margin-bottom: 10px;
          margin-top:15px
        }
        .searchWrap input:focus {
          outline: 0;
        }
        .searchWrap .sarchReasult {
          margin: 0;
          margin-top:10px;
          padding: 0;
          list-style: none;
        }
        .searchWrap .sarchReasult a:not(:last-child) {
          margin-bottom: 5px;
        }
        .searchWrap .sarchReasult a {
          color: #fff;
          text-decoration: none;
          background: #3a135b;
          display: block;
          border-radius: 5px;
        }
        .searchWrap .sarchReasult li {
          padding: 6px 9px;
        }
        .searchWrap .sarchReasult h4 {
          font-size: 12px;
          line-height: 17px;
          margin: 0;
          font-weight: 600;
        }
        .searchWrap .sarchReasult .searchInfo {
          font-size: 10px;
          color: #9989b5;
          line-height: 14px;
        }
        .searchWrap .sarchReasult .searchInfo span:not(:first-child) {
          padding-left: 9px;
        }
        .searchWrap .sarchReasult .searchInfo span {
          position: relative;
        }
        .searchWrap .sarchReasult .searchInfo span:not(:first-child):before {
          content: "";
          width: 3px;
          height: 3px;
          background: #9989b5;
          position: absolute;
          border-radius: 50%;
          left: 3px;
          top: 50%;
          transform: translateY(-50%);
        }
        .highlight {
          border: 2px solid white;
        }
      
        .searchWrap input[nav-selected='true'] {
            border:2px solid #29F87E;
        }
        ul li[nav-selected="true"]{
          border:2px solid ${activeElementBorderColor};
        }
        .noResultsSearch{
          width:100%;
          display:block;
          color:#ffffff;
          font-size:14px;
          font-weight:700;
          line-height:20px;
          padding:15px 10px;
        }
       
      `}</style>
    </>
  );
};


export default Search;
