import React, { useEffect, useRef, useState } from 'react'
import { apiUrl } from '../site.config';
import Image from '../Components/Image';
import { useGridNavigation } from '../CustomHooks/useGridNavigation';
import { activeBtnColor, activeElementBorderColor } from '../utils/helper';
import { gridViewTotalCount } from '../utils/constants';
import Loader from '../Components/loader';
import Cookies from 'js-cookie';
import { navigate } from '../navigate';
export default function LanguageDetail() {
    let languageId = window.history.state?.languageId || '';
    let languageName = window.history.state?.languageName || '';
    let type = ""

    const [data, setData] = useState([]);
    let pageName;
    let client_id = Cookies.get("clientId") || ''

    let cur = useRef(0);
    const [loading, setLoading] = useState(true)
    const [loadMore, setLoadMore] = useState(false)
    const [nodata, setNodata] = useState('')
    const [selectedItem, setSelectedItem] = useState('item_0')
    const [totalCount, setTotalCount] = useState(gridViewTotalCount || 0)
    const [tabNo, setTabNo] = useState(0);
    const [oneTime, setOneTime] = useState(false)
    const [page, setPage] = useState(Number(Cookies.get('pageNo')) || 1)
    const loadMoreRef = useRef(false);
    const currentRef = useRef(false);

    loadMoreRef.current = loadMore;

    let tabs = ['movie', 'show']
    const activeTabRef = useRef(tabs[tabNo]);
    const tabNoRef = useRef(tabNo);
    const [totalCountNumber, setTotalCountNumber] = useState(0)
    function handleTabChange(tabDir) {
        if (tabDir === "right") {
            setData([])
            setPage(1)
            tabNo <= tabs.length - 1 && setTabNo((prev) => parseInt(prev) + 1);
        } else if (tabDir === "left") {
            setData([])
            setPage(1)
            tabNo >= 0 && setTabNo((prev) => parseInt(prev) - 1);
        }
    }
    activeTabRef.current = tabs[tabNo];
    tabNoRef.current = tabNo;

    useEffect(() => {

        (() => {
            fetchLangdata();
            document.addEventListener('keydown', scrollIntoSreenView);
            !oneTime && handleActiveBorder();
            document.addEventListener('keydown', handlekey)
        })()
        //  setTimeout(() => {     
        //  }, 0);

        return () => {
            document.removeEventListener('keydown', scrollIntoSreenView);
            document.removeEventListener('keydown', handlekey)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, activeTabRef.current])

    function scrollIntoSreenView(e) {
        let ele = document.querySelector("[nav-selected=true]");
        ele?.scrollIntoView(false)
    }
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    function fetchLangdata() {
        let limit = 9;
        let pageNo = page;
        if (Cookies.get('langContentPlayed') == 'true') {
            limit = limit * Number(Cookies.get('pageNo'))
            // Cookies.set('page',Cookies.get('pageNo'))
            pageNo = 1;
            Cookies.remove('langContentPlayed');
        }
        setLoading(true);
        setLoadMore(false)
        let end_point = `${apiUrl}/v4.5/mobile/language-genre/movieshowlist?limit=${limit}&page=${pageNo}&languages=${languageId}&content_type=${activeTabRef.current || "show"}`;
        fetch(end_point, { headers: { 'client_id': client_id || '', 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } })
            .then(function (response) {
                // The API call was successful!
                return response.json();
            }).then(function (result) {
                // This is the JSON from our response
                console.log("languagedetails", result);
                setTotalCountNumber(result?.totalCount)
                if (result && Array.isArray(result?.data) && result?.data?.length) {
                    setData([...data, ...result.data])
                    setLoading(false)
                    if (result.data.length < 9) setLoadMore(false);
                    // if (page === 2) setLoadMore(false)
                    else setLoadMore(true)
                }
                else {
                    setNodata(`There are no ${languageName} ${activeTabRef.current || ""} in your package`)
                    setData([])
                    setLoadMore(false)
                    setLoading(false);
                }
            }).catch(function (err) {
                // There was an error
                console.warn('Something went wrong.', err);
            });
        // setTotalCountNumber(result?.totalCount)
        // if(result && Array.isArray(result?.data) && result?.data?.length){
        //     setData( [...data,...result.data])
        //     setLoading(false)
        //     if(result.data.length < 9 ) setLoadMore(false) ;
        //     if(page === 2) setLoadMore(false)
        //     else setLoadMore(true)
        // }
        // else{
        //   setData([])
        //   setLoadMore(false)
        //   setLoading(false);
        // }
    }


    const handlHorizontal = (currentId, actionId, dir) => {
        document.getElementById(`item_${currentId}`).classList.remove('active')
        document.getElementById(`item_${actionId}`).classList.add('active')
        document.getElementById(`item_${actionId}`).scrollIntoView(false)
        setSelectedItem(`item_${actionId}`)
    }

    const handleActiveBorder = () => {
        let tablist = document.querySelectorAll(`[nav-selectable='true']`)
        // console.log("tablist", tablist)
        if (cur.current == 0) {
            tablist[0].setAttribute("nav-selected", true);
            setOneTime(true)
        }
    }

    const handlekey = (e) => {
        e.preventDefault();
        let keyName = e.key;
        let tablist = document.querySelectorAll(`[nav-selectable='true']`)
        let listSelectable = document.querySelectorAll(`[list-selectable='true']`)
        let id = document.querySelector('.active')?.id;
        let licount = listSelectable.length;
        if (cur.current == 0) {
            switch (keyName) {
                case 'ArrowRight':
                    tablist[1].setAttribute("nav-selected", true)
                    tablist[1].classList.add('activeTab')
                    tablist[0].setAttribute("nav-selected", false)
                    tablist[0].classList.remove('activeTab')
                    activeTabRef.current = tabs[1];
                    handleTabChange('right')

                    break;

                case 'ArrowLeft':
                    tablist[0].setAttribute("nav-selected", true)
                    tablist[0].classList.add('activeTab')
                    tablist[1].setAttribute("nav-selected", false)
                    tablist[1].classList.remove('activeTab')
                    activeTabRef.current = tabs[0];
                    handleTabChange('left')
                    break;

                case 'ArrowUp':
                    tablist[tabs.findIndex((day) => day === activeTabRef.current)].setAttribute("nav-selected", false)
                    tablist[tabs.findIndex((day) => day === activeTabRef.current)].classList.remove('activeTab')
                    let headerNav = document.querySelectorAll('[head-selectable]')[0];
                    headerNav.style.outline = `2px solid ${activeBtnColor}`
                    cur.current -= 1;
                    break;

                case 'ArrowDown':
                    cur.current = 1;
                    listSelectable[0].classList.add('active')
                    tablist[tabs.findIndex((day) => day === activeTabRef.current)].setAttribute("nav-selected", false)
                    tablist[tabs.findIndex((day) => day === activeTabRef.current)].classList.remove('activeTab')
                    break;

                default:
                    break;
            }
        }
        else if (cur.current == 1) {
            if (id) {
                let eleId = parseInt(document.querySelector('.active').id.split('_')[1]);
                switch (keyName) {
                    case "ArrowRight":
                        if (eleId < licount - 1) {
                            handlHorizontal(eleId, eleId + 1)
                        }
                        break;
                    case "ArrowLeft":
                        if (eleId > 0) {
                            handlHorizontal(eleId, eleId - 1)
                        }
                        break;
                    case "ArrowUp":
                        if (eleId > 2) {
                            handlHorizontal(eleId, eleId - 3)
                        }
                        else if (cur.current === 1) {
                            cur.current = 0;
                            document.getElementById(`item_${eleId}`).classList.remove('active')
                            tablist[tabs.findIndex((day) => day === activeTabRef.current)].setAttribute("nav-selected", true)
                            tablist[tabs.findIndex((day) => day === activeTabRef.current)].classList.add('activeTab')
                        }
                        break;
                    case "ArrowDown":
                        if (eleId < licount - 3) {
                            handlHorizontal(eleId, eleId + 3)
                        }
                        else if (document.getElementById('loadmrbtn')) {
                            document.getElementById(`item_${eleId}`).classList.remove('active')
                            document.getElementById('loadmrbtn').focus();
                        }
                        else if (eleId < licount - 2) {
                            handlHorizontal(eleId, eleId + 2)
                        }
                        else if (eleId < licount - 1) {
                            handlHorizontal(eleId, eleId + 1)
                        }

                        break;
                    case "Enter":
                        let btn = document.getElementById('loadmrbtn');
                        if (document.activeElement === btn) {
                            btn.blur();
                            btn.style.removeProperty('border');
                            document.querySelectorAll('li')[licount - 1].classList.add('active')
                        } else {

                            handleOk(id, e);
                        }
                        break;

                    default:
                        break;
                }
            }

            else {
                let btn = document.getElementById('loadmrbtn');
                switch (keyName) {

                    case "ArrowRight":
                        break;
                    case "ArrowLeft":
                        if (document.activeElement === btn) {
                            btn.style.removeProperty("border")
                            btn.blur();
                            document.querySelectorAll('li')[licount - 1].classList.add('active')
                            document.querySelectorAll('li')[licount - 1].scrollIntoView(false)
                        }
                        break;
                    case "ArrowUp":
                        if (document.activeElement === btn) {
                            btn.style.removeProperty("border")
                            btn.blur();
                            document.querySelectorAll('li')[licount - 1].classList.add('active')
                        }
                        break;
                    case "ArrowDown":
                        if (cur.current === -1 || cur.current === -2) {
                            let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
                            if (headerNav) {
                                headerNav.style.removeProperty("box-shadow");
                                headerNav.style.removeProperty("outline");
                            };
                            cur.current = 0;
                            document.querySelectorAll('li')[0].classList.add('active')

                            return
                        };
                        break;
                    case "Enter":
                        if (document.activeElement === btn) {
                            btn.blur();
                            btn.style.removeProperty('border');
                            document.querySelectorAll('li')[licount - 1].classList.add('active')
                            setPage(Number(Cookies.get('pageNo') || 1) + 1);
                            Cookies.set('pageNo', Number(Cookies.get('pageNo') || 1) + 1)
                            document.querySelectorAll('li')[licount - 1].scrollIntoView(false)
                        }
                        break;

                    default:
                        break;
                }
            }
        }
        else if (cur.current <= 0) {
            switch (keyName) {
                case "ArrowRight":
                    if (cur.current === -2) return;
                    if (cur.current === -1) {
                        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
                        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
                        headerNavSubs.style.removeProperty('box-shadow')
                        headerNavSubs.style.removeProperty('outline')
                        headerNavProfile.style.boxShadow = `0px 0px 6px #ffffff`;
                        cur.current -= 1;
                    }
                    break;
                case "ArrowLeft":
                    if (cur.current === -2) {
                        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
                        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
                        headerNavProfile.style.removeProperty('box-shadow')
                        headerNavSubs.style.boxShadow = `0px 0px 6px #ffffff`;
                        cur.current += 1;
                    }
                    break;
                case "ArrowDown":
                    if (cur.current === -1 || cur.current === -2) {
                        let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
                        if (headerNav) {
                            headerNav.style.removeProperty("box-shadow");
                            headerNav.style.removeProperty("outline");
                        };
                        cur.current = 0;
                        tablist[tabs.findIndex((day) => day === activeTabRef.current)].setAttribute("nav-selected", true)
                        tablist[tabs.findIndex((day) => day === activeTabRef.current)].classList.add('activeTab')

                        return
                    };
                    break;
                case "Enter":
                    if (cur.current == -1) {
                        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0].title;
                        if (headerNavSubs) {
                            navigate(e, `${headerNavSubs}`)
                        }
                        return;
                    }
                    else if (cur.current === -2) {
                        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1].title;
                        if (headerNavProfile) {
                            navigate(e, `${headerNavProfile}`)
                        }
                        return;
                    }
                    break;

                default:
                    break;
            }
        }
    }

    const handleOk = (id, e = {}) => {
        if (type === "partners") {
        }
        let state = {
            content_type: document.getElementById(id).getAttribute('contenttype'),
            ottplay_id: document.getElementById(id).getAttribute('ottplay_id'),
            provider: document.getElementById(id).getAttribute('providers'),
            _id: document.getElementById(id).getAttribute('_id'),

        }
        let showstate = {
            seo_url: document.getElementById(id).getAttribute('seo_url')
        }
        let path = document.getElementById(id).getAttribute('to');
        let action = `${document.getElementById(id).getAttribute('contenttype')}/${document.getElementById(id).getAttribute('name')}`
        // logEvent(`${state?.content_type}_click`, action, `${document.getElementById(id).getAttribute('ottplay_id')}/${document.getElementById(id).getAttribute('providers')}`)
        path.includes('/player') ? navigate(e, path, state) : navigate(e, path, showstate);
    }



    return (<>
        {/* <Header /> */}
        <div className='partnerWrap'>
            <ul className="searchTabs">
                {tabs.map((tab, id) => (
                    <li
                        nav-selectable='true'
                        key={id}
                        className={`tabsList  ${activeTabRef.current === tab ? 'activetab' : ''} `}
                    >
                        {capitalizeFirstLetter(tab)}
                    </li>
                ))}
            </ul>
            {data.length ?
                <>
                    <ul className="partnerReasult">
                        {data?.map((data, id) => <>
                            <li id={`item_${id}`} _id={data._id || ''} content_type={data?.content_type || ''} list-selectable='true' ottplay_id={data?.ottplay_id || ''} provider={data?.where_to_watch[0]?.provider || ''} seo_url={data?.seo_url || ''} to={type !== "partners" ? `${[data["content_type"]]}` === 'show' ? `/show-details?seo_url=${data?.seo_url}&subscriptionType=${data?.where_to_watch[0]?.subscription_type}` : `/player?content_type=${data?.content_type}&ottplay_id=${data?.ottplay_id}&provider=${data?.where_to_watch[0]?.provider}&subscriptionType=${data?.where_to_watch[0]?.subscription_type}&_id=${data._id || ''}` : '/'} key={id} data-content_type={data?.conntent_type} className="">
                                <img src={data?.posters} width="71" height="104" />
                                {/* <Image src={data?.posters} width="71" height="104" /> */}
                            </li>
                        </>)}
                    </ul>

                    {loadMore && (data?.length < totalCountNumber) ? <button
                        id="loadmrbtn"
                        nav-selectable='true'
                        style={{ color: "black", marginBottom: "30px" }}
                    >
                        Load More
                    </button> : null}
                </> : <div className='noData'>{nodata}</div>
            }
            {loading ? <div className="loaderPlan loadplan"><Loader /></div> : null}

        </div>
        {/* <Footer/> */}
        <style jsx="true">{`
  .partnerWrap * {
    box-sizing: border-box;
  }
  .loadplan {
    top:60px;
  }
  .partnerWrap {
    background: #270048;
    width: 100%;
    height: calc(100vh - 38px);
    padding: 40px 0px 80px 0px;
    box-sizing: border-box;
    overflow: scroll;
    overflow-x: hidden;
  
  }
  .partnerWrap{
    -ms-overflow-style: none; 
    scrollbar-width: none;
  }
  .noData{
    color:white;
    margin-left: 8px;
  }
  .partnerWrap::-webkit-scrollbar {
    display: none;
  }
  
  .partnerWrap .tabsList {
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    line-height: 13px;
    padding: 9px 4px;
    position: relative;
    width: 50%;
    text-align: center;
  }
  .partnerWrap .searchTabs {
    margin: -13px 0px 0;
    margin-bottom: 4px;
    padding: 0;
    list-style-type: none;
    background-color: #551787;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow-x: hidden;
    border-top: 1px solid;
    border-color: rgba(112, 112, 112, 0.3);
  }
  .partnerWrap .searchTabs::-webkit-scrollbar {
    display: none;
  }
  .partnerWrap .partnerReasult {
    margin: 0;
    padding: 0 5px;
    list-style: none;
    display: flex;
    justify-content: flex-start; 
    flex-wrap: wrap;
   
  }
  .partnerWrap .partnerReasult li {
    margin-bottom: 6px;
    border-radius: 3px;
    overflow: hidden;
    margin-right:5px;
    flex:0 0 31%;
  }
  .partnerWrap .partnerReasult li>span{
    display:inline !important ;
  }
 .partnerWrap  .partnerReasult li a {
    width: 71px;
    height: ${type === "partners" ? "45px" : "104px"};
    display: block;
    border-radius: 3px;
  }
 .partnerWrap  .partnerReasult li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .partnerReasult li[nav-selected="true"] {
    outline: 2px solid ${activeElementBorderColor};
  }
  .partnerReasult li[nav-selected="true"] > span{
    display:inline !important;
  }
  .partnerWrap .searchTabs li[nav-selected="true"], .activetab {
    color: #29f87e;
    font-weight: bold;
  }
  .partnerWrap .searchTabs li[nav-selected="true"]::after,.activetab::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid ${activeElementBorderColor};
  }
  .partnerWrap button {
    margin: 0;
    height: 30px;
    width: 100%;
    background-color: #521384;
    padding: 4px 7px;
    border-radius: 2px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 700;
    color: #ffffff !important;
    border: 1px solid #6413A7;
  }
  .partnerWrap button[nav-selected="true"]{
    border : 2px solid ${activeBtnColor};
    color:#270048 !important;
    background-color:#29F87E;
    border:0 !important;
    outline:none !important;
  }
  .partnerWrap button:focus{
    color: #270048 !important;
    background-color: #29F87E;
    border: 0 !important;
    outline: none !important;
  }
  
  `}</style>

    </>
    )
}
