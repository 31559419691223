import React, { useEffect, useState } from "react";
import Image from "../Components/Image";
import Title from "../Components/titleHeading";
import { apiUrl } from "../site.config";
import Cookies from "js-cookie";

function OttPartners({ item = {}, uniqueId = '', longWidget = false }) {
  const { title, pin_it, end_point, template_name, section } = item;
  let height = longWidget ? '103' : '44';
  let clientId = Cookies.get('clientId') || '';


  const [widgetData, setWidgetData] = useState([])

  useEffect(() => {
    fetchwidgetData(pin_it, end_point, template_name, section);
  }, [])
  const fetchwidgetData = (pin_it, end_point, template_name, section) => {
    Cookies.set('providerApi', `${apiUrl}/v4.5/mobile/ranking?limit=17&pin_it=${pin_it}&template_name=${template_name}&section=${section}&page=1&module_name=Home`)
    fetch(`${apiUrl}/v4.5/mobile/ranking?limit=3&pin_it=${pin_it}&template_name=${template_name}&section=${section}&page=1&module_name=Home`, { headers: { client_id: clientId||'' , 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } }).then(function (response) {
      // The API call was successful
      return response.json();
    }).then(function (data) {
      // This is the JSON from our response
      console.log("ottpartners",data);
      data = data?.data;
      setWidgetData(data.slice(0, 3))
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });
  }

  return ((Array.isArray(widgetData) && widgetData.length) ?
    <>
      <div className='fixedContainer'>
        <Title title={title} template_name={template_name} section={section} end_point={end_point} />
        <div className='cardContainer' id={uniqueId} home-selectable="true">
          {widgetData && widgetData.map((item, idx) => {
            return (
              <a key={idx} to={!item?.name.includes('ABP') ? `/provider-details?seo_url=${item?.seo_url || ''}` : '/livetv-home'} widget-selectable={uniqueId} seo_url={item?.seo_url || ''}>
                <Image src={item?.logo_url} height={height} width='72' alt='' />
              </a>
            );
          })}
        </div>
      </div>
      <div className='space'></div>
      <style jsx="true">
        {`
  .cardContainer{
      display: flex;
      align-items:center;
      padding:0 8px;
  }
  .cardContainer a{
      margin-right:4px;
      border-radius:4px;
  }
  .cardContainer a span{
    display:flex!important;

}
  .card{
      width:33%;
  }
  .cardImage{

  }
  .cardContainer img{
      border-radius:2px;
  }
  
`}
      </style>
    </> : null
  );
}

export default OttPartners;
