import Cookies from "js-cookie";
import { navigate } from "../navigate";
import ReactGA from "react-ga4";
import { defaultImgUrl } from "../site.config";
export const imageLoader = (src = '', height = '', width = '') => {


  return src ? `${src}?height=${height}&width=${width}&format=jpeg&quality=100` : `${defaultImgUrl}?height=${height}&width=${width}&format=jpeg&quality=100`
}

export const DateFormatter = (dateDay) => {
  if (!dateDay) return '';
  let date = new Date(dateDay).toLocaleDateString('en-us', { day: 'numeric' })
  let month = new Date(dateDay).toLocaleDateString('en-us', { month: 'short' })
  let year = new Date(dateDay).toLocaleDateString('en-us', { year: 'numeric' })
  const FormattedDate = `${date} ${month} ${year}`
  return FormattedDate;
}
export const dateFormater = (date) => {
  let Datev = new Date(date)
  return Datev.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
}
export function handlekeyDown(e, id = '', setPage = () => { }) {
  if (e.key !== "Enter") return;
  let selectedEle = document.querySelector("[nav-selected='true']")
  if (selectedEle.nodeName === 'BUTTON' || selectedEle.nodeName === "INPUT") {
    // setPage(2)
    return
  }
  let naviUrl = selectedEle ? selectedEle.getAttribute('to') : null
  if (!naviUrl) return;
  let state = {
    content_type: selectedEle.getAttribute('content_type'),
    ottplay_id: selectedEle.getAttribute('ottplay_id'),
    provider: selectedEle.getAttribute('provider'),
  }
  let showstate = {
    seo_url: selectedEle.getAttribute('seo_url')
  }
  let path = selectedEle.getAttribute('to');
  console.log("@path ", path, state)
  let cat = selectedEle.getAttribute('content_type') == 'search'?'search_suggestion_click':'search_result_click';
  let action = selectedEle.getAttribute('content_type') == 'search'?selectedEle.getAttribute('name'):selectedEle.getAttribute('query');
  let label = selectedEle.getAttribute('content_type') == 'search'?selectedEle.getAttribute('query'):`${selectedEle.getAttribute('content_type')}/${selectedEle.getAttribute('name')}/${selectedEle.getAttribute('_id')}`
  logEvent(cat,action,label,selectedEle.getAttribute('id'))
  path.includes('/player') ? navigate(e,path, state) : navigate(e,path, showstate);
  // navigate(naviUrl)      
}

export const activeElementBorderColor = '#29f87e';
export const activeBtnColor = '#9989B5';

export const querySplitter = (query) => {
  let p = (query && query.split('?')[1]?.split('&')) || []
  let splittedQuery = p.reduce((acc, ele) => {
    acc[ele.split('=')[0]] = ele.split('=')[1]
    return acc;
  }, {})
  return splittedQuery || {};
}

export function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}


export const setCookies = (cookies) => {
  // Setting cookies to be sent in the headers vie browser
  if (cookies) {
    cookies&& cookies.map((ele) => {
      Cookies.set(ele?.key, ele?.value, {
        path: '/',
        domain: '.ottplay.com',
        // domain:'localhost'
        // secure: Boolean(process.env.isLocal)
      });
    });
  }

};

export const logEvent = (category = '', action = '', label = '',value = null) => {
  if (category && action) {
    if (value) {      
      ReactGA.event({ category, action, label ,value})
    }
    else{
      ReactGA.event({ category, action, label})

    }
  }
}

export function popupModal(msg,duration){
  console.log(msg)
  showPopup();
  setTimeout(() => {
    hidePopup();
  }, duration);
}

export function showPopup() {
  document.getElementById("mobileoverlay").style.display = "block";
}
export function hidePopup() {
  document.getElementById("mobileoverlay").style.display = "none";
}

export function removeSpecialCharacter(string){
  return string.replace(/[^a-zA-Z0-9]/g, '_');
}


export function generateCurlCommand(url, options) {
  let command = `curl -X ${options.method||"GET"} "${url}"`;

  if (options.headers) {
    for (const [key, value] of Object.entries(options.headers)) {
      command += ` -H "${key}: ${value}"`;
    }
  }

  if (options.body) {
    command += ` -d '${options.body}'`;
  }

  return command;
}
