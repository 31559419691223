import React, { useEffect, useRef, useState } from "react";
import Header from '../Components/header'
import Footer from '../Components/footer'
import { apiUrl } from "../site.config";
import { navigate } from "../navigate";
import Cookies from "js-cookie";
import { generateAuthorization } from "../utils/captchGenerate";


export default function Login() {

    const [value, setValue] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [error, setError] = useState(false);
    const [isValid, setIsValid] = useState(false)
    let cur = useRef(-1);
    let footSelect;
    useEffect(() => {
        footSelect = document.querySelectorAll("[foot-selectable='true']");
        let myreqbtn = document.getElementById('requestOTP')
        document.getElementById('numberinput').focus();
        setTimeout(() => {
            myreqbtn.addEventListener('click', requestOtp)
            document.addEventListener('keydown', handlekey)
        }, 500)
        return () => {
            myreqbtn.removeEventListener("click", requestOtp);
            document.removeEventListener('keydown', handlekey)
        }

    }, []);
    function handlekey(e) {
        let submitBtn = document.getElementById('requestOTP')

        switch (e.key) {
            case "Backspace":
                e.preventDefault();
                return;
            case "ArrowUp":
                if (document.activeElement === document.getElementById('numberinput')) {
                    return;
                }
                if (submitBtn.classList.contains('btnDisabled') === false && !(document.activeElement === submitBtn)) {
                    footSelect[cur.current].classList.remove('activeFont');
                    submitBtn.focus();
                    cur.current = -1;
                }
                else if (cur.current >= 0) {
                    footSelect[cur.current].classList.remove('activeFont');
                    document.getElementById('numberinput').focus();
                    cur.current = -1;
                }
                else {
                    submitBtn.blur();
                    document.getElementById('numberinput').focus();
                }
                break;
            case "ArrowLeft":
                if (cur.current === 1) {
                    footSelect[cur.current].classList.remove('activeFont');
                    cur.current = cur.current - 1;
                    footSelect[cur.current].classList.add('activeFont');
                }
                break;
            case "ArrowRight":
                if (cur.current === 0) {
                    footSelect[cur.current].classList.remove('activeFont');
                    cur.current = cur.current + 1;
                    footSelect[cur.current].classList.add('activeFont');
                }
                break;
            case "ArrowDown":
                e.preventDefault();
                if (cur.current >= 0) {
                    return;
                }
                document.getElementById('numberinput').blur();
                if (document.activeElement === submitBtn) {
                    cur.current = cur.current + 1;
                    submitBtn.blur();
                    footSelect[cur.current].classList.add('activeFont');
                }
                else if (submitBtn.classList.contains('btnDisabled') === false) {
                    submitBtn.focus();
                }
                else if (cur.current < 0) {
                    cur.current = cur.current + 1;
                    footSelect[cur.current].classList.add('activeFont');
                }
                break;
            case "Enter":
                let cellNumber = document.getElementById('numberinput') && document.getElementById('numberinput')?.value;
                if (document.activeElement === submitBtn) {
                    document.getElementById('requestOTP') && document.getElementById('requestOTP').blur();
                    if (cellNumber && cellNumber.length !== 10) return;
                    requestOtp();
                }
                if (cur.current >= 0) {
                    let path = footSelect[cur.current].getAttribute('to')
                    navigate(e,path);
                }

                break;
            default:
                break;
        }

    }
    const checkValidation = (e) => {
        setError(false);
        let cellNumber = document.getElementById('numberinput')
        if (cellNumber?.value?.toString()?.length > 10) {
            cellNumber.value = cellNumber?.value.toString().slice(0, 10);
            return;
        }
        setIsValid(true)
        if (new RegExp(/^[0]?[789]\d{9}$/).test(e.target.value)) {
            setValue(e.target.value)
            setIsValid(false)
            removeClass('requestOTP', 'btnDisabled')
        }
        else {
            addClass('requestOTP', 'btnDisabled')
        }
    }

    function removeClass(target, className) {
        var element = document.getElementById(target);
        element.classList.remove(className);
    }
    function addClass(target, className) {
        var element = document.getElementById(target);
        element.classList.add(className);
    }


     function requestOtp() {
        let cellNumber = document.getElementById('numberinput')?.value;
        if (cellNumber.length !== 10) return;
        let data = {
            "otpFor": "SIGN_UP",
            "referrer": "OTT",
            "cellNumber": `91${parseInt(cellNumber)}`,
        }
        let bodyData = {
            "otpFor": "SIGN_UP",
            "referrer": "OTT",
            "cellNumber": `91${parseInt(cellNumber)}`,
            "authPayload": JSON.stringify(data)
        }
        let token = generateAuthorization(data);
        let headers = {
            'accept': 'application/json, text/plain, */*',
            'Accept-Encoding': 'gzip',
            'apiversion': '1',
            'authorization': "",
            'Connection': 'Keep-Alive',
            'Content-Length': '159',
            'Content-Type': 'application/json',
            'Host': 'accounts.hindustantimes.com',
            'User-Agent': 'okhttp/4.10.0',
            'x-authorization': token,
            'x-client': '1016',
            'x-platform': 'android',
        }
        let checkRegisterUrl = `${apiUrl}/v3.2/mobile/user/generateOtp`
        let registeredResponse =  fetch(checkRegisterUrl, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(bodyData),
        }).then((r) => r.json())
        .then(function (registeredResponse) {
            // This is the JSON from our response
            console.log("loginDetails",registeredResponse);
            if (registeredResponse?.data?.data?.valid) {
                Cookies.set('value', cellNumber)
                localStorage.setItem('value', cellNumber)                
                Cookies.set('registered', registeredResponse?.data?.data?.registered || false)
                navigate({},'/otp')
            }
            else if (!registeredResponse?.data?.success) {
                setErrorMsg(registeredResponse?.data?.message?.text)
                setError(true);
                document.getElementById("numberinput").value = "";
                document.getElementById("numberinput") &&
                    document.getElementById("numberinput").focus();
    
            }
          }).catch(function (err) {
            // There was an error
            console.warn('Something went wrong.', err);
          });
        // if (registeredResponse?.data?.data?.valid) {
        //     Cookies.set('value', cellNumber)
        //     Cookies.set('registered', registeredResponse?.data?.data?.registered || false)
        //     navigate('/otp')
        // }
        // else if (!registeredResponse?.data?.success) {
        //     setErrorMsg(registeredResponse?.data?.message?.text)
        //     setError(true);
        //     document.getElementById("numberinput").value = "";
        //     document.getElementById("numberinput") &&
        //         document.getElementById("numberinput").focus();

        // }
    }
    return (
        <>
            {/* <Header page="login" /> */}
            <div className="loginWrap">
                <div className="loginBox">
                    <h4 className="loginBoxHeading">Sign in using mobile number</h4>
                    <div className="inputPrimaryPhone">
                        <input
                            type="text"
                            name="countryCode"
                            value="+91"
                            className="countryCode"
                            disabled
                        />
                        <span className="redAsterisk">*</span>
                        <input
                            id="numberinput"
                            type="tel"
                            pattern="/^-?\d+\.?\d*$/"
                            name="cellNumber"
                            placeholder="10 Digit Mobile No."
                            // max="10"
                            className="phoneNumber"
                            onChange={(e) => checkValidation(e)}
                        />
                    </div>

                    {isValid && <span className="error">
                        Please enter 10 digit mobile number
                    </span>}
                    {error ? (
                        <span className="error">{errorMsg || ""}</span>
                    ) : null}

                    <button id="requestOTP" className="proceedBtn btnDisabled" >
                        Request OTP
                    </button>
                    <div className="jioLoginBottom">
                        By signing up, you agree to our <br />
                        <span id="p&p" to='/privacy-policy' foot-selectable='true' className="">Privacy Policy</span> and <span id="terms" to='/terms' foot-selectable='true' className="">Terms & Conditions</span>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
            <style jsx="true">
                {`
            .loginWrap{
                background-color:#270048;
                padding: 50px 10px 53px;
            }
            .loginBox h4{
                font-size:16px;
                line-height:20px;
                color:#ffffff;
                margin:0;
                padding: 5px 0 10px 0;
                text-align:center;
                font-weight:600;
            }
            .inputPrimaryPhone {
                overflow:hidden;
                padding: 5px;
                border: 1px solid #725D9B;
                border-radius: 6px;
                background:#0A0417;
                color: #ffffff;
                font-size: 13px;
                font-weight: 400;
                outline: none;
                display: flex;
                font-weight:600;
            }
            .inputPrimaryPhone:focus,
            .inputPrimaryPhone:active,
            .inputPrimaryPhone:focus-visible {
                outline: none;
            }
            
            .inputPrimaryPhone::placeholder {
                color: #8473A5;
                font-weight: 400;
            }
            
            .inputPrimaryPhone .countryCode{
                border: 0;
                background: #0A0417;
                color: #ffffff;
                font-size: 15px;
                font-weight: 600;
                padding: 5px;
                outline: none;
                width:30px;
            }
            .inputPrimaryPhone .phoneNumber{
                border: 0;
                background: #0A0417;
                color: #ffffff;
                font-size: 15px;
                font-weight: 600;
                padding: 5px;
                outline: none;
                width:calc(100% - 30px);
                box-shadow:none;
                letter-spacing:1px;
            }
            .inputPrimaryPhone input:focus,
            .inputPrimaryPhone input:active,
            .inputPrimaryPhone input:focus-visible {
                outline: none;
            }
            
            .inputPrimaryPhone input::placeholder {
                color: #8473A5;
                font-weight: 400;
            }
            .error{
                font-size:12px;
                color:#FF006F;
                display:inline-block;
                padding:5px 0 10px;
            }
            .proceedBtn {
                width: 100%;
                display: block;
                background: #521384;
                font-size: 14px;
                color: #ffffff;
                padding: 8px 5px;
                border: 2px solid #6413A7;
                margin: 10px 0;
                border-radius: 4px;
                cursor: pointer;
                font-weight:700;
            }
            .proceedBtn.btnDisabled {
                cursor: not-allowed;
                opacity: 0.5;
                background: transparent;
                border:1px solid #9989B5;
                color: #9989B5;
            }
            .jioLoginBottom{
                text-align: center;
                margin-top:25px;
                color:#B1A4C9;
                font-size: 12px;
                line-height: 18px;
            }
            .jioLoginBottom > span{
                text-decoration: underline;
                cursor:pointer;
            }
            .proceedBtn:focus{
                color: #270048;
                background: #29F87E;
                border:2px solid #29F87E;
                outline:none;
            }
            #numberinput:focus{
                border:none;
                outline:none 
            }
            .activeFont{
                font-weight: 700;
                color: rgb(2, 248, 126);
            }
        `}
            </style>
        </>
    );
}


// background: rgb(104,15,170);
