import React, { useEffect, useState, useRef } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import Image from "../Components/Image";
import { navigate } from "../navigate";
import Cookies from "js-cookie";
// import { apiUrl } from "../site.config";
import Loader from "../Components/loader";
import { activeBtnColor } from "../utils/helper";

const Allpartners = ({ type = "", parent = 'Home' }) => {
  const { end_point = '', section = '', template_name = '', title = '', page = 'Home', seo_url = '',pin_it=true } = window?.history?.state;
  let providerApi = Cookies.get('providerApi')
  const currentRef = useRef(false)
  const pageLimit = useRef(1)
  const cur = useRef(0);
  const [Page, setpage] = useState(1);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState('item_0')
  const [totalCountNumber, setTotalCountNumber] = useState(0)
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    ( () => {
      setLoading(true);
       getData();
      // setTimeout(() => {
      //   handleActiveBorder();
      //   setLoading(false);
      // }, 1500);
    })()
    return () => { document.removeEventListener("keydown", handlekey) }
  }, []);

  const handleActiveBorder = () => {
    currentRef.current = false
    let v = document.getElementById(selectedItem);
    if (v) {
      v.classList.add('active')
      document.addEventListener("keydown", handlekey);
    }
  }

  const handlekey = (e) => {
    e.preventDefault();
    let keyName = e.key;
    let id = document.querySelector('.active')?.id
    let licount = document.querySelectorAll('li').length;
    if (id) {
      let eleId = parseInt(document.querySelector('.active').id.split('_')[1]);
      switch (keyName) {
        case "ArrowRight":
          if (eleId < licount - 1) {
            handlHorizontal(eleId, eleId + 1)
          }
          break;
        case "ArrowLeft":
          if (eleId > 0) {
            handlHorizontal(eleId, eleId - 1)
          }
          break;
        case "ArrowUp":
          if (eleId > 2) {
            handlHorizontal(eleId, eleId - 3)
          }
          else if (cur.current === 0) {
            document.getElementById(`item_${eleId}`).classList.remove('active')
            let headerNav = document.querySelectorAll('[head-selectable]')[0];
            headerNav.style.outline = `2px solid ${activeBtnColor}`
            cur.current -= 1;
          }
          break;
        case "ArrowDown":
          if (eleId < licount - 3) {
            handlHorizontal(eleId, eleId + 3)
          }
          else {
            let btn = document.getElementById('loadmrbtn');
            if (btn) {
              document.getElementById(`item_${eleId}`).classList.remove('active')
              btn.focus();
              btn.style.outline = "2px solid red";
            }
          }
          break;
        case "Enter":
          let btn = document.getElementById('loadmrbtn');
          if (document.activeElement === btn) {
            btn.blur();
            btn.style.removeProperty('outline');
            document.querySelectorAll('li')[licount - 1].classList.add('active')
            getData();
          } else {

            handleOk(id,e);
          }
          break;

        default:
          break;
      }
    }
    else {
      let btn = document.getElementById('loadmrbtn');
      switch (keyName) {

        case "ArrowRight":
          if (cur.current === -2) return;
          if (cur.current === -1) {
            let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
            let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
            headerNavSubs.style.removeProperty('box-shadow')
            headerNavSubs.style.removeProperty('outline')
            headerNavProfile.style.boxShadow = `0px 0px 6px #ffffff`;
            cur.current -= 1;
          }
          break;
        case "ArrowLeft":
          if (document.activeElement === btn) {
            btn.style.removeProperty("outline")
            btn.blur();
            document.querySelectorAll('li')[licount - 1].classList.add('active')
            document.querySelectorAll('li')[licount - 1].scrollIntoView(false)
          }
          else if (cur.current === -2) {
            let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
            let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
            headerNavProfile.style.removeProperty('box-shadow')
            headerNavSubs.style.boxShadow = `0px 0px 6px #ffffff`;
            cur.current += 1;
          }
          break;
        case "ArrowUp":
          if (document.activeElement === btn) {
            btn.style.removeProperty("outline")
            btn.blur();
            document.querySelectorAll('li')[licount - 1].classList.add('active')
          }
          break;
        case "ArrowDown":
          if (cur.current === -1 || cur.current === -2) {
            let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
            if (headerNav) {
              headerNav.style.removeProperty("box-shadow");
              headerNav.style.removeProperty("outline");
            };
            cur.current = 0;
            document.querySelectorAll('li')[0].classList.add('active')

            return
          };
          break;
        case "Enter":
          if (document.activeElement === btn) {
            btn.blur();
            btn.style.removeProperty('outline');
            document.querySelectorAll('li')[licount - 1].classList.add('active')
            getData();
            document.querySelectorAll('li')[licount - 1].scrollIntoView(false)
          }
          if (cur.current == -1) {
            let headerNavSubs = document.querySelectorAll('[head-selectable]')[0].title;
            if (headerNavSubs) {
              navigate(e`${headerNavSubs}`)
            }
            return;
          }
          else if (cur.current === -2) {
            let headerNavProfile = document.querySelectorAll('[head-selectable]')[1].title;
            if (headerNavProfile) {
              navigate(e,`${headerNavProfile}`)
            }
            return;
          }
          break;

        default:
          break;
      }
    }
  }


  const handleOk = (id,e={}) => {
    if (type === "partners") {
      // Cookies.set('providersId',document.getElementById(id).getAttribute('seo_url'))
    }
    let state = {
      content_type: document.getElementById(id).getAttribute('contenttype'),
      ottplay_id: document.getElementById(id).getAttribute('ottplay_id'),
      provider: document.getElementById(id).getAttribute('providers'),
      _id:document.getElementById(id).getAttribute('_id'),
    }
    let showstate = {
      seo_url: document.getElementById(id).getAttribute('seo_url')
    }
    let path = document.getElementById(id).getAttribute('to');
    path?.includes('/player') ? navigate(e,path, state) : navigate(e,path, showstate);
  }
  const handlHorizontal = (currentId, actionId, dir) => {
    document.getElementById(`item_${currentId}`).classList.remove('active')
    document.getElementById(`item_${actionId}`).classList.add('active')
    document.getElementById(`item_${actionId}`).scrollIntoView(false)
    setSelectedItem(`item_${actionId}`)
  }


  const getData =  () => {
    setpage((prev) => prev + 1)
    let result =  fetch(providerApi,{ headers: { authorization:'bearer 34B7FBD24B97D7A4CF717D414F2A8'} }).then((r) => r.json())
    .then(function (result) {
      // This is the JSON from our response
      if (!result?.data[result?.data.length-1].hasOwnProperty('name')) {
        result?.data.pop();
      }
      console.log("allpartners",result)

    setTotalCountNumber(result?.data?.length)
    if (result?.data?.length && Array.isArray(result?.data)) {
      setTimeout(() => {
        handleActiveBorder();
        setLoading(false);
      }, 1000);
      pageLimit.current = pageLimit.current + 1;
      setpage(Page + 1)
      // if (result?.data?.length < 9) setLoadMore(true);
      setData((prev) => [...prev, ...result?.data]);
    } else {
      setLoading(false);
      setData(data || []);
    }
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });
    // console.log("dddddddd",result)
    // setTotalCountNumber(result?.data?.length)
    // if (result?.data?.length && Array.isArray(result?.data)) {
    //   pageLimit.current = pageLimit.current + 1;
    //   setpage(Page + 1)
    //   // if (result?.data?.length < 9) setLoadMore(true);
    //   setData((prev) => [...prev, ...result?.data]);
    // } else {
    //   setData(data || []);
    // }
  };

  return (
    <>
      {/* <Header /> */}
      <div className="partnerWrap">
        <h2>All OTT Partners</h2>
        {Array.isArray(data) && data?.length ? (

          <ul className="partnerReasult">
            {data?.map((data, id) =>
              <li id={`item_${id}`} contenttype={data?.content_type || ''}  seo_url={data?.seo_url || ''} to={!data?.name?.includes('ABP')?`/provider-details?seo_url=${data?.seo_url}`:'/livetv-home'} key={id} data-content_type={data?.content_type} ref={currentRef} className="">
                <a>
                  <Image src={data?.logo_url} width="71" height="45" className="landscapeImg"/>
                </a>
              </li>
            )}
          </ul>
        ) : null}
        {/* {!loadMore && data?.length  ? (
          <button
            id="loadmrbtn"
            onClick={getData}
            style={{ color: "black", marginBottom: "30px" }}
          >
            Load More{" "}
          </button>
        ) : null} */}
      </div>
      {loading ? <div className="loaderPlan"><Loader /></div> : null}

      <div class="space"></div>
      {/* <Footer /> */}

      <style jsx="true">{`
        .partnerWrap {
          background: #270048;
          width: 100%;
          height: calc(100vh - 35px);
          padding: 40px 0px 80px 0px;
          box-sizing: border-box;
          overflow: scroll;
          border-bottom:1px solid transparent;
          border-opacity:0
          
        }
        
        .partnerWrap{
          -ms-overflow-style: none; 
          scrollbar-width: none;
        }
        .partnerWrap::-webkit-scrollbar {
          display: none;
        }
        
        .partnerWrap h2 {
          margin: 0;
          color: #fff;
          font-size: 15px;
          margin-bottom: 10px;
          font-weight: 600;
        }
        .partnerWrap .partnerReasult {
          margin: 0;
          padding: 0 2px 0px 3px;
          list-style: none;
          display: flex;
          justify-content: flex-start;         
          flex-wrap: wrap;
        }
        .partnerWrap .partnerReasult li {
          margin-bottom: 8px;
          border-radius: 3px;
          overflow: hidden;
          margin-right:5px;
          box-sizing:border-box;
          flex:0 0 31%;
        }
        .partnerWrap .partnerReasult li:last-child{
          margin-bottom:15px;
        }
        .partnerWrap .partnerReasult li a {
          width: 71px;
          height: 45px;
          display: block;
          border-radius: 3px;
        }
        .partnerWrap .partnerReasult li a > span{
          display:inline !important;
        }
        .partnerWrap .partnerReasult li img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .partnerWrap .partnerReasult li img.landscapeImg{
          height:45px;
        }
        .partnerWrap .partnerReasult li img.potraitImg{
            height:107px;
        } 
        .partnerWrap button {
          margin: 0;
          height: 30px;
          width: 100%;
          background-color: #03f87e;
          padding: 4px 7px;
          border-radius: 2px;
          line-height: 14px;
          font-size: 12px;
          font-weight: 700;
          color: #270048 !important;
          border: 0;
        }
      `}</style>
    </>
  );
};

export default Allpartners;
