
import React, { useContext, useEffect, useRef, useState } from 'react'
import 'intersection-observer';
import { navigate } from '../navigate'
import ListContainer from '../Components/listContainer';
import FeaturedCarousel from '../Components/featuredCarosel';
import CardContainer from '../Components/cardConatiner';
import LangCarousel from '../Components/langCarousel';
import Header from '../Components/header';
import Footer from '../Components/footer';
import Cookies from 'js-cookie';
import OttPartners from '../Components/OttPartners';
import { UserContext } from '../Contexts/userContext';
import { activeElementBorderColor, logEvent, popupModal } from '../utils/helper';
import { apiUrl } from '../site.config';
import PackAlertPopup from '../Components/packAlertPopup';
import Loader from '../Components/loader';
import ContinueContainer from '../Components/continueContainer';

export default function HalfHome({divref}) {

    const componentRef = useRef();
    let loggedIn = Cookies.get('logged_in') || false;
    let clientId = Cookies.get('clientId') || ''
    const [loading, setLoading] = useState(false);
    const [rankingData, setRankingData] = useState([])


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    console.log("widget is on screen")
                    getSubData();
                    observer.unobserve(divref.current);
                }
            },
            { threshold: 1 } // Trigger when 50% of component is visible
        );
        if (observer && divref.current) {
            observer.observe(divref.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [divref]);

   
    const getSubData = () => {
        fetch(`${apiUrl}/v2.2/widgets?menu=Home`, { headers: { 'client_id': clientId, 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } })
            .then(function (response) {
                // The API call was successful!
                return response.json();
            }).then(function (data) {
                // This is the JSON from our response
                console.log(data);
                if (data?.data && data?.data?.length) {
                    setRankingData(data.data)
                }
                else {
                    setLoading(false)
                    setRankingData([])
                }
            }).catch(function (err) {
                // setLoading(false)
                // There was an error
                console.warn('Something went wrong.', err);
            });
    }

    const conditionalRender = (item, id) => {

        switch (item?.template_name) {
            case 'subscription_banner':
                return <FeaturedCarousel item={item} key={id} id={id} uniqueId={'featuredCarousel'} setLoading={setLoading} />
            case 'providers':
                return <OttPartners item={item} key={`home-${id}`} id={id} uniqueId={'ottpartners'} page={"Home"} />
            case 'live_tv':
                return <CardContainer item={item} componentRef={componentRef} loading={loading} longWidget={false} content_type={item?.content_type} key={id} id={id} uniqueId={'liveTV'} page={"Home"} />
            case 'movies':
                return <ListContainer item={item} key={`nav-${id}`} id={id} uniqueId={'top10Movies'} page={"Home"} />
            case 'shows':
                return <ListContainer item={item} key={`nav-${id}`} id={id} uniqueId={'top10Shows'} page={"Home"} />
            case 'recommended_movies_for_you':
                return <ListContainer item={item} key={`nav-${id}`} id={id} uniqueId={'recommended_movies_for_you'} page={"Home"} />
            case 'recommended_shows_for_you':
                return <ListContainer item={item} key={`nav-${id}`} id={id} uniqueId={'recommended_shows_for_you'} page={"Home"} />
            case 'new_release':
                return <CardContainer item={item} componentRef={componentRef} loading={loading} longWidget={true} content_type={'movie'} key={`nav-${id}`} id={id} uniqueId={'newRelease'} page={"Home"} />
            case 'trending_in_sports':
                return <CardContainer item={item} componentRef={componentRef} loading={loading} longWidget={true} content_type={item?.content_type} key={`nav-${id}`} id={id} uniqueId={item.section} page={"Home"} />
            case 'top_languages':
                return <LangCarousel item={item} key={`home-${id}`} uniqueId={'top_languages'} />
            case 'continue_watching':
                return loggedIn ? <ContinueContainer item={item} setLoading={setLoading} longWidget={true} content_type={'movie_show'} key={`nav-${id}`} id={id} uniqueId={'k'} page={"Home"} /> : null
            default:
                break;
        }
    }
    return (
        <>
            <div className='2222render' ref={divref}>

                {
                    !!rankingData?.length && rankingData.slice(4)?.map((item, id) => { return conditionalRender(item, id) }
                    )
                }
            </div>

        </>
    )
}