import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import SeasonDropdown from "../Components/seasonDropdown";
import { navigate } from "../navigate";
import { apiUrl } from "../site.config";
import { activeBtnColor } from "../utils/helper";

export default function ShowDetails() {
  const [seo_url, setSeo_url] = useState(window?.history?.state?.seo_url || "");
  const [subscriptionType, setSubscriptionType] = useState(
    window?.history?.state?.subscriptionType || ""
  );

  const [showSeasonDropdown, setSeasonDropdown] = useState(false);
  let showSeasondrop = useRef(false)
  const [seasonsNumber, setSeasonsNumber] = useState("");
  const [episodeNumber, setEpisodesNumber] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const [showData, setShowData] = useState({});
  const [episodeData, seteEisodeData] = useState([]);
  let episodesList = null;
  const curEpisode = useRef(0);
  const cur = useRef(0);
  const headers = { 'authorization':"bearer 34B7FBD24B97D7A4CF717D414F2A8"}
  const curSingleSeasonEpisode = useRef(0);
  const renderedtopten = useRef(false);
  const curMultipleSeason = useRef(null);


  useEffect(() => {
    fetchShowData();
    return () => document.removeEventListener("keydown", handleEpiodekeyDown);
  }, []);
  useEffect(() => {
    if (!(seasonsNumber>-1)) return;
    (() => {
      fetchSeasonData();
    })();
    return () => {
      document.removeEventListener("keydown", handleEpiodekeyDown);
      document.removeEventListener("click", handleSeasonkeydown);
    };
  }, [seasonsNumber]);

  const fetchShowData = () => {
    // let queryString = `seoUrl=${seoUrl}`
    let apiurl = `${apiUrl}/v4.5/mobile/show/seo/url?seoUrl=${seo_url}`;
    // let data = await fetchData(end_points, queryString)
    fetch(apiurl,{headers:headers})
      .then(function (response) {
        // The API call was successful!
        return response.json();
      }).then(function (data) {
        // This is the JSON from our response
        console.log("showData", data);
        data = data?.data;

        if (Object.keys(data).length) {
          setShowData(data);
          setBtnDisable(
            data.hasOwnProperty("seasons") && data?.seasons.length > 1
              ? false
              : true
          );
          setSeasonsNumber(
            data.hasOwnProperty("seasons")
              ? data?.seasons[0]?.season_number || ""
              : null
          );
          setEpisodesNumber(
            data.hasOwnProperty("seasons")
              ? data?.seasons[0]?.number_of_episodes
              : null
          );
          showData.hasOwnProperty("seasons");
        }
        if (
          data.seasons &&
          Array.isArray(data?.seasons) &&
          data?.seasons?.length > 1
        ) {
          curMultipleSeason.current = null;
          setTimeout(() => {
            let seasonDropdownBtn = document.querySelector(".seasonSelect");
            if (seasonDropdownBtn) {
              seasonDropdownBtn.focus();
              seasonDropdownBtn.addEventListener("click", handleSeasonkeydown);
            }
          }, 0);
        }
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
  };

  const fetchSeasonData = () => {
    let apiurl = `${apiUrl}/v4.6/episode/seo/url?seoUrl=${seo_url}&limit=10&page=1&sortBy=${episodeNumber > 50 ? "desc" : "asc"
      }&seasonNumber=${seasonsNumber}`;
    fetch(apiurl,{headers:headers})
      .then(function (response) {
        // The API call was successful!
        return response.json();
      }).then(function (data) {
        // This is the JSON from our response
        console.log("seasonDat2", data);
        if (Object.keys(data).length) {
          seteEisodeData(data?.episodes);
          setTimeout(() => {
            renderedtopten.current = true;
            renderedtopten.current && seasonEpisodeEvents();
          }, 200);
        }
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
  };
  function handleSeasonkeydown(e) {
    setSeasonDropdown(true);
    showSeasondrop.current = true;
  }

  function seasonEpisodeEvents() {
    episodesList = document.querySelectorAll("[list-selectable='true']");
    if (episodesList && renderedtopten) {
      renderedtopten.current = false;
      if (showData && showData.seasons.length === 1) {
        episodesList[curSingleSeasonEpisode.current].classList.add('activeEpisode')
      }
      else {
        document.querySelector(".seasonSelect").focus();
      }
      document.addEventListener("keydown", handleEpiodekeyDown);
    }
  }
  function dateFormatter(date) {
    let datev = new Date(date)
    return datev.toDateString();
  }
  function handleEpiodekeyDown(e) {
    if (showSeasondrop.current) {
      e.preventDefault();
      return;
    }
    switch (e.key) {
      case "ArrowUp":
        if (showData?.seasons.length > 1) {
          if (
            curMultipleSeason.current === false &&
            curEpisode.current >= 1 &&
            curEpisode.current !== episodesList.length - 1
          ) {
            curMultipleSeason.current = true;
            curEpisode.current -= 1;
          }
          curMultipleSeason.current = true;
          if (document.activeElement === document.querySelector(".seasonSelect")) {
            document.querySelector(".seasonSelect").blur();
            let headerNav = document.querySelectorAll('[head-selectable]')[0];
            headerNav.style.outline = `2px solid ${activeBtnColor}`
            cur.current = -1;
            return;
          }
          if (curEpisode.current === 0) {
            curMultipleSeason.current = false;
            let seasonDropdownBtn = document.querySelector(".seasonSelect");
            seasonDropdownBtn.focus();
            episodesList[curEpisode.current].classList.remove('activeEpisode')

          }

          else {
            episodesList[curEpisode.current - 1].classList.add('activeEpisode')

            if (curEpisode.current >= 0)
              episodesList[curEpisode.current].classList.remove('activeEpisode')
            if (curEpisode.current !== 0)
              curEpisode.current = curEpisode.current - 1;
          }
        } else {
          if (curSingleSeasonEpisode.current === 0) {
            episodesList[curEpisode.current].classList.remove('activeEpisode')
            let headerNav = document.querySelectorAll('[head-selectable]')[0];
            headerNav.style.outline = `2px solid ${activeBtnColor}`
            cur.current = -1;
            return;
          };
          episodesList[curSingleSeasonEpisode.current].classList.remove('activeEpisode')
          episodesList[curSingleSeasonEpisode.current - 1].classList.add('activeEpisode')
          if (curSingleSeasonEpisode.current !== 0)
            curSingleSeasonEpisode.current = curSingleSeasonEpisode.current - 1;
        }
        break;
      case "ArrowDown":
        if (showData?.seasons.length > 1) {
          if (cur.current === -1 || cur.current === -2) {
            let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
            if (headerNav) {
              headerNav.style.removeProperty("box-shadow");
              headerNav.style.removeProperty("outline");
            };
            cur.current = 0;
            let seasonDropdownBtn = document.querySelector(".seasonSelect");
            seasonDropdownBtn.focus();
            curEpisode.current = 0;
            return;
          }
          if (curEpisode.current === 0) {
            let seasonDropdownBtn = document.querySelector(".seasonSelect");
            seasonDropdownBtn.blur();
          }
          if (curMultipleSeason.current) {
            curEpisode.current = curEpisode.current !== 0 ? curEpisode.current + 1 : curEpisode.current;
            curMultipleSeason.current = false;
          }
          episodesList[curEpisode.current].classList.add('activeEpisode')

          episodesList[curEpisode.current].setAttribute("active", "true");
          if (curEpisode.current > 0)
            episodesList[curEpisode.current - 1].classList.remove('activeEpisode')

          if (curEpisode.current !== episodesList.length - 1)
            curEpisode.current = curEpisode.current + 1;
        } else {
          if (cur.current === -1 || cur.current === -2) {
            let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
            if (headerNav) {
              headerNav.style.removeProperty("box-shadow");
              headerNav.style.removeProperty("outline");
            };
            cur.current = 0;
            episodesList[curSingleSeasonEpisode.current].classList.add('activeEpisode')
            return;
          }
          if (curSingleSeasonEpisode.current === episodesList.length - 1)
            return;
          episodesList[curSingleSeasonEpisode.current].classList.remove('activeEpisode')

          if (curSingleSeasonEpisode.current !== episodesList.length - 1)
            curSingleSeasonEpisode.current = curSingleSeasonEpisode.current + 1;
          episodesList[curSingleSeasonEpisode.current].classList.add('activeEpisode')
        }
        break;
      case "ArrowRight":
        if (cur.current === -2) return;
        if (cur.current === -1) {
          let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
          let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
          headerNavSubs.style.removeProperty('box-shadow')
          headerNavSubs.style.removeProperty('outline')
          headerNavProfile.style.boxShadow = `0px 0px 6px #ffffff`;
          cur.current = -2;
        }
        break;
      case "ArrowLeft":
        if (cur.current === -2) {
          let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
          let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
          headerNavProfile.style.removeProperty('box-shadow')
          headerNavSubs.style.boxShadow = `0px 0px 6px #ffffff`;
          cur.current = -1;
        }
        break;
      case "Enter":
        if (showData?.seasons.length > 1) {
          let seasonDropdownBtn = document.querySelector(".seasonSelect");
          if (document.activeElement === seasonDropdownBtn) {
            seasonDropdownBtn.blur();
            handleSeasonkeydown();
            return;
          }
          else {
            if (cur.current == -1) {
              let headerNavSubs = document.querySelectorAll('[head-selectable]')[0].title;
              if (headerNavSubs) {
                navigate(e,`${headerNavSubs}`)
              }
              return;
            }
            else if (cur.current === -2) {
              let headerNavProfile = document.querySelectorAll('[head-selectable]')[1].title;
              if (headerNavProfile) {
                navigate(e,`${headerNavProfile}`)
              }
              return;
            }
            let path = episodesList[curSingleSeasonEpisode.current]?.getAttribute('to');
            navigate(e,path)
          }
        }
        else {
          if (cur.current == -1) {
            let headerNavSubs = document.querySelectorAll('[head-selectable]')[0].title;
            if (headerNavSubs) {
              navigate(e,`${headerNavSubs}`)
            }
            return;
          }
          else if (cur.current === -2) {
            let headerNavProfile = document.querySelectorAll('[head-selectable]')[1].title;
            if (headerNavProfile) {
              navigate(e,`${headerNavProfile}`)
            }
            return;
          }
          let state = {
            content_type: episodesList[curSingleSeasonEpisode.current]?.getAttribute('contentType'),
            ottplay_id: episodesList[curSingleSeasonEpisode.current]?.getAttribute('ottplay_id'),
            provider: episodesList[curSingleSeasonEpisode.current]?.getAttribute('providers'),
          }
          let path = episodesList[curSingleSeasonEpisode.current]?.getAttribute('to');
          navigate(e,path, state)
        }
        break;
      default:
        break;
    }
  }

  return (
    <>
      {/* <Header /> */}
      <div className="showDetailsWrap">
        <div className="showTitleWrap">
          <div className="showThumbnail">
            <img
              src={showData?.backdrops}
              width="77"
              height="52"
              intial="render"
            />
          </div>
          <div className="showTitle">
            <h2>{showData?.display_name}</h2>
            <div className="showGenres">
              <span>{showData?.genres}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                fill="#9989B5"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
              </svg>
              <span>{showData?.primary_language}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                fill="#9989B5"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
              </svg>
              <span>{episodeNumber}</span>
            </div>
          </div>
        </div>
        {seasonsNumber ? (
          <div className="episodesDetails">
            <h3>Episodes</h3>
            <button className="seasonSelect">{`Season ${seasonsNumber}`}</button>
          </div>
        ) : null}
        <ul className="episodesList">
          {Array.isArray(episodeData) &&
            !!episodeData.length &&
            episodeData.map((item, id) => {
              return (
                <li
                  id={`episodeList${id}`}
                  list-selectable="true"
                  className=""
                  contentType="show"
                  ottplay_id={`${item.ottplay_id}`}
                  providers={`${showData?.provider}`}
                  _id={showData._id || ''}
                  to={`/player?content_type=show&ottplay_id=${item?.ottplay_id}&provider=${showData?.provider}&subscriptionType=${subscriptionType}&_id=${showData._id || ''}&name=${showData?.display_name}&showData=S${item?.season_number}E${item?.episode_number}&seoUrl=${seo_url}&episodeNumber=${item?.episode_numbe}&seasonNumber=${item?.season_number}`}
                >
                  <h3>{item?.name}</h3>
                  <div className="showGenres">
                    <span>S{item?.season_number || 1} E{item?.episode_number}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7"
                      height="7"
                      fill="#9989B5"
                      className="bi bi-dot"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                    </svg>
                    <span>{dateFormatter(item?.air_date)}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7"
                      height="7"
                      fill="#9989B5"
                      className="bi bi-dot"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                    </svg>
                    <span>{item?.run_time}m</span>
                  </div>
                </li>
              );
            })}
        </ul>
        {showSeasonDropdown && (
          <SeasonDropdown
            seasons={
              showData.hasOwnProperty("seasons") ? showData?.seasons : []
            }
            seasonsNumber={seasonsNumber}
            setSeasonsNumber={setSeasonsNumber}
            setSeasonDropdown={setSeasonDropdown}
            showSeasondrop={showSeasondrop}
          />
        )}
      </div>
      {/* <Footer /> */}
      <style jsx="true">
        {`
          .showDetailsWrap {
            background-color: #270048;
            padding: 30px 0px 33px;
          }
          .showTitleWrap {
            display: flex;
            align-items: center;
            padding: 7px;
            background-color: #582484;
            gap: 5px;
          }
          .showThumbnail {
            flex: 0 0 65px;
            border-radius: 3px;
          }
          .showThumbnail img {
            border-radius: 3px;
          }
          .showTitle{
            margin-left:5px;
          }
          .showTitle h2 {
            color: #ffffff;
            font-size: 13px;
            font-weight: 700;
            margin: 0;
            padding-bottom: 5px;
          }
          .showGenres {
            display: flex;
            align-items: center;
          }
          .showGenres span {
            font-weight: 400;
            font-size: 10px;
            color: #9989b5;
            display: inline-block;
            margin: 0 2px;
          }
          .showGenres span:first-child {
            margin-left: 0px;
          }
          .episodesDetails {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 7px;
            gap: 8px;
          }
          .episodesDetails h3 {
            font-size: 12px;
            line-height: 17px;
            color: #ffffff;
            margin: 0;
            font-weight: 700;
          }
          .seasonSelect {
            font-size: 10px;
            font-weight: 700;
            line-height: 14px;
            color: #270048;
            border-radius: 2px;
            border: 0;
            padding: 4px 7px;
            padding-right: ${!btnDisable ? "23px" : "7px"};
            position: relative;
            background-color: #03f87e;
          }
          .seasonSelect::after {
            content: ${!btnDisable
            ? "url(https://images.ottplay.com/static/subscriptionimages/arrowBlack.svg)"
            : 'none'};
            background-size: 4px;
            background-color: #03f87e;
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-26%) rotate(90deg);
            width: 7px;
            height: 7px;
          }
          .episodesList {
            padding: 0 7px 7px;
            margin: 0;
            list-style-type: none;
          }
          .episodesList a {
            text-decoration: none;
          }
          .episodesList li {
            padding: 10px 10px 15px 25px;
            position: relative;
            border-bottom: 1px solid #0d0023;
            background-image: url(https://images.ottplay.com/static/jioImages/whitePlayIcon.svg);
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: 6px 12px;
          }
          .episodesList li.activeEpisode {
            background-image: url(https://images.ottplay.com/static/jioImages/greenPlayIcon.svg);
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: 6px 12px;
            background-color: #551787;
          }
          .episodesList li:last-child {
            border-bottom: 0;
          }
          .episodesList li h3 {
            font-size: 12px;
            line-height: 17px;
            margin: 0;
            font-weight: 600;
            color: #ffffff;
            padding-bottom: 5px;
          }
          .seasonSelect:focus {
            border: 2px solid white;
          }
        `}
      </style>
    </>
  );
}
