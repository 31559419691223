import FlashScreen from "./Pages/flashScreen";
// import About from "./Components/Pages/about";
// import Contact from "./contact";
import TopTen from "./Pages/topTen";
import Home from "./Pages/home";
// import Home from "./Pages/homepage";
import GridListingPage from "./Pages/gridListingPage";
import Search from "./Pages/search";
import VideoPlayer from "./Pages/videoPlayer";
import NavMenu from "./Pages/navMenu";
import Login from "./Pages/login";
import OtpScreen from "./Pages/otpScreen";
import ShowDetails from "./Pages/showDetails";
import PlansList from "./Pages/planListing";
import PlanDetails from "./Pages/planDetails";
import AllLanguage from "./Pages/allLanguages";
import LiveTv from "./Pages/liveTv";
import PartnerList from "./Pages/partnerList";
import AllPartners from "./Pages/allPartners";
import AllSearchResult from "./Pages/allSearchResults";
import MyProfile from "./Pages/myProfile";
import LangauageDetail from "./Pages/langauageDetail";
import ProviderDetail from "./Pages/providerDetail";
import TermsConditions from "./Pages/termsConditons";
import PrivacyPolicy from "./Pages/privacyPolicy";
// import ManageSubscription from "./Pages/manageSubscription";
// import CancelSubscription from "./Pages/cancelMembership";
// import ProviderGridListing from "./Pages/providerListingPage";
// import ErrorBoundary from "./utils/errorBoundary";
// const routess = ['/home','/view-all',"/show-detail","/videoplayer"] ; 

export const routes = {
    '/' : <FlashScreen/>,
    '/home' : <Home  />,
    '/re-widget' :<TopTen type = "shows"/>,
    '/partners' : < AllPartners/>,
    '/search' : <Search/>,
    '/player' : <VideoPlayer/>,
    '/menu' : <NavMenu/>,
    '/login' : <Login/>,
    '/otp' : <OtpScreen/>,
    '/show-details' : <ShowDetails/>,
    '/plans' : <PlansList/>,
    '/planDetails' : <PlanDetails/>,
    '/all-languages' : <AllLanguage/>,
    '/livetv-home' : <LiveTv/>,
    '/partner-list' : <PartnerList/>,
    '/results':<AllSearchResult/>,
    '/profile':<MyProfile/>,
    // '/manage-subscription':<ManageSubscription/>,
    // '/cancel-subscription':<CancelSubscription/>,
    '/language-detail':<LangauageDetail/>,
    '/provider-details':<ProviderDetail/>,
    '/gridListingPage' :  <GridListingPage  type="hindi_and_regional_entertainment" />,
    '/terms' :  <TermsConditions />,
    '/privacy-policy' :  <PrivacyPolicy />,


  };