import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { navigate } from "../index";
import { navigateBack } from "../index";
import { UserContext } from "../Contexts/userContext";

export default function Title({ title = "", template_name = "", section = '', end_point = '', id = "", page = '',content_type="" ,pin_it=true}) {
 
  let grigPageRoute = `/gridListingPage?end_point=${end_point}&template_name=${template_name}&section=${section}&title=${title}&page=${page}&pin_it=${pin_it}`;
  const User = useContext(UserContext)
  const [planName, setPlanName] = useState(User?.user?.planName || "Subscribe");
  const navigationRoutes = {
    providers: planName == "Subscribe" ? `/partners?end_point=${end_point}&template_name=${template_name}&section=${section}&title=${title}&page=${page}&pin_it=${pin_it}`  : `/partner-list?end_point=${end_point}&template_name=${template_name}&section=${section}&title=${title}&page=${page}&pin_it=${pin_it}`,
    top_languages: "/all-languages",
    movies: `/re-widget?end_point=${end_point}&template_name=${template_name}&section=${section}&title=${title}&page=${page}&pin_it=${pin_it}`,
    shows:`/re-widget?end_point=${end_point}&template_name=${template_name}&section=${section}&title=${title}&page=${page}&pin_it=${pin_it}`,
    recommended_shows_for_you:`/re-widget?end_point=${end_point}&template_name=${template_name}&section=${section}&title=${title}&page=${page}&pin_it=${pin_it}`,
    recommended_movies_for_you:`/re-widget?end_point=${end_point}&template_name=${template_name}&section=${section}&title=${title}&page=${page}&pin_it=${pin_it}`
  };
  let titleRoute = navigationRoutes[template_name]?navigationRoutes[template_name]:grigPageRoute;



  // const navigate = useNavigate()
  return (
    <>
      <div className="NewHeading">
        <h2>{content_type !== "mix_search".trim()?title:null}</h2>
        {navigationRoutes[template_name]|| page.length ? (
          <div
            className="view view-all"
            id={`view-all-title`}
            home-selectable="true"
            title={titleRoute}
            widgetname={title}
            template={template_name}
            section={section}
            end_point={end_point}
          >
            View All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="14"
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        ) : page === 'provider' ?
          <div
            className="view view-all"
            id={`view-all-title`}
            home-selectable="true"
            title='/provider-grid'
            widgetname={title}
            template={template_name}
            section={section}
            end_point={end_point}
          >
            View All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="14"
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div> : null}
      </div>
      <style jsx="true">
        {`
          .NewHeading h2 {
            font-size: 14px;
            letter-spacing: 0;
            color: #ffffff;
            margin: 0px;
            font-weight: 700;
            flex: 0 0 71%;
          }
          .NewHeading {
            display: flex;
            justify-content: space-between;
            padding: 3px 8px;
          }
          .view {
            font-size: 11px;
            letter-spacing: 0;
            color: #ffffff;
            margin: 0px;
            height: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            flex: 0 0 26%;
          }
          .view:focus {
            background: red;
          }
        `}
      </style>
    </>
  );
}
