import React, { useEffect } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";

const TermsConditions = () => {

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])


    return (
        <>
            {/* <Header /> */}
            <div className="termsWrap">
                <h2 className="termsHeading">Terms Of Use</h2>
                <p className="termsPara">
                    This Terms and Conditions (this “Agreement or Terms”) shall governs your use of
                    products including services from Hindustan Media Ventures Limited, OTTplay
                    platform (“HMVL”, “or “we” or “us”) as offered from time to time, unless other terms
                    and conditions expressly govern. The User(s) of the OTTplay App / website (&quot;User&quot;/
                    “You”) must carefully read and agree to the following Terms including any future
                    amendments before using the App / website. The expressions “You” “Your” or
                    “User(s)” refers to any person who accesses or uses the website for any purpose
                    (commercial or non-commercial).
                </p>
                <p className="termsPara">
                    These Terms govern User’s access to and use of the website, mobile application or
                    other platform where these Terms are posted (“Site”). By using or connecting to the
                    Site through a third party or by accessing, browsing, or using the Site in any manner,
                    User(s) agree to be bound to these Terms and our Privacy Policy, whether or not
                    User registered/member with us. We/HMVL/ reserves the right to deny access to any
                    person who violates these Terms. If You do not agree to or do not wish to be bound
                    by the Agreement, You may not access or otherwise use the website in any manner.
                </p>
                <p className="termsPara">
                    We will do our utmost to ensure that availability of the Site is uninterrupted and that
                    transmission is error-free. However, due to the nature of the Internet, this cannot be
                    guaranteed. Also, User(s) access to the Site/App may also be occasionally
                    suspended or restricted to allow for repairs, maintenance, or services at any time
                    without prior notice. We will attempt to limit the frequency and duration of any such
                    suspension or restriction.
                </p>
                <h2 className="termsHeading">Eligibility</h2>
                <p className="termsPara">
                    User(s) represent and warrant that they have the right to avail or use the Product
                    provided by HMVL, including but limited to the website or any other services which
                    may be provided by HMVL in relation to the use of the Site/App (&quot;Product &quot;). HMVL
                    Product can only be availed by those individuals, which are authorised under
                    applicable law to form legally binding agreements. User(s) may use this Web
                    Site/App and/or Products herein solely for personal purposes. Any commercial gain
                    from the use of the Site/App and/or any the Product herein is prohibited and
                    punishable under applicable law.
                </p>
                <p className="termsPara">
                    The User(s) shall do its own due diligence before entering into any transaction or
                    purchase of any Product on the website/App. HMVL Product shall not available to
                    User(s) whose accounts have been temporarily or indefinitely suspended by HMVL.
                </p>
                <p className="termsPara">
                    To become a registered User(s) of the Web Site/App a proper procedure has been
                    made available on the Web Site/App which is for the convenience of User(s) so that
                    they can easily use the website/App.
                </p>
                <p className="termsPara">
                    User(s) can become registered User(s) by filling an on-line registration form on the
                    Web Site/App by providing the required information (including name, contact
                    information, etc.). HMVL will establish an account (&quot;Account&quot;) for the User(s) upon
                    successful registration and will assign a user alias (&quot;User ID&quot;) and password
                    (&quot;Password&quot;) for log-in access to the User(s)’s Account.
                </p>
                <p className="termsPara">
                    User(s) registering on the Web Site on behalf of another User represent and warrant
                    that: (a) they have the requisite authority to bind such User under this Agreement all
                    information provided to HMVL during the registration process is true, accurate,
                    current and complete.
                </p>
                <p className="termsPara">
                    User(s) agree that by registering on the Site or website/App, they consent to the
                    inclusion of their personal data in HMVL on-line database and authorize HMVL to
                    share such information with other User(s). HMVL may refuse registration and deny
                    participation and associated User ID and Password to any User(s) for whatever

                    reason. HMVL may suspend or terminate a registered membership at any time
                    without any prior notification in interest of HMVL or general interest of its User(s)
                    without assigning any reason thereof and there shall arise no further liability on
                    HMVL of whatsoever nature due to the suspension or termination of the User
                    account.
                </p>
                <h2 className="termsHeading">Product</h2>
                <p className="termsPara">
                    The term “Product&quot; shall mean and refer to the HMVL Product, for the Users to
                    enroll.
                </p>
                <p className="termsPara">
                    The User is required to enroll and participate in the Product for the use of Product.
                    We may change, suspend or discontinue any aspect of Product at any time. We may
                    also impose or limit any feature or restrict access to parts or all of the Product
                    without notice or liability. We reserve the right to modify the Product at any time,
                    without any prior approval or consent from the User(s).
                </p>
                <h2 className="termsHeading">Content</h2>
                <p className="termsPara">
                    All materials or Products published or available (including, but not limited to text,
                    photographs, graphics, images, illustrations, designs, audio clips, video clips, “look
                    and feel,” metadata, data, or compilations, arrangement &amp; assembly, all also known
                    as the &quot;Content&quot;) are protected by copyright, property and owned or controlled by us
                    or the party credited as the provider of the Content. User shall abide by all additional
                    copyright notices, information, or restrictions contained in any Content accessed
                    through the Products. We grant User a limited, non-exclusive, non-transferable right
                    to access the Product and Content. Except for the foregoing, no right, title or interest
                    shall be transferred to User.
                </p>
                <p className="termsPara">
                    User must not copy, sell, reproduce, republish, upload, post, modify or modify the
                    said Content, transmit or distribute such Content in any way, including by e-mail or
                    other electronic means and whether directly or indirectly and User must not assist
                    any other person to do so. Without the prior written consent of the owner,
                    modification of the Content and or use of the Content on any other website or
                    networked computer environment or use of the Content for any purpose other than
                    personal, non-commercial use is a violation of the copyrights, trademarks and other
                    proprietary rights, and is prohibited. Any use for which User receive any
                    remuneration, whether in money or otherwise, is a commercial use for the purposes
                    of this clause. The use of the Content on any other website or in a networked
                    computer environment for any purpose is prohibited. User shall not copy or adapt our
                    code that is created to generate any Content or the pages making up any of its
                    website which is also protected by our copyright.
                </p>
                <p className="termsPara">
                    Further, User(s) may also avail the Subscription of variety of OTT Content under the
                    “Premium” tab which offers subscription to the content offered by various OTT
                    content providers (“Service Providers”) on As Is basis. In order to avail this feature
                    User(s) will have to accept the terms and conditions of the Subscriber Agreement
                    available at _____________________. The Content belongs to the respective
                    Service Providers, they are the owners/licensees of the content and HMVL shall not
                    in any manner be responsible for the said content. Any grievances as regard the
                    content to be raised by he User directly to the respective Service Providers through
                    their customer care centres / grievance officers.
                </p>
                <h2 className="termsHeading">Privacy</h2>
                <p className="termsPara">
                    By using the HMVL Product, User hereby agrees and indicates that he or she has
                    understand the disclosure practices described in the Privacy Policy. Personal
                    information supplied by User(s) during the use of the Web Site is governed by HMVL
                    Privacy Policy. Notwithstanding User’s registration with National Do Not Call Registry

                    (In Fully or Partly blocked category under National Customer Preference Register set
                    up under Telecom Regulatory Authority of India), User hereby expresses his or her
                    interest and accord its willful consent to receive communication (including
                    commercial communication) in relation to Products provided by us. User further
                    confirms that any communication, as mentioned hereinabove, shall not be construed
                    as Unsolicited Commercial Communication under the TRAI guidelines and User has
                    specifically opted to receive communication in this regard on the telephone or mobile
                    number provided by him or her.
                </p>
                <h2 className="termsHeading">General Terms</h2>
                <h2 className="termsHeading">Rights and Duties of the User(s)</h2>
                <ul className="termsUL">
                    <li className="termsLI">
                        User shall be solely responsible for maintaining the confidentiality of their
                        account, user name and passwords and for restricting access to its computer
                        to prevent unauthorised access to the account. User shall be solely
                        responsible for all activities happening under its Username whether
                        authorized or not. User agrees to immediately notify us in case of any
                        unauthorized use of its Account and Password or User has any reason to
                        believe that the password has become known to anyone else.
                    </li>
                    <li className="termsLI">
                        User shall be responsible for use of the Site, and for any consequences
                        arising thereof. The User agrees to use the Site in accordance with all laws,
                        rules and regulations, as applicable from time to time, including without
                        limitation IT laws and laws relating to unfair competition, antidiscrimination,
                        false advertising and defamation. The User further agrees that the usage of
                        Site by the User shall not violate any third party rights including but not limited
                        to Intellectual Property Rights, Right of Privacy and Right of Publicity. In
                        addition to above, the User will not use the Site in order to transmit, distribute,
                        store or destroy material, including without limitation Content, that is
                        defamatory, obscene, threatening, abusive or hateful. Any violation of any of
                        the foregoing, including the terms and conditions mentioned herein shall result
                        in immediate termination of this Terms and Conditions. Moreover, we reserve
                        the right to seek damages from any such User to the extent permitted by law.
                    </li>
                    <li className="termsLI">
                        The User shall not cause any minor, i.e., below the age of 18 years, to view or
                        access any content which is rated A, i.e., intended for mature audiences (18+)
                        only.
                    </li>
                    <li className="termsLI">
                        User is also prohibited from violating or attempting to violate the security of
                        Site, including, without limitation the following activities
                        <ul className="termsInnerUL">
                            <li className="termsInnerLI">
                                Accessing data not intended for such User or logging into a server or
                                account which the User is not authorized to access.
                            </li>
                            <li className="termsInnerLI">
                                Attempting to probe, scan or test the vulnerability of a system or
                                network or to breach security or authentication measures without
                                proper authorization.
                            </li>
                            <li className="termsInnerLI">
                                Attempting to interfere with access of Product to any User, host or
                                network, including, without limitation, via means of submitting a virus to
                                web-Site, overloading, &quot;flooding&quot;, &quot;spamming&quot;, &quot;mailbombing&quot; or
                                &quot;crashing&quot;
                            </li>
                            <li className="termsInnerLI">
                                Violations of system or network security may result in civil or criminal
                                liability. We will investigate occurrences which may involve such
                                violations and may involve, and cooperate with, law enforcement
                                authorities in prosecuting the User who are involved in such violations.
                            </li>
                        </ul>
                    </li>
                    <li className="termsLI">
                        User(s), if such functionality is available, may post reviews, comments, and other content; and submit suggestions, ideas, comments, questions, or other information, so long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable, is not created by generative AI, and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam." You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of your content. We reserve the right (but not the obligation) to remove or edit such content; but do not regularly review posted content. We reserve the right without prior notice to access, read preserve and/or disclose the personal information of the User if the same is required
                        <ul className="termsInnerUL">
                            <li className="termsInnerLI">
                                In order to comply with any court judgment/decree/order/directive.
                            </li>
                            <li className="termsInnerLI">
                                By any legal/government authority.
                            </li>
                            <li className="termsInnerLI">
                                By applicable law.
                            </li>
                            <li className="termsInnerLI">
                                By professional/legal advisers, directors,
                                employees/agents/representatives of HMVL in connection with this
                                Agreement or otherwise.
                            </li>
                            <li className="termsInnerLI">
                                For investigating potential violations of the terms herein or applicable
                                national &amp; international laws.
                            </li>
                            <li className="termsInnerLI">
                                For investigating deliberate damage to the Products or its legitimate
                                operation.
                            </li>
                            <li className="termsInnerLI">
                                For responding to support requests or
                            </li>
                            <li className="termsInnerLI">
                                For protecting the rights, property or safety of HMVL and/or its
                                Directors, employees and the general public at large.
                            </li>
                        </ul>
                    </li>
                    <li className="termsLI">
                        We reserves the right to store and process the personal information provided
                        by the User in India or any other country where HMVL or its
                        agents/representatives/group companies maintain storage and processing

                        facilities and the User by using the Product herein consents to any such
                        transfer of information outside India without prior notice.

                    </li>
                    <li className="termsLI">
                        We reserve the right to create limits/restrictions on use of the Product at its
                        sole discretion without assigning any reason whatsoever and without giving
                        any prior notices.
                    </li>
                    <li className="termsLI">
                        We do not warrant that web-Site will operate without any technical error and is
                        free from any computer viruses or other harmful mechanisms. We shall not be
                        liable in any manner whatsoever for any consequences, claims, damages,
                        costs etc. arising out of any of the above-said reasons or due to any force
                        majeure factors and/or due to any reasons beyond the control of HMVL. We
                        to the fullest extent permitted by law, disclaims all warranties, whether
                        express or implied, including the warranty of merchantability, fitness for
                        particular purpose and non-infringement. We makes no warranties about the
                        accuracy, reliability, completeness, or timeliness of the content, services,
                        software, text, graphics, and links used on the Site.
                    </li>
                    <li className="termsLI">
                        HMVL’s maximum liability arising out of or in connection with the Site,
                        regardless of the cause of action (whether in contract, tort, breach of warranty
                        or otherwise), will not exceed the amount paid towards the enrolment by the
                        User.
                    </li>
                    <li className="termsLI">
                        We shall not be responsible or liable in any manner whatsoever for the
                        exercise or non-exercise of its rights herein.
                    </li>
                    <li className="termsLI">
                        All HMVL trade names, trademarks, logos, domain names including without
                        limitation, Hindustan, HMVL, etc. and all content on OTTplay, are the
                        exclusive property of HMVL and the User shall not use the same without the
                        prior written permission of HMVL.
                    </li>
                    <li className="termsLI">
                        We reserves the right in its sole discretion to investigate and take legal action
                        against anyone who engages in any illegal or prohibited conduct or otherwise
                        violates these Terms of Use, including without limitation, removing the User
                        Content from the Site and/or terminating the offending User&#39;s ability to access
                        the Site and/or use HMVL Product. We may take any other action with respect
                        to User Content or User actions that it deems necessary or appropriate in its
                        sole discretion if it believes it may create liability for us or may cause us to
                        lose (in whole or in part) the services of its ISPs or other suppliers.
                    </li>
                    <li className="termsLI">
                        We reserves the right, in its sole discretion, to amend or revise these terms &amp;
                        conditions at any point of time, without prior notice and the User agrees to be
                        bound by such amendments or revisions. It is therefore suggested that the
                        User must periodically review the updated version of these terms &amp;
                        conditions.
                    </li>
                    <li className="termsLI">
                        User shall ensure that the details provided to us are correct and complete.
                        User should inform us immediately of any changes to the information that was
                        provided when registering on Site. User can access and update much of the
                        information which was provided to us. Users agree and acknowledge that
                        User will use its account on the Site to purchase products only for its personal
                        use and not for business purposes. We reserve the right to refuse access to
                        the Site, terminate accounts, remove or edit content at any time without notice
                        to User.
                    </li>

                </ul>
                <h2 className="termsHeading">Disclaimers of Warranties and Limitation of Liability</h2>
                <p className="termsPara">
                    USER HEREBY AGREE THAT YOUR ACCESS TO, AND USE OF, THE
                    SERVICES, PRODUCTS, AVAILABLE IS ON AN &quot;AS-IS&quot;, &quot;AS AVAILABLE&quot; BASIS

                    AND HT SPECIFICALLY DISCLAIM ANY REPRESENTATIONS OR WARRANTIES,
                    EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY
                    REPRESENTATIONS OR WARRANTIES OF MERCHANTABILITY OR FITNESS
                    FOR A PARTICULAR PURPOSE.
                </p>
                <p className="termsPara">
                    Without prejudice to the forgoing paragraph, we do not warrant that:
                </p>
                <p className="termsPara">
                    This Site will be constantly available or available at all; or the information on this Site
                    is complete, true, accurate or non-misleading.
                </p>
                <p className="termsPara">
                    We will not be liable to User in any way or in relation to the Contents of, or use of, or
                    otherwise in connection with, the Site. We does not warrant that this Site;
                    information, Content, materials, Product (including software) or Services included on
                    or otherwise made available to User through the Site; their servers; or electronic
                    communication sent from us are free of viruses or other harmful components. All the
                    Contents of this Site are only for general information or use. They do not constitute
                    advice and should not be relied upon in making (or refraining from making) any
                    decision.
                </p>
                <p className="termsPara">
                    The information from or through this Site is provided on &quot;AS IS&quot; basis, and all
                    warranties, expressed or implied of any kind, regarding any matter pertaining to any
                    goods, Service or channel, including without limitation, the implied warranties of
                    merchantability, fitness for a particular purpose, and non-infringement are disclaimed
                    and excluded. Certain links on the Site lead to resources located on servers
                    maintained by third parties over whom we have no control or connection, business or
                    otherwise as these Sites are external to us. User hereby agrees and understands
                    that by visiting such Sites User is beyond the HT’s website. We therefore neither
                    endorses nor offers any judgment or warranty and accepts no responsibility or
                    liability for the authenticity/availability of any of the goods/Services/or for any
                    damage, loss or harm, direct or consequential or any violation of local or
                    international laws that may be incurred by your visit and/or transaction/s on these
                    Sites.
                </p>
                <p className="termsPara">
                    HINDUSTAN MEDIA VENTURES LIMITED AND ITS AFFILIATES AND THEIR
                    RESPECTIVE, DIRECTORS, OFFICERS, EMPLOYEES, CONTENT PROVIDERS,
                    AGENTS (&quot;THE HT PARTIES&quot;) WILL NOT BE LIABLE (JOINTLY OR SEVERALLY)
                    TO USER OR ANY OTHER PERSON AS A RESULT OF ACCESS OR USE OF
                    THE PRODUCTS OR SERVICES OR CONTENT, FOR INDIRECT,
                    CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY
                    DAMAGES, INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOST
                    SAVINGS AND LOST REVENUES (COLLECTIVELY, THE &quot;EXCLUDED
                    DAMAGES&quot;), WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT,
                    CONTRACT, OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE HT
                    PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE
                    FORESEEN ANY OF THE EXCLUDED DAMAGES, AND IRRESPECTIVE OF ANY
                    FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY.
                </p>
                <p className="termsPara">
                    <b>Indemnity:</b>User shall indemnify and hold harmless HMVL its owner,
                    affiliates, subsidiaries, group companies (as applicable) and their respective
                    officers, directors, agents, and employees, from any claim or demand, or actions
                    including reasonable attorneys fees, made by any third party or penalty imposed
                    due to or arising out of User breach of this Terms of Use, Privacy Policy and
                    other Policies, or violation of any law, rules or regulations or the rights
                    (including infringement of intellectual property rights) of a third party.
                </p>
                <h2 className="termsHeading">Communications</h2>
                <p className="termsPara">
                    When User visits the Site, User is communicating with us electronically. User will be
                    required to provide a valid phone number or e-mail while placing an order with us.
                    We may communicate with User by e-mail, SMS, phone call or by any other mode of
                    communication. For contractual purposes, User consent to receive communications
                    (including transactional, promotional and/or commercial messages), from us with
                    respect to User use of the Site and/or User order placed on the Site.
                </p>
                <p className="termsPara">
                    <b>Age:</b> The services of OTT Play is only available for persons who have attained the age of majority, i.e., the age of 18 and is expressly restricted to persons below the age of 18, i.e., minors. By using and/ or interacting with OTT Play, you represent and warrant that you are 18 years of age or older.  In case of access or usage of Site/ App by a minor i.e. under the age of 18 years, such access or usage shall be under strict supervision of a parent or guardian.
                </p>
                <p className="termsPara">
                    <b>Content Classification:</b>
                    The content available on OTT Play is classified based on
                    its nature and type and the age group to which such content is restricted. Under
                    applicable laws, such classification is provided by the publisher of such content and
                    has no involvement of OTT Play. we disclaims any and all liability with respect to
                    such classification.
                </p>
                <p className="termsPara">
                    <b>Content Rated A for Mature Audience (18+) Only:</b>
                    Some content available on OTT
                    Play may not be appropriate for persons below the age of 18 (minors) and parents or
                    guardians are advised to exercise discretion and strict supervision. It shall be the
                    obligation of such parent/ guardian to ensure that minors do not have access to
                    content on OTT Play which is meant for mature audience only.
                </p>
                <p className="termsPara">
                    <b>Content Review and Rating:</b>
                    With a view to enhance User experience, we may
                    review the content available on OTT Play and rate it according to such review.
                    However, this rating and review system must not be confused with content
                    classification under applicable laws. We disclaim any and all liability with respect to
                    such reviews and rating.
                </p>
                <p className="termsPara">
                    <b>Applicable Law:</b>
                    Terms of Use and all the disputes arising between the User and
                    HMVL shall be governed by and interpreted and construed in accordance with the
                    laws of India. The place of jurisdiction shall be exclusively in New Delhi.
                </p>
                <h2 className="termsHeading">MISCELLANEOUS</h2>
                <ul className="termsUL">
                    <li className="termsLI">
                        Headings for any section of the Agreement are for reference purposes only
                        and in no way define, limit, construe or describe the scope or extent of such
                        section.
                    </li>
                    <li className="termsLI">
                        HMVL’s failure to enforce any right or failure to act with respect to any breach
                        by a User(s) under the Agreement and/or Privacy Policy will not be deemed to
                        be a waiver of that right or HMVL waiver of the right to act with respect with
                        subsequent or similar breaches.
                    </li>
                    <li className="termsLI">
                        HMVL shall have the right to assign its obligations and duties in this
                        Agreement and in any other agreement relating HMVL Product to any person
                        or entity.
                    </li>
                    <li className="termsLI">
                        If any provision of this Agreement is held to be invalid or unenforceable, such
                        provision shall be struck out and the remaining provisions of the Agreement
                        shall be enforced.
                    </li>
                    <li className="termsLI">
                        Any complaints or concerns with regards to Product or any breach of this
                        Agreement or Privacy Policy can addressed to support@ottplay.com.
                    </li>
                    <li className="termsLI">
                        The Agreement and the privacy policy constitute the entire agreement
                        between the User(s) and HMVL with respect to access to and use of the Web
                        Site, superseding any prior written or oral agreements in relation to the same
                        subject matter herein.
                    </li>
                </ul>
                <h2 className="termsHeading">Contact Us</h2>
        <p className="termsPara">
        All questions, comments and requests regarding this policy should be addressed to:<br/>
        Mailing Address: Hindustan Media Ventures Limited, Attn: Legal Department, Hindustan Times House, 18-20, Second Floor, Kasturba Gandhi Marg, New Delhi – 110 001, India<br/>
        Grievance Officer : Saurabh Shukla<br/>
        Contact Number : 080-62012555<br/>
        Email Address: support@ottplay.com
        </p>
            </div>

            {/* <Footer /> */}

            <style jsx="true">{`
        .termsWrap{
            padding: 45px 10px 30px;
        }
        .termsHeading{
            font-size:15px;
            line-height: 20px;
            color:#ffffff;
            font-weight:400;
            margin:0;
            padding:5px 0 0;
        }
        .termsPara{
            font-size:13px;
            line-height: 16px;
            color:#ffffff;
            font-weight:400;
            margin:0;
            padding:6px 0;
            opacity:0.7;
            text-align: justify;
        }
        .termsUL{
            padding:0;
            margin:0;
            padding-left:15px;
            list-style-type:disc;
        }
        .termsLI{
            font-size:13px;
            line-height: 16px;
            color:#ffffff;
            font-weight:400;
            margin:0;
            padding:6px 0;
            opacity:0.7;
            text-align: justify;
        }
        .termsInnerUL{
            padding:0;
            margin:0;
            padding-left:15px;
            list-style-type:circle;
        }
        .termsInnerLI{
            font-size:13px;
            line-height: 16px;
            color:#ffffff;
            font-weight:400;
            margin:0;
            padding:6px 0;
            opacity:0.7;
            text-align: justify;
        }
        
      `}</style>
        </>
    );
};

export default TermsConditions;
