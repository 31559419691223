import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import { navigate } from "../navigate";
import { apiUrl } from "../site.config";
import Loader from "../Components/loader";
import { logEvent } from "../utils/helper";

export default function PlansList() {

  const renderedtopten = useRef(false)
  const cur = useRef(0);
  const [planList, setPlanList] = useState([]);
  const [nodata, setNodata] = useState('')
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (() => {
      setLoading(true)
      getPlanListdata();
      // setTimeout(() => {
      //   PlanListEvents();
      //   // setLoading(false)
      // }, 1500);
    })();
    return () => { document.removeEventListener("keydown", handlekey) }
  }, []);
  var planlist;
  function PlanListEvents() {
    renderedtopten.current = false
    planlist = document.querySelectorAll("[nav-selectable='true']");
    if (planlist.length) {
      window.scrollTo({ top: 0 })
      document.getElementById(`plan-checkbox-${cur.current}`).checked = true;
      planlist[cur.current].style.outline = "1px solid  #29f87e";
      planlist[cur.current].style["outline-offset"] = '-2px'
      document.addEventListener("keydown", handlekey)
    }
  }

  const handlekey = (e) => {

    if (e.key === "ArrowUp") {
      if (cur.current == 0) return

      planlist[cur.current].style.removeProperty("outline");
      document.getElementById(`plan-checkbox-${cur.current}`).checked = false;
      planlist[cur.current - 1].style.outline = "1px solid  #29f87e";
      planlist[cur.current - 1].style["outline-offset"] = "-2px";
      document.getElementById(`plan-checkbox-${cur.current - 1}`).checked = true;
      if (cur.current != 0) cur.current = cur.current - 1
      let ele = document.getElementById(`plan-checkbox-${cur.current - 1}`)
      let container = document.querySelector('.planListWrap')
      scrollAt(container, ele, cur.current);

    }

    if (e.key == "ArrowDown") {
      if (cur.current == planlist.length - 1) return;
      planlist[cur.current].style.removeProperty("outline");
      document.getElementById(`plan-checkbox-${cur.current}`).checked = false;
      if (cur.current != planlist.length - 1) cur.current = cur.current + 1;
      planlist[cur.current].style.outline = "1px solid  #29f87e";
      planlist[cur.current].style['outline-offset'] = "-2px";
      document.getElementById(`plan-checkbox-${cur.current}`).checked = true;
      let ele = document.getElementById(`plan-checkbox-${cur.current}`)
      let container = document.querySelector('.planListWrap')
      scrollAt(container, ele, cur.current, planlist.length - 1);
      //  const element = document.getElementById(`plan-checkbox-${cur.current}`)
      //  element.scrollTo(0, 0);
    }
    if (e.key == "Enter") {
      handlePlan(`plan-list-${cur?.current}`,e);
    }
  }

  const handlePlan = (id,e={}) => {
    logEvent('subscription_plan_selected_monthly', 'subscription_plan_selected', 'subscription_plan_selected', 'subscription_plan_selected')
    let state = document.getElementById(id).getAttribute('planData')
    navigate(e,`/planDetails?state=${state}`)
  }
  const getPlanListdata = () => {
    // let response = await fetchData("v4.5/mobile/subscription-plans","");
    // fetch(`https://stg-jioapi.ottplay.com/api/v4.5/mobile/subscription-plans`, {
    fetch(`${apiUrl}/v4.5/mobile/subscription-plans`, {
      headers: {
        'deviceType': 'JIO_Phone',
        'platform': 'JIOPHONE',
        'bypass':true,
        'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8"
      }
    }).then(function (response) {
      // The API call was successful!
      return response.json();
    }).then(function (response) {
      setTimeout(() => {
        PlanListEvents();
      }, 1000);
      // This is the JSON from our response
      console.log("planlist", response);
      if (
        Array.isArray(response?.subscriptionplans) &&
        response?.subscriptionplans?.length
      ) {
        setLoading(false);
        setPlanList(response?.subscriptionplans);
      } else {
        setNodata('No Plan Found')
        setLoading(false);
        setPlanList([]);
      }
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });
    // if (
    //   Array.isArray(response?.subscriptionplans) &&
    //   response?.subscriptionplans?.length
    // ) {
    //   setLoading(false);
    //   setPlanList(response?.subscriptionplans);
    // } else {
    //   setLoading(false);
    //   setPlanList([]);
    // }
  };
  var scrollAt = function (container, target, m, planList) {
    if (container.contains(target)) {
      if (m == null) {
        container.scrollTop = 200;
      }
      else {
        container.scrollTop = m === planList ? 135 * 2 * m : (140 * m);
      }
    } else {
      container.scrollTop = 0;
    }
  }
  return (
    <>
      {/* <Header /> */}
      <ul className="planListWrap" id='planListWrap'>
        {planList?.length
          ? planList.map((plan, id) => (
            <>
              <li className="plansBox" id={`plan-list-${id}`} planData={JSON.stringify(plan)} nav-selectable="true">
                <div className="planTitleWrap">
                  <h2 className="planTitle">{plan?.name || ""}</h2>
                  <input type="checkbox" id={`plan-checkbox-${id}`} />
                  <label htmlFor="1">
                  </label>
                </div>
                <p className="planDesc">{plan?.description}</p>
                <div className="planPartners">
                  {Array.isArray(plan.providers) && plan?.providers?.length
                    ? plan?.providers?.map((provider) => (
                      <img src={provider?.logo_url} alt="" />
                    ))
                    : null}
                </div>
                <div className="planFooter">
                  <div className="planAmountCut">
                    <span>₹</span>{plan?.plan_discount?.actual_price}
                  </div>
                  <div className="planActualAmount">
                    ₹{plan?.plan_discount?.price_after_discount} <span>per month</span>
                  </div>
                </div>
                <div className="marketPrice">Market Price ₹{plan?.plan_discount?.actual_price} per month</div>
                <div className="savedAmount">Save upto ₹{plan?.plan_discount?.actual_price - plan?.plan_discount?.price_after_discount}</div>
              </li>
              {/* <div></div> */}
            </>
          ))
          : <div className="noPlanFound">{nodata}</div>}

      </ul>
      {loading ? <div className="loaderPlan"><Loader /></div> : null}
      {/* <Footer /> */}
      <style jsx>
        {`
          .planListWrap {
            position: absolute;
            top: 43px;
            padding: 0px 7px 30px 7px ;
            margin: 0;
            height: calc(100vh - 40px);
            list-style-type: none;
            overflow:auto;
          }
          .marketPrice{
            font-size:10px;
            line-height:14px;
            color:#9989B5;
            font-weight:300;
            padding-top:5px;
          }
          .savedAmount{
            font-size:10px;
            line-height:14px;
            color:#29F87E;
            font-weight:700;
            padding-top:3px;
            }
          .plansBox {
            padding: 7px 9px;
            background-color: #39135b;
            border-radius: 3px;
            margin-bottom: 11px;
 
          }
          .plansBox:last-child{
            margin-bottom:30px;
          }
          .planTitle {
            font-size: 12px;
            font-weight: 700;
            color: #ffffff;
            margin: 0;
            padding-bottom: 3px;
            line-height: 17px;
          }
          .planDesc {
            margin: 0;
            padding-bottom: 10px;
            color: #9989b5;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
          }
          .planPartners {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 10px;
          }
          .planPartners img {
            width: 49px;
            border-radius: 2px;
            margin-right: 2px;
            margin-bottom: 4px;
          }
          .planFooter {
            border-top: 1px solid;
            border-color: rgba(236, 229, 250, 0.3);
            padding-top: 8px;
            display: flex;
            
          }
          .planAmountCut {
            font-size: 15px;
            font-weight: 500;
            color: #9989b5;
            line-height: 11px;
            padding-left: 6px;
            position: relative;
          }
          .planAmountCut > span {
            position: absolute;
            top: -1px;
            left: 0;
            font-size: 10px;
          }
          .planAmountCut::after {
            content: "";
            border-bottom: 2px solid #f81351;
            width: 100%;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-77%) rotate(-10deg);
          }
          .planActualAmount {
            font-size: 15px;
            font-weight: 700;
            color: #ffffff;
            line-height: 13px;
            display: flex;
            margin-left: 10px;
          }
          .planActualAmount > span {
            font-size: 9px;
            display: inline-block;
            padding-left: 4px;
            vertical-align: text-top;
          }
          .plansBox .planTitleWrap{
            display:flex;
            justify-content:space-between;
            align-items:flex-start;
          }
          .plansBox .planTitleWrap input{padding: 0;height: initial;width: initial;margin-bottom: 0;display: none;cursor: pointer;}
          .plansBox .planTitleWrap label {position: relative;cursor: pointer;display: flex;align-items: center;margin-top: 2px;}
          .plansBox .planTitleWrap label:before{content: '';box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);padding: 6px;position: absolute;cursor: pointer;right: 0;background: #270048;border-radius: 2px;top: 0;}
          .plansBox .planTitleWrap input:checked + label:after {content: '';display: block;position: absolute;top: 1px;right: 4px;width: 3px;height: 7px;border: solid #270048;border-width: 0 1px 1px 0;transform: rotate(45deg);}
          .plansBox .planTitleWrap label .langIcon{width: 22px;height: 22px;background: #270048;margin-right: 7px;border-radius: 50%;display: flex;justify-content: center;align-items: center;font-size: 12px;}
          .plansBox .planTitleWrap label .langText{font-size: 12px;line-height: 17px;}
          .plansBox .planTitleWrap input:checked + label .langIcon {background:#29f87e;color:#270048;font-weight:600;}
          .plansBox .planTitleWrap input:checked + label:before{background:#29f87e}
          .noPlanFound{
            width:100%;
            display:block;
            color:#ffffff;
            font-size:14px;
            font-weight:700;
            line-height:20px;
            padding:30px 10px;
          }
        `}
      </style>
    </>
  );
}
