import React, { useEffect, useRef, memo, useContext } from 'react'
import MobileDataAlert from './mobileDataAlert'
import { popupModal } from '../utils/helper'
import { navigate, navigateBack } from '../navigate'
import { UserContext } from '../Contexts/userContext'
import { getCookies } from '../utils/cookieHelper'
const Footer = (props) => {
    let { footerValue = 'Search', menuValue = 'Menu', navigateTo = '/search', data = {} } = props
    const rendered = useRef(null)
    let {logged_in} =  getCookies(['logged_in']);

    const User = useContext(UserContext);

    let footerRightNavigation = {
        Search: '/search',
        'Manage Subscription': navigateTo,
        'Cancel Subscription': '/cancel-subscription',
    }

    useEffect(() => {
        window.addEventListener('online', () => console.log('Became online'));
        window.addEventListener('offline', () => popupModal("beacame offline", 3500));
        setTimeout(() => {
            rendered.current = true;
            rendered.current && footerEvents();
        }, 1000);
        return () => { rendered.current = false; document.removeEventListener('keydown', handlekeydown) }

    }, [])

    const footerEvents = () => {

        document.addEventListener('keydown', handlekeydown)
    }
    const handlekeydown = (e) => {
        let btnName = document.getElementById('search');
        let btnName2 = document.getElementById('menu');

        switch (e.key) {
            case "SoftRight":
                if (btnName.getAttribute('title') === 'Cancel Subscription') {
                    props && props.cancelSubscription()
                }
                else if (btnName.getAttribute('to')?.length) {
                    navigate(e,btnName.getAttribute('to'))
                }
                break;
            case "Escape":
                if (btnName.getAttribute('title') === 'Cancel Subscription') {
                    props && props.cancelSubscription()
                }
                else if (btnName.getAttribute('to')?.length) {
                    navigate(e,btnName.getAttribute('to'))
                }
                break;
            case "SoftLeft":
                if (btnName2.getAttribute('title') === 'Back') {
                    navigateBack();
                } else {
                    navigate(e,'/menu')
                }
                break;
            case "Shift":
                if (btnName2.getAttribute('title') === 'Back') {
                    navigateBack();
                } else {
                    navigate(e,'/menu')
                }
                break;
            case "Backspace":
                if (document.querySelector('.seasonDropdownWrap')) { e.preventDefault(); return }
                const remainingRoutes = JSON.parse(localStorage.getItem("visitedRoutes"));
                if (remainingRoutes && remainingRoutes.length === 1) {
                    localStorage.removeItem("visitedRoutes");
                    return;
                }
                if (!remainingRoutes) {
                    return;
                }
                e.preventDefault();
                navigateBack();
                break;
            case "1":
                if (window.location.pathname != '/search' && window.location.pathname != '/login' && window.location.pathname != '/otp'  && window.location.pathname != '/') {
                    navigate(e,'/')
                }
                break;
            case "2":
                if (window.location.pathname != '/search' && window.location.pathname != '/login' && window.location.pathname != '/otp' && window.location.pathname != '/livetv-home') {
                    navigate(e,'/livetv-home')
                }
                break;
            case "3":
                if (window.location.pathname != '/search' && window.location.pathname != '/login' && window.location.pathname != '/otp' && window.location.pathname != (User?.user?.planName !== 'Subscribe'? '/partner-list':  '/partners')) {
                    let path = User?.user?.planName !== 'Subscribe'? '/partner-list':  '/partners' 
                    navigate(e,path)
                }
                break;
            case "4":
                if (window.location.pathname != '/search' && window.location.pathname != '/login' && window.location.pathname != '/otp'  && window.location.pathname != '/all-languages') {
                    navigate(e,'/all-languages')
                }
                break;
            case "0":
                if (window.location.pathname != '/search' && window.location.pathname != '/login' && window.location.pathname != '/otp'  && !logged_in) {
                    navigate(e,'/login')
                }
                break;
            case "5":
                if (window.location.pathname != '/search' && window.location.pathname != '/login' && window.location.pathname != '/otp' && window.location.pathname != '/profile' && logged_in) {
                    navigate(e,'/profile')
                }
                break;
            default:
                break;
        }

    }

    const handleOperation = () => {
        setTimeout(() => {
            navigate({},'/search')
        }, 0);
    }
    return (
        <>
            <MobileDataAlert />
            <div className='footer' id='FooterID'>
                <div className='footercontainer'>
                    <div id='menu' className='menu' title={menuValue} to={`/menu`}><a>{menuValue}</a></div>
                    {/* <div>
                        <img src={SearchIcon} height='30' width="42"/>
                    </div> */}
                    <div id="search" className='search' title={footerValue} to={`${navigateTo}`}><a>{footerValue}</a></div>
                </div>

            </div>
            <style jsx="true">
                {`
                .footer {
                    height: 30px;
                    width: 100%;
                    position: fixed;
                    left: 0;
                    bottom: 0;
                    background: #FFFFFF;
                    z-index:999;

                }
                .noneDisplay {
                    display : none;
                }
                .footercontainer{
                    height: 30px;
                    display: flex;
                    justify-content: space-between;
                }
                .menu {
                    padding: 5px;
                    margin-left: 8px;               
                }
                .menu a{
                    font-family: var(--unnamed-font-family-open-sans);
                    font-style: var(--unnamed-font-style-normal);
                    font-weight: var(--unnamed-font-weight-normal);
                    font-size: var(--unnamed-font-size-24);
                    line-height: var(--unnamed-line-spacing-8);
                    letter-spacing: var(--unnamed-character-spacing-0);
                    color: var(--unnamed-color-ffffff);
                    text-decoration:none;
                }
                .search{
                    padding: 5px;
                    margin-right: 8px;
                }
                .search a{
                    font-family: var(--unnamed-font-family-open-sans);
                    font-style: var(--unnamed-font-style-normal);
                    font-weight: var(--unnamed-font-weight-normal);
                    font-size: var(--unnamed-font-size-24);
                    line-height: var(--unnamed-line-spacing-8);
                    letter-spacing: var(--unnamed-character-spacing-0);
                    color: var(--unnamed-color-ffffff);
                    text-decoration:none;
                }
                `}
            </style>
        </>
    )
}

export default memo(Footer)