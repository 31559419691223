import React, { useEffect, useRef, useState } from "react";
import { subsApi, apiUrl } from "../site.config";
// import { useParams } from "react-router-dom";
import Header from "../Components/header";
import Footer from "../Components/footer";
import DetailList from "../Components/cards/detaillists";
import { fetchData } from "../Api/client";
import { useNavigation } from "../CustomHooks/useNavigation";
import { activeBtnColor, activeElementBorderColor, handlekeyDown } from "../utils/helper";
import { navigate } from "../navigate";
// import { fetchSearchData } from "../Api/Services/user";

export default function AllSearchResult() {
  // const { query = "" } = useParams();
  const tabs = ["All", "Movies", "Shows", "Live", "Sports"];
  let query = window.history.state?.query || "";
  const [tabNo, setTabNo] = useState(0);
  const [searchapiResult, setSearchapiResult] = useState('')
  const activeTabRef = useRef(tabs[tabNo]);
  const tabNoRef = useRef(tabNo);
  const [allResult, setallResult] = useState([]);
  const tabsObj = {
    All: "all",
    Movies: "movie",
    Shows: "show",
    Live: "live TV",
    Sports: "sport",
  };

  function handleTabChange(tabDir) {
    if (tabDir === "right") {
      tabNo <= tabs.length - 1 && setTabNo((prev) => parseInt(prev) + 1);
    } else if (tabDir === "left")
      tabNo >= 0 && setTabNo((prev) => parseInt(prev) - 1);
  }
  activeTabRef.current = tabs[tabNo];
  tabNoRef.current = tabNo;
  const [current, setNavigation] = useNavigation(
    tabNoRef.current,
    tabs.length,
    handleTabChange
  );
  useEffect(() => {
    (() => {
      searchAllResult();
      document.addEventListener("keydown", handlekeyDown);
    })();
    return () => document.removeEventListener("keydown", handlekeyDown);
  }, []);

  const searchAllResult = () => {
    let end_point = `${subsApi}search/jio-titles?type=all&query=${query}&listing=false&limit=30&page=1`;
    let result = fetch(end_point, { headers: {} }).then(function (response) {
      return response.json();
    }).then(function (searchResult) {
      console.log("searchResult", searchResult);
      if (
        searchResult &&
        searchResult?.result?.length &&
        Array.isArray(searchResult.result)
      ) {
        setallResult(searchResult.result);
      } else {
        setSearchapiResult(`No Results found for "${query}"`)
        setallResult(allResult);
      }
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    }) || '';
    // if (
    //   searchResult &&
    //   searchResult?.result?.length &&
    //   Array.isArray(searchResult.result)
    // ) {
    //   setallResult(searchResult.result);
    // } else {
    //   setallResult(allResult);
    // }
  };


  function tabHasResults() {
    let res = allResult?.filter((vid, id) => {
      if (tabs?.[tabNo] === "All") return true;
      return vid.content_type === tabsObj[tabs?.[tabNo] || "all"];
    })
    if (res?.length) return true
    else {
      return false
    }
  }
  return (
    <>
      {/* <Header /> */}
      <div className="searchWrap">
        <ul className="searchTabs">
          {tabs.map((tab, id) => (

            <li
              nav-selectable='true'
              className={`tabsList ${tab === activeTabRef.current ? "activetab" : ""
                }`}
            >
              {tab}
            </li>

          ))}
        </ul>
        <div className="showingResults">
          { }
          {tabHasResults() ? <>Showing results for "<span>{query}</span>"</> : <div className="noResultsSearch">{searchapiResult}</div>}
        </div>
        <ul className="sarchReasult">
          {allResult?.length
            ? allResult
              ?.filter((vid, id) => {
                if (tabs?.[tabNo] === "All") return true;
                return vid.content_type === tabsObj[tabs?.[tabNo] || "all"];
              }).length ? allResult?.filter((vid, id) => {
                if (tabs?.[tabNo] === "All") return true;
                return vid.content_type === tabsObj[tabs?.[tabNo] || "all"];
              }).map((vid, id) => (
                <DetailList

                  key={`searchresult-${id}`}
                  page="allresult"
                  searchQuery={query}
                  name={vid.name}
                  content_type={
                    vid.content_type == "live TV"
                      ? "live_tv"
                      : vid.content_type
                  }
                  language={vid.language}
                  release_year={vid.release_year}
                  ottplay_id={vid.ottplay_id || ""}
                  provider={vid.provider || ""}
                  seo_url={vid.seo_url || ""}
                  _id={vid._id || ''}
                />
              )):<div className="noResultsSearchFilter">{`No Results found for "${query}"`}</div>
              : null}
        </ul>
      </div>
      {/* <Footer /> */}
      <style jsx="true">{`
        .searchWrap * {
          box-sizing: border-box;
        }
        .searchWrap {
          background: #270048;
          width: 100%;
          min-height: 100vh;
          padding: 45px 8px;
          box-sizing: border-box;
        }
        .searchWrap input {
          border: 0;
          border-radius: 3px;
          padding: 8px 9px;
          width: 100%;
          font-size: 10px;
          font-weight: 600;
          margin-bottom: 7px;
        }
        .searchWrap input:focus {
          outline: 0;
        }
        .searchWrap .sarchReasult {
          margin: 0;
          padding: 0;
          list-style: none;
        }
        .searchWrap .sarchReasult a:not(:last-child) {
          margin-bottom: 5px;
        }
        .searchWrap .sarchReasult a {
          color: #fff;
          text-decoration: none;
          background: #3a135b;
          display: block;
          border-radius: 5px;
        }
        .searchWrap .sarchReasult li {
          padding: 9px;
        }
        .searchWrap .sarchReasult h4 {
          font-size: 12px;
          line-height: 17px;
          margin: 0;
          font-weight: 600;
        }
        .searchWrap .sarchReasult .searchInfo {
          font-size: 10px;
          color: #9989b5;
          line-height: 14px;
        }
        .searchWrap .sarchReasult .searchInfo span:not(:first-child) {
          padding-left: 9px;
        }
        .searchWrap .sarchReasult .searchInfo span {
          position: relative;
        }
        .searchWrap .sarchReasult .searchInfo span:not(:first-child):before {
          content: "";
          width: 3px;
          height: 3px;
          background: #9989b5;
          position: absolute;
          border-radius: 50%;
          left: 3px;
          top: 50%;
          transform: translateY(-50%);
        }
        .highlight {
          border: 2px solid white;
        }
        .searchTabs {
          margin: -13px -8px 0;
          margin-bottom: 10px;
          padding: 0;
          list-style-type: none;
          background-color: #551787;
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow-x: auto;
          border-top: 1px solid;
          border-color: rgba(112, 112, 112, 0.3);
        }
        .searchTabs::-webkit-scrollbar {
          display: none;
        }

        .tabsList {
          font-size: 12px;
          font-weight: 600;
          color: #ffffff;
          line-height: 17px;
          padding: 9px 4px;
          position: relative;
        }

        .searchTabs .activetab {
          color: #29f87e;
          font-weight: bold;
        }
        .searchTabs activetab::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
          border-bottom: 1px solid #29f87e;
        }
        .searchTabs li[nav-selected="true"] {
          color: #29f87e;
          font-weight: bold;
        }
        .searchTabs li[nav-selected="true"]::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
          border-bottom: 1px solid ${activeBtnColor};
        }
        .showingResults {
          font-size: 14px;
          font-weight: 700;
          color: #ffffff;
          line-height: 19px;
          padding: 0px 0px 7pxp;
        }
        .sarchReasult li[nav-selected="true"] {
          border: 2px solid ${activeElementBorderColor};
        }
        .noResultsSearch{
          width:100%;
          display:block;
          color:#ffffff;
          font-size:14px;
          font-weight:700;
          line-height:20px;
          padding:15px 10px;
        }
        .noResultsSearchFilter{
          width:100%;
          display:block;
          color:#ffffff;
          font-size:14px;
          font-weight:700;
          line-height:20px;
          padding:15px 10px;
          position: absolute;
          top: 85px;
        }
      `}</style>
    </>
  );
}
