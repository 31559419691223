import React, { useEffect, useState } from 'react'
import Title from './titleHeading'
import Image from './Image'
import { apiUrl } from '../site.config'
import Cookies from 'js-cookie'


const CardContainer = ({ item = {},loading, longWidget, page = "", id = "", uniqueId = '', seo_url = '' }) => {
    let client_id = Cookies.get("clientId") || ''
    let logged_in = Cookies.get("logged_in") || false
    const { title, pin_it, end_point, template_name, section } = item
    let height = longWidget ? '103' : '44'
    const [widgetData, setWidgetData] = useState([])

    useEffect(() => {
        fetchwidgetData(pin_it, end_point, template_name, section);
    }, [])
  
    const fetchwidgetData =  (pin_it, end_point, template_name, section) => {
        end_point = end_point.slice(0, 1) == '/' ? end_point : `/${end_point}`
        fetch(`${apiUrl}${end_point.trim()}?limit=3&pin_it=${pin_it}&template_name=${template_name}&section=${section}&page=1&module_name=${page == 'live' ? 'livetv' : page == 'provider' ? seo_url : 'Home'}`, 
        { headers: { 'client_id':client_id|| '', 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } }).then(function (response) {
            // The API call was successful
            return response.json();
        }).then(function (data) {
            // This is the JSON from our response
            console.log("cardContainer",data);
            if (Array.isArray(data?.data) && data?.data?.length) setWidgetData(data?.data.length ? data.data.slice(0, 3) : [])
            else {
                setWidgetData([])
            }
        }).catch(function (err) {
            // There was an error
            console.warn('Something went wrong.', err);
        });
    }
    return (
        (Array.isArray(widgetData) && widgetData.length) ?
        <>
            <div className='fixedContainer' >
                <Title title={title} template_name={template_name} section={section} end_point={end_point} id={id} page={page} pin_it={pin_it} />
                <div className='cardContainer' id={uniqueId} home-selectable="true">
                    {widgetData && widgetData.map((item, id) => {
                        if (item?.content_type !== 'documentary') {
                            return (
                                <a key={id} _id={item._id || ''} widget-selectable={uniqueId} content-type={item?.content_type || ''} ottplay_id={item?.ottplay_id || ''} name={item?.name} providers={item?.where_to_watch[0]?.provider || ''} seo_url={item?.seo_url || ''} to={item?.content_type == 'movie' || item?.content_type == 'live_tv' || item?.content_type == 'sport' ? `/player?content_type=${item?.content_type}&ottplay_id=${item?.ottplay_id}&provider=${item?.where_to_watch[0]?.provider}&subscriptionType=${item?.where_to_watch[0]?.subscription_type}&_id=${item._id || ''}&name=${item?.name}` : `/show-details?seo_url=${item?.seo_url}&subscriptionType=${item?.where_to_watch[0]?.subscription_type}&name=${item?.name}`} >
                                    <Image src={item?.content_type == "live_tv" ? item?.backdrops : item?.posters} height={height} width='72' alt='' className={!longWidget ? 'landscapeImg' : 'potraitImg'} />
                                </a>
                            )
                        } else {
                            return (
                                <a key={id} _id={item._id || ''} widget-selectable={uniqueId} content-type={item?.content_type || ''} ottplay_id={item?.movie?.ottplay_id || ''} name={item?.name} providers={item?.movie?.where_to_watch[0]?.provider._id || ''} seo_url={item?.movie?.seo_url || ''} to={item?.content_type == 'movie' || item?.content_type == 'live_tv' || item?.content_type == 'sport' || item?.content_type == 'documentary' ? `/player` : `/show-details`} >
                                    <Image src={item?.content_type == "live_tv" ? item?.movie?.backdrops[0]?.url : item?.movie?.posters} height={height} width='72' alt='' className={!longWidget ? 'landscapeImg' : 'potraitImg'} />
                                </a>
                            )
                        }

                    })}
                </div>
            </div>
            <div className='space'></div>
            <style jsx="true">
                {`
            .cardContainer{
                display: flex;
                align-items:center;
                padding:0 8px;
            }
            .cardContainer a{
                margin-right:4px;
                border-radius:4px;
               
            }
            .cardContainer a span{
                display:flex!important;

            }
            .card{
                width:33%;
            }
            .cardImage{

            }
            .cardContainer img{
                border-radius:2px;
                width:72px;
            }
            .cardContainer img.landscapeImg{
                height:40px;
            }
            .cardContainer img.potraitImg{
                height: 107px;
            }
        `}
            </style>
        </>
         : null
    )
}

export default React.memo(CardContainer);