import React, { useContext, useEffect, useState } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import { apiUrl, subsApi } from "../site.config";
import { navigate } from "../navigate";
import { useRef } from "react";
import Cookies from "js-cookie";
import { generateAuthorization } from "../utils/captchGenerate";
import { logEvent } from "../utils/helper";
import { UserContext } from "../Contexts/userContext";

export default function OtpScreen() {
  let cellNumber = Cookies.get("value");
  let registered = Cookies.get("registered") || false;
  let [otp, setOtp] = useState("");
  let path;
  let visitedRoutes = JSON.parse(localStorage.getItem('visitedRoutes'));
  // let visitedRoutes = JSON.parse(localStorage.getItem('visitedRoutes'));
  const [verifybtn, setVerifybtn] = useState(true);
  const [errorMsg, setErrorMsg] = useState('')
  const [error, setError] = useState(false);
  const [resendbtn, setResendbtn] = useState(true);
  const [currentCount, setCount] = useState(30);
  const timer = () => setCount(currentCount - 1);
  const User = useContext(UserContext)
  const curRef = useRef(0);
  useEffect(() => {
    Cookies.set("login_type", true);
    if (!Cookies.get('routePath')) {
      Cookies.set("routePath", visitedRoutes);
      localStorage.setItem('visitedRoutes', JSON.stringify(visitedRoutes.slice(0, 1)));
    }
    path = visitedRoutes[visitedRoutes.length - 3] === '/menu' ? '/' : '/plans';
    let inputBox = document.querySelector(".otp_number_block");
    inputBox.focus();
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  function handleKeyDown(e) {
    let a = document.getElementById("verifyOTP");
    if (e.key === "Backspace") return;
    let resendOTPbtn = document.getElementById("resendOTP");
    let verifyOTPbtn = document.getElementById("verifyOTP");
    let loginAnother = document.getElementById("anotherLogin");
    let inputOtp = document.getElementById(`input-otp`);
    switch (e.key) {
      case "ArrowUp":
        if (curRef.current === 0) return;
        else if (curRef.current === 1) {
          curRef.current = 0;
          inputOtp.focus();
          window.scroll({ top: inputOtp.offsetTop - 115 })
          verifyOTPbtn.blur();
        } else if (curRef.current === 2 && !verifyOTPbtn.disabled) {
          curRef.current -= 1;
          resendOTPbtn.blur();
          verifyOTPbtn.focus();
        } else if (curRef.current === 2 && verifyOTPbtn.disabled) {
          curRef.current = 0;
          inputOtp.focus();
          window.scroll({ top: inputOtp.offsetTop - 115 })
          resendOTPbtn.blur();
        }
        else if (curRef.current === 3 && !resendOTPbtn.disabled) {
          curRef.current = 2;
          loginAnother.blur();
          resendOTPbtn.focus();
          verifyOTPbtn.blur();
        }
        else if (curRef.current === 3 && !verifyOTPbtn.disabled) {
          curRef.current = 1;
          loginAnother.blur();
          resendOTPbtn.blur();
          verifyOTPbtn.focus();
        }
        else if (curRef.current === 3 && resendOTPbtn.disabled && verifyOTPbtn.disabled) {
          curRef.current = 0;
          inputOtp.focus();
          window.scroll({ top: inputOtp.offsetTop - 115 })
          loginAnother.blur();
          resendOTPbtn.blur();
          verifyOTPbtn.blur();
        }

        break;
      case "ArrowDown":
        if (curRef.current === 3) {
          return;
        }
        else if (
          curRef.current === 0 &&
          verifyOTPbtn.disabled &&
          resendOTPbtn.disabled
        ) {
          curRef.current = 3;
          verifyOTPbtn.blur();
          resendOTPbtn.blur();
          loginAnother.focus();
        } else if (curRef.current === 0 && !verifyOTPbtn.disabled) {
          curRef.current += 1;
          loginAnother.blur();
          verifyOTPbtn.focus();
        } else if (curRef.current === 0 && !inputOtp.value.toString().length < 6 && verifyOTPbtn.disabled) {
          curRef.current = 2;
          inputOtp.blur();
          resendOTPbtn.focus();
        } else if (curRef.current === 1 && resendOTPbtn.disabled) {
          return;
        } else if (curRef.current === 1 && !resendOTPbtn.disabled) {
          curRef.current += 1;
          verifyOTPbtn.blur();
          resendOTPbtn.focus();
        }
        else if (curRef.current === 2 && loginAnother.disabled) {
          return;
        } else if (curRef.current === 2 && !loginAnother.disabled) {
          curRef.current += 1;
          verifyOTPbtn.blur();
          resendOTPbtn.blur();
          loginAnother.focus();
        }
        break;
      case "Enter":
        if (document.activeElement === verifyOTPbtn) {
          verifyOTPbtn.blur();
          if (otp && otp.length !== 6) return;
          verifyOTP();
        }
        else if (document.activeElement === resendOTPbtn) {
          resendOTPbtn.blur();
          resendOTP();
        }
        else if (document.activeElement === loginAnother) {
          loginAnother.blur();
          navigate({},'/login');
        }
        break
      default:
        break;
    }
  }
  useEffect(() => {
    if (currentCount <= 0) {
      setResendbtn(false);
      removeClass("resendOTP", "btnDisabled");
      return;
    }
    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [currentCount]);

  function removeClass(target, className) {
    var element = document.getElementById(target);
    element.classList.remove(className);
  }
  function addClass(target, className) {
    var element = document.getElementById(target);
    element.classList.add(className);
  }

  const handleChange = (e) => {
    let otpInput = document.getElementById("input-otp");
    if (otpInput?.value.toString().length > 6) {
      otpInput.value = otpInput?.value.toString().slice(0, 6);
      return;
    }
    let otpValue = otpInput?.value;
    if (otpValue.length === 6) {
      setOtp(e.target.value);
      setVerifybtn(false);
      setResendbtn(false);
      removeClass("verifyOTP", "btnDisabled");
    }
  };

  const verifyOTP = () => {
    let OTP = "";
    OTP = document.getElementById("input-otp").value; // store collection of all <input/> elements
    if (OTP.length === 6) {
      let data = {
        full_name: "sso",
        newsletterConsent: true,
        os: "ios",
        otp: OTP,
        referrer: "OTT",
        source: "S",
        type: "app",
        email_id: null,
        cellNumber: `91${cellNumber}`,
        register: registered == 'false' ? true : false,
        isMergeable: false,
      };
      let headers = {
        "x-client": 1001,
        apiversion: 1,
        'content-type': 'application/json',
        authorization: "bearer 34B7FBD24B97D7A4CF717D414F2A8"
      };
      let loginOtpUrl = `${subsApi}api/v2.1/web/user/viaOtp`;
      let otpResponse = fetch(loginOtpUrl, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(data),
      }).then((r) => r.json())
        .then(function (otpResponse) {
          // This is the JSON from our response
          console.log("otpsresponse", otpResponse);
          if (otpResponse?.data?.headers?.authorization) {
            Cookies.set("clientId", otpResponse?.data?.data?.clientId);
            localStorage.setItem('clientId',otpResponse?.data?.data?.clientId)
            localStorage.setItem('auth',otpResponse?.data?.headers?.authorization)
            localStorage.setItem('logged_in',true)
            Cookies.set("email", otpResponse?.data?.data?.email);
            Cookies.set("auth", otpResponse?.data?.headers?.authorization);
            Cookies.set("logged_in", true);
            Cookies.set("login_type", false);
            Cookies.set("popupAlert", true);
            let visitedRoute = Cookies.get('routePath').split(',');
            localStorage.setItem('visitedRoutes', JSON.stringify(visitedRoute.slice(0, visitedRoute.length - 2)));
            // let userSubscription = fetchSubscricption(otpResponse?.data?.data?.clientId);
            fetch(`${apiUrl}/v4.5/mobile/plansubscribe/getusersubscription`, { headers: { client_id: otpResponse?.data?.data?.clientId, 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } })
              .then(function (response) {
                // The API call was successful!
                return response.json();
              }).then(function (userSubscription) {
                // This is the JSON from our response
                console.log("fetchsubscription", userSubscription);
                if (userSubscription?.plans.length) {
                  // User?.setUser({ ...User.user, 'planName': userSubscription?.plans[0]?.name })
                  User.setUser(Object.assign({}, User.user, { 'planName': userSubscription?.plans[0]?.name }))
                  Cookies.set('subscribed', true);
                  Cookies.set('subscribeId', userSubscription?.plans[0]?.subscription_id);
                  Cookies.set('productId', userSubscription?.plans[0]?.product_id);
                  Cookies.set('plan_code', userSubscription?.plans[0]?.plan_code);
                  logEvent('sso_signin_sucess', '<success>', null)
                  console.log('visitedroutes', visitedRoute)
                  let navPath = visitedRoute[visitedRoute.length - 2];
                  if (navPath) {
                    Cookies.remove('routePath');
                    navigate({},navPath);
                  } else {
                    Cookies.remove('routePath');
                    navigate({},'/');
                  }
                }
                else {
                  Cookies.set('subscribed', false);
                  Cookies.remove('routePath');
                  navigate({},path);
                }
              }).catch(function (err) {
                // There was an error
                console.warn('Something went wrong.', err);
              });
          } else if (!otpResponse?.data?.success) {
            removeClass("resendOTP", "btnDisabled");
            curRef.current = 0;
            setVerifybtn(true);
            setErrorMsg(otpResponse?.data?.message?.text)
            addClass("verifyOTP", "btnDisabled");
            setError(true);
            document.getElementById("input-otp").value = "";
            document.getElementById("input-otp") &&
              document.getElementById("input-otp").focus();
              window.scroll({ top: document.getElementById("input-otp").offsetTop - 115 })
            setResendbtn(false);
          }
        }).catch(function (err) {
          // There was an error
          console.warn('Something went wrong.', err);
        });
    }
  };
  function resendOTP() {
    document.getElementById("input-otp").value = "";
    document.getElementById("input-otp").focus();
    window.scroll({ top: document.getElementById("input-otp").offsetTop - 115 })
    curRef.current = 0;
    setVerifybtn(true);
    addClass("verifyOTP", "btnDisabled");
    let data = {
      "otpFor": "SIGN_UP",
      "referrer": "OTT",
      cellNumber: `91${cellNumber}`,
    };
    let bodyData = {
      "otpFor": "SIGN_UP",
      "referrer": "OTT",
      cellNumber: `91${cellNumber}`,
      "authPayload": JSON.stringify(data)
    }

    let token = generateAuthorization(data);
    let headers = {
      'accept': 'application/json, text/plain, */*',
      'Accept-Encoding': 'gzip',
      'apiversion': '1',
      'authorization': "",
      'Connection': 'Keep-Alive',
      'Content-Length': '159',
      'Content-Type': 'application/json',
      'Host': 'accounts.hindustantimes.com',
      'User-Agent': 'okhttp/4.10.0',
      'x-authorization': token,
      'x-client': '1016',
      'x-platform': 'android',
    }
    let checkRegisterUrl = `${apiUrl}/v3.2/mobile/user/generateOtp`
    fetch(checkRegisterUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(bodyData),
    })
      .then((r) => r.json())
      .then(function (registeredResponse) {
        // This is the JSON from our response
        console.log("otplogin", registeredResponse);
        document.getElementsByClassName("otp_number_block").value = '';
        setError(false);
        setCount(30);
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
  }

  return (
    <>
      {/* <Header /> */}
      <div className="loginWrap">
        <div className="otpBox">
          <h4 className="loginBoxHeading">
            An OTP code has been sent to
            <br /> +91 {cellNumber}
          </h4>
          <h4 className="loginBoxHeading">Please enter code below</h4>
          <div className="otpInputWrapper">
            <input
              name={`otp-field`}
              type="tel"
              autoComplete="off"
              className={`otp_number_block ${error ? 'otperror' : ''}`}
              id={`input-otp`}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {error ? (
            <span className="error">{errorMsg || "Entered OTP is incorrect"}</span>
          ) : null}
          <span className="otpResendTimer">
            00 :{currentCount < 10 ? `0${currentCount}` : currentCount}
          </span>
          <button
            id="verifyOTP"
            disabled={verifybtn}
            className="proceedBtn btnDisabled"
          >
            Verify OTP
          </button>
          <button
            id="resendOTP"
            disabled={resendbtn}
            className="proceedBtn btnDisabled"
          >
            Didn’t receive OTP? Resend OTP in 30 Sec
          </button>
          <button
            id="anotherLogin"
            className="proceedBtn"
          >
            Login Via Another Number
          </button>
        </div>
      </div>
      {/* <Footer /> */}
      <style jsx="true">
        {`
          .loginWrap {
            background-color: #270048;
            padding: 50px 10px 53px;
          }
          .proceedBtn {
            width: 100%;
            display: block;
            background: #521384;
            font-size: 14px;
            color: #ffffff;
            padding: 8px 5px;
            border: 2px solid #6413A7;
            margin: 10px 0;
            border-radius: 4px;
            cursor: pointer;
            font-weight:700;
        }
          .proceedBtn.btnDisabled {
            cursor: not-allowed;
            opacity: 0.5;
            background: transparent;
            border:1px solid #9989B5;
            color: #9989B5;
        }
          .error {
            font-size: 10px;
            color: #ff006f;
            display: inline-block;
            padding: 5px 0 10px;
          }
          .jioLoginBottom {
            text-align: center;
            margin-top: 25px;
            color: #b1a4c9;
            font-size: 10px;
            line-height: 18px;
          }
          .jioLoginBottom > span {
            text-decoration: underline;
            cursor: pointer;
          }

          .otpBox h4 {
            font-size: 12px;
            line-height: 18px;
            color: #ffffff;
            margin: 0;
            padding: 5px 0 8px 0;
            text-align: center;
            font-weight: 400;
          }
          .otpInputWrapper {
            display: flex;
            gap: 3px;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
          }
          .otp_number_block {
            background: #0a0417;
            border: 1px solid #9a8db4;
            border-radius: 6px;
            font-size: 12px;
            height: 16px;
            width: 100%;
            padding: 6px;
            color: #ffffff;
            text-align: center;
            outline: none;
            box-shadow: none;
          }
          .otp_number_block::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }
          .otp_number_block::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          .otp_number_block.otpError {
            border: 1px solid #ff006f;
          }
          .otpResendTimer {
            font-size: 10px;
            font-weight: 700;
            padding: 5px 0;
            color: #29f87e;
            width: 100%;
            display: inline-block;
            text-align: right;
          }
          #verifyOTP:focus {
            color: #270048;
            background: #29F87E;
            border: 2px solid #29F87E;
            outline: none;
          }
          #resendOTP:focus{
            color: #270048;
            background: #29F87E;
            border: 2px solid #29F87E;
            outline: none;
          }
          #anotherLogin:focus{
            color: #270048;
            background: #29F87E;
            border: 2px solid #29F87E;
            outline: none;
          }
          .otperror{
            border:1px solid #ff006f;
          }
        `}
      </style>
    </>
  );
}

// background: rgb(104,15,170);
