import React, { useEffect } from "react";
// import Image from "../components/Image";
import OTTPlaylogo from '../Assets/images/ottplay.png'
import HTlabs from '../Assets/images/htlabs.png'
import { navigate } from "../navigate";
import Cookies from "js-cookie";
import UserProvider from "../Contexts/userContext";
import App from "../App";
import { root } from "..";
// import { navigate } from "../utils/navigate";
// import { useNavigate } from "react-router-dom";
export default function FlashScreen() {

  useEffect(() => {
    setTimeout(() => {
      if (Cookies.get('login_type') == 'true') {
        root.render(<UserProvider><App/></UserProvider>);
        navigate({},'/otp')

      }
      else {              // navigate to home page
        root.render(<UserProvider><App/></UserProvider>);
        navigate({},'/')
      }
    }, 2000);
  }, []);
  return (
    <>
      <div className="flash">
        <img src={OTTPlaylogo} height="47" width="134" className="ottplaylogo" alt="" />
        <div className="flashhtlabs">
        </div>
        <img className="htlabslogo" src={HTlabs} height="37" width="53" alt="" />
      </div>

      <style jsx="true">
        {`
     .flash{
      height: 100vh;
    width: 100%;
    background-color: rgba(16,8,32,1);  
     }
  
     .ottplaylogo{
      position:fixed;
      top:112px;
      left:50%;
      transform:translate(-50%);
     }
.flashhtlabs{
  position:fixed;
  bottom:0;
  left:50%;
      transform:translate(-50%);
      height:77px;
      width:154px;
      background-color :rgb(104,15,170);  
      filter:blur(50px)
}
.htlabslogo{
  position:fixed;
  bottom:20px;
  left:50%;
      transform:translate(-50%);
      height:37px;
      width:53px;
  
}
     `}
      </style>
    </>
  );
}


// background: rgb(104,15,170);
