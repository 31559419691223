import { useState, useEffect, useRef } from 'react';

export const useNavigation = (tabNo, totalTabs=false,setTabNo = ()=>{}) => {
//    let tabNo = tabNoRef?.current
  const istabActiveRef = useRef(totalTabs ? true : false)
  const tabNoRef = useRef(tabNo)

  
  tabNoRef.current = tabNo
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    setNavigation(0);
    return () => document.removeEventListener('keydown', onKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [current, setCurrent] = useState({ type: null, index: null });

  const getAllElements = () =>{
  return  document.querySelectorAll('[nav-selectable]')};

  const getTheIndexOfTheSelectedElement = () => {
    const element = document.querySelector('[nav-selected=true]');
    return element ? parseInt(element.getAttribute('nav-index')) : 0;
  }
  // index = 0 first time
  const setNavigation = index => selectElement(getAllElements()[index] || document.body);
  const onKeyDown = evt => {
    // if (evt.key !== 'ArrowDown' && evt.key !== 'ArrowUp') return;
    const allElements = getAllElements();
    let currentIndex = getTheIndexOfTheSelectedElement();
    let setIndex;
   
    switch (evt.key) {
        case 'ArrowLeft':
            if(currentIndex === 0 ||  !totalTabs) return;          
            setIndex =  currentIndex - 1;
            selectElement(allElements[setIndex] || allElements[0], setIndex)
            setTabNo('left');
              break;

        case 'ArrowRight':
            if( !totalTabs  ||   currentIndex === totalTabs-1 ) return;
            setIndex = currentIndex + 1;
            selectElement(allElements[setIndex] || allElements[0], setIndex)
            setTabNo('right');
            break;
      case 'ArrowDown':
        if(totalTabs && allElements.length === totalTabs ) return
        if(currentIndex === allElements.length-1 ) return ;    
        else if(istabActiveRef?.current){
            setIndex = totalTabs
            istabActiveRef.current = false
        }
        else{
            setIndex =  currentIndex + 1 ;
        }
        return selectElement(allElements[setIndex] || allElements[0], setIndex);
      case 'ArrowUp':
        if(currentIndex ===0 ||  istabActiveRef?.current) return ;
        if(currentIndex === totalTabs){
            istabActiveRef.current =true;
            setIndex =  tabNoRef.current;
        }
        else{
            setIndex =  currentIndex - 1;
        }
        return selectElement(allElements[setIndex] || allElements[0], setIndex);
      default:
        break;
    }
  }

  const selectElement = (selectElement, setIndex = 0) => {
    if (selectElement) {
      [].forEach.call(getAllElements(), (element, index) => {
        const selectThisElement = element === selectElement;
        element.setAttribute("nav-selected", selectThisElement);
        element.setAttribute("nav-index", index);
        if (element.nodeName === 'INPUT') {
           index ==0 && window.scrollTo({top:0})
           
          selectThisElement ? element.focus() : element.blur();
        }
      });
      setCurrent({ type: selectElement.tagName, index: setIndex });
    } else {
      setNavigation(0);
    }
  }

  return [current, setNavigation];
};