
import React, { useEffect, useRef, useState } from "react";
import Header from '../Components/header'
import Footer from '../Components/footer'
import Cookies from "js-cookie";
import { fetchData } from "../Api/client";
import { apiUrl } from "../site.config";
import { DateFormatter, dateFormater } from "../utils/helper";
import Loader from "../Components/loader";
import { navigate } from "../navigate";

export default function MyProfile() {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({})
    const [noDataSnippet, setNoDataSnippet] = useState({
        description: '',
        name: ''
    })
    let rendered = useRef(false)

    const [clientId, setClientId] = useState(Cookies.get(['clientId']) || '')
    let mobNo = Cookies.get('value') || '';
    const [navigateValue, setNavigateValue] = useState({
        menuValue: 'Menu',
        footerValue: 'Manage Subscription',
        ManageComponent: false,
        CancelComponent: false
    })
    useEffect(() => {
        setLoading(true)
        fetchSubscricption();
        return () => { document.removeEventListener("keydown", handlekey); }
    }, [])

    const handlekey = (e) => {
        switch (e.key) {
          case "Enter":
            if (document.activeElement === document.getElementById('purchasebtn')) {
              document.getElementById('purchasebtn').blur();
              navigate(e,'/plans')
            }
            break
          default:
            break;
        }
    }
    const fetchSubscricption = () => {
        fetch(`${apiUrl}/v2.2/mobile/plansubscribe/getusersubscription`, { headers: { client_id: clientId, 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } })
            .then(function (response) {
                // The API call was successful!
                return response.json();
            }).then(function (subsData) {
                // This is the JSON from our response
                console.log("myprofile", subsData);
                setLoading(false)
                if (subsData?.plans?.length) {
                    setUserData(subsData?.plans[0])
                }
                else {
                    setNoDataSnippet({ name: 'No Plan Subscribed', description: 'Please click and subscribe to our plans' })
                    setTimeout(() => {
                        rendered.current = true
                        rendered.current && document.addEventListener("keydown", handlekey);
                        document.getElementById('purchasebtn').focus();
                    }, 500)
                }
            }).catch(function (err) {
                setLoading(false)
                // There was an error
                console.warn('Something went wrong.', err);
            });
    }
    return (
        <>
            {/* <Header page={'myProfile'} /> */}
            {!loading ?
                <>
                    <div className="myProfileWrap">
                        <div className="profileHeading">My Subscription</div>
                        <div className="subscribedNumber">{mobNo ? `+91 ${mobNo}` : ''}</div>
                        <div className="plansBoxProfile">
                            <h2 className="planTitleProfile">{userData?.name || noDataSnippet.name}</h2>
                            <p className="planDescProfile">{userData?.description || noDataSnippet.description}</p>
                            <div className="planPartnersProfile">
                                {Array.isArray(userData.providers) && userData?.providers?.length
                                    ? userData?.providers?.map((provider, id) => (
                                        <img key={id} src={provider?.logo_url} alt="" />
                                    ))
                                    : null}
                            </div>
                            {Array.isArray(userData.providers) && userData?.providers?.length ?
                                <div className="planValidityWrap">
                                    <div className="purchasedDate">Purchased on {dateFormater(userData?.purchase_date || null)}</div>
                                    <div className="purchasedPlanAmount">₹{userData?.original_price || userData?.price} <span className="planSuperText"> per {userData?.duration == 30 ? 'month' : userData?.duration == 90 ? 'quarter' : 'year'}</span></div>
                                    <div className="ExpiryDate">Valid upto {dateFormater(userData?.expiry || null)}</div>
                                </div>
                                :
                                <button id="purchasebtn" className="upgradeBtn"><div className="planAmount" style={{ fontSize: '12px' }}>
                                    OK
                                </div></button>
                            }
                        </div>

                    </div>
                </>
                :
                <div className="loaderPlan"><Loader /></div>
            }
            {/* <Footer footerValue={''} navigateTo={''} /> */}


            <style jsx='true'>
                {`
            .myProfileWrap{
                padding: 50px 7px 30px;
            }
            .profileHeading{
                font-size:18px;
                font-weight:700;
                line-height:19px;
                margin:0;
                padding-bottom:10px;
                color:#ffffff;
            }
            .subscribedNumber{
                margin: 0;
                padding-bottom: 8px;
                color: #9989B5;
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
            }
            .plansBoxProfile{
                padding: 7px 9px;
                background-color:#39135B;
                border-radius: 3px;
                margin-bottom:11px;
            }
            .planTitleProfile{
                font-size:16px;
                font-weight:700;
                color:#ffffff;
                margin:0;
                padding-bottom:3px;
                line-height:17px;
            }
            .planDescProfile{
                margin:0;
                padding-bottom:8px;
                color:#9989B5;
                font-size:14px;
                font-weight:400;
                line-height:18px;
            }
            .planPartnersProfile{
                display:flex;
                flex-wrap:wrap;
                padding-bottom:10px;
            }
            .planPartnersProfile img {
                width: 65px;
                border-radius: 2px;
                margin:0px 2px 4px;
            }
            .upgradeBtn{
                width:100%;
                position:relative;
                font-size:12px;
                font-weight:700;
                color:#270048;
                line-height:15px;
                padding: 9px;
                border:0;
                border-radius:4px;
                background-color:#521384;
                outline:0;
                box-shadow:none;
                margin-top:5px;
                text-align:left;
            }
            .upgradeBtn::after{
                content:'';
                position:absolute;
                top:50%;
                right:10px;
                transform:translateY(-50%);
                background:url(https://images.ottplay.com/static/images/blackRightArrow.png) no-repeat;
                background-size:20px;
                width:20px;
                height:20px;
            }
            #purchasebtn:focus {
                color: #270048;
                background: #29F87E;
                border:2px solid #29F87E;
                outline:none;
            }
            .planValidityWrap{
                display:flex;
                flex-direction:column;
                padding-top:10px;
                border-top:1px solid rgba(236, 229, 250, 0.3);
            }
            .purchasedDate{
                font-size:12px;
                line-height:13px;
                color:#9989B5;
                font-weight:400;
                padding-bottom:5px;
            }
            .purchasedPlanAmount{
                font-size:19px;
                line-height:17px;
                color:#ffffff;
                font-weight:700;
                padding-bottom:5px;
            }
            .purchasedPlanAmount .planSuperText{
                font-size:12px;
                font-weight:400;
            }
            .ExpiryDate{
                font-size:12px;
                line-height:13px;
                color:#29F87E;
                font-weight:700;
            }
        `}
            </style>
        </>
    );
}


