import React, { useEffect, useState } from 'react'
import Title from './titleHeading';
import { fetchData } from '../Api/client';
import Image from './Image';
import { apiUrl } from '../site.config';
import Cookies from 'js-cookie';


const ListContainer = ({ item = {}, id = "", page = '', uniqueId = '', longWidget = true }) => {
    const { title, pin_it, end_point, template_name, section } = item;
    let client_id = Cookies.get("clientId") || ''

    let height = longWidget ? '103' : '44'


    const [widgetData, setWidgetData] = useState([])

    useEffect(() => {
        fetchwidgetData(pin_it, end_point, template_name, section);
    }, [])

    const fetchwidgetData = (pin_it, end_point, template_name, section) => {
        // let wdata =  fetchData(`${apiUrl}${end_point}?limit5&pin_it=${pin_it}&template_name=${template_name}&section=${section}&page=1&module_name=${page=='live' ? 'livetv':'Home'}`)
        fetch(`${apiUrl}${end_point}?limit5&pin_it=${pin_it}&template_name=${template_name}&section=${section}&page=1&module_name=${page == 'live' ? 'livetv' : 'Home'}`,
         { headers: { 'client_id': client_id||'', 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } }).then(function (response) {
            return response.json();
        }).then(function (data) {
            console.log("listContainer", data);
            if (data?.rank && data?.rank.length) setWidgetData(data.rank.slice(0, 3))
            else {
                setWidgetData([])
            }
        }).catch(function (err) {
            // There was an error
            console.warn('Something went wrong.', err);
        });
        // if (wdata?.rank && wdata?.rank.length) setWidgetData(wdata.rank.slice(0, 3))
        // else {
        //     setWidgetData([])
        // }
    }

    return ((Array.isArray(widgetData) && widgetData.length) ?
        <>
            <div className='fixedContainer'>
                <Title title={title} template_name={template_name} section={section} end_point={end_point} id={id} />
                <div className='cardContainer' id={uniqueId} home-selectable="true">
                    {widgetData && widgetData.map((item, id) => {
                        return (
                            <a key={id} _id={item._id || ''} widget-selectable={uniqueId} content-type={item?.content_type || ''} name={item?.name} ottplay_id={item?.ottplay_id || ''} providers={item?.where_to_watch[0]?.provider || ''} seo_url={item?.seo_url || ''} to={item.content_type == 'movie' ? `/player?content_type=${item?.content_type}&ottplay_id=${item?.ottplay_id}&provider=${item?.where_to_watch[0]?.provider}&subscriptionType=${item?.where_to_watch[0]?.subscription_type}&_id=${item._id || ''}&name=${item?.name}` : `/show-details?seo_url=${item?.seo_url}&subscriptionType=${item?.where_to_watch[0]?.subscription_type}&name=${item?.name}`} >
                                <Image src={item?.content_type == "live_tv" ? item?.backdrops : item?.posters} height={height} width='72' alt='' className='potraitImg' />
                            </a>
                        )
                    })}
                </div>
            </div>
            <div className='space'></div>
            <style jsx="true">
                {`
    .cardContainer{
        display: flex;
        align-items:center;
        padding:0 8px;
    }
    .cardContainer a{
        margin-right:4px;
        border-radius:4px;
    }
    .cardContainer a span{
        display:flex!important;
    }
    .card{
        width:33%;
    }
    .cardImage{

    }
    .cardContainer img{
        border-radius:2px;
    }
    .cardContainer img.landscapeImg{
        height:40px;
    }
    .cardContainer img.potraitImg{
        height:107px;
    }
    
`}
            </style>
        </> : null
    )
}

export default React.memo(ListContainer);