import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Cookies from "js-cookie";
import ReactGA from "react-ga4";
import FlashScreen from './Pages/flashScreen';
export const windowAny = typeof window !== 'undefined' && window;

if(localStorage.getItem("visitedRoutes")){localStorage.removeItem("visitedRoutes");}
if (localStorage.getItem('clientId')) {
   let clientId = localStorage.getItem('clientId')
   let value = localStorage.getItem('value')
   let logged_in = localStorage.getItem('logged_in')
   let auth = localStorage.getItem('auth')
   Cookies.set('clientId',clientId)
   Cookies.set('auth',auth)
   Cookies.set('value',value)
   Cookies.set('logged_in',logged_in)
   
}

ReactGA.initialize("G-7CNV2SZN2T");
export const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <FlashScreen/>
   
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
