import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../Components/header';
import Footer from '../Components/footer';
import Image from '../Components/Image';
import { UserContext } from '../Contexts/userContext';
import { navigate, navigateBack } from '../navigate';
import { activeBtnColor, activeElementBorderColor, logEvent } from '../utils/helper';
import { fetchData } from '../Api/client';
import { apiUrl } from '../site.config';
import Loader from '../Components/loader';
const Topmovies = ({ type = "" }) => {
  const { end_point = '', section = '', template_name = '', title = '', page = 'Home', seo_url = '' } = window?.history?.state;
  console.log("toptenstate", window?.history?.state)

  const renderedtopten = useRef(false)
  const [loadMore, setLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingmore, setLoadingmore] = useState(false);
  const [Page, setpage] = useState(1);
  const [nextPage, setNextPage] = useState(null)
  const cur = useRef(0);
  const pageLimit = useRef(1)

  // const {user,auth,setAuth} = useContext(UserContext)
  const [top10, setTop10] = useState([])

  useEffect(() => {
    (() => {
      setLoading(true)
      getTop10Data();
      // setTimeout(() => {
      //   renderedtopten.current = true
      //   renderedtopten.current && ListEvents();
      //   setLoading(false);
      // },1500);
    })()
    return () => { document.removeEventListener("keydown", handlekey) }
  }, [])

  let viewAll;
  // renderedtopten.current &&
  //  setTimeout(() => { 
  function ListEvents() {
    renderedtopten.current = false
    viewAll = document.querySelectorAll("[nav-selectable='true']");
    if (viewAll.length) {
      viewAll[cur.current].style.border = `2px solid ${activeElementBorderColor}`
      document.addEventListener("keydown", handlekey)
    }
  }
  const getTop10Data = () => {
    setLoadMore(true);
    setLoadingmore(true)
    setpage((prev) => prev + 1)
    // let result =  fetchData(`${apiUrl}${end_point}?limit=10&pin_it=false&template_name=${template_name}&section=${section}&page=${pageLimit.current}&module_name=Home`)
    let result = fetch(`${apiUrl}${end_point}?limit=10&pin_it=false&template_name=${template_name}&section=${section}&page=${pageLimit.current}&module_name=Home`, { headers: { 'client_id': '', 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } })
      .then(function (response) {
        // The API call was successful!
        return response.json();
      }).then(function (data) {
        // This is the JSON from our response
        console.log("topten", data);
        setLoadMore(false);
        setTimeout(() => {
          renderedtopten.current = true
          renderedtopten.current && ListEvents();
          setLoading(false);
          setLoadingmore(false);
          if (data?.data?.length < 10) setLoadMore(true);
        },100);
        if (data?.rank?.length) {
          pageLimit.current = pageLimit.current + 1;
          setpage(Page + 1)
          setTop10((prev) => [...prev, ...data?.rank])
          setNextPage(data.nextPage || null)
        }
        else {
          setTop10((prev) => [...prev])
        }
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
    // if (result?.data?.length < 10) setLoadMore(true);
    // if (result?.rank?.length) {
    //   pageLimit.current = pageLimit.current + 1;
    //   setpage(Page + 1)
    //   setTop10((prev) => [...prev, ...result?.rank])
    //   setNextPage(result.nextPage || null)
    // }
    // else {
    //   setTop10((prev)=>[...prev])
    // }
  }

  const handlekey = (e) => {
    let btn = document.getElementById('loadmrbtn');
    if (e.key === "ArrowUp") {
      if (document.querySelector('.topmoviesWrap')) document.querySelector('.topmoviesWrap').style['padding-top'] = '40px'
      if (cur.current === -1) return;
      if (cur.current === 0) {
        let headerNav = document.querySelectorAll('[head-selectable]')[0];
        viewAll[cur.current].style.removeProperty("border");
        headerNav.style.outline = `2px solid ${activeBtnColor}`
        headerNav.style.boxShadow = `0px 0px 6px #ffffff`;
        cur.current = -1;
        return;
      }
      if (document.activeElement === btn) {
        btn.style.removeProperty("border")
        btn.blur();
        viewAll[cur.current].style.border = `2px solid ${activeElementBorderColor}`;
      }
      else {

        if (viewAll[cur.current]) {

          viewAll[cur.current].style.removeProperty("border");
          viewAll[cur.current - 1].style.border = `2px solid ${activeElementBorderColor}`;
          // viewAll[cur.current - 1].scrollIntoView(true)
          window.scrollTo({ top: viewAll[cur.current - 1].offsetTop - 100 })
        }
        if (cur.current != 0) cur.current = cur.current - 1

      }
    }
    if (e.key == "ArrowDown") {
      if (cur.current === -1 || cur.current === -2) {
        if (document.querySelector('.topmoviesWrap')) document.querySelector('.topmoviesWrap').style['padding-top'] = '100px'
        let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
        if (headerNav) {
          headerNav.style.removeProperty("box-shadow");
          headerNav.style.removeProperty("outline");
        }
        cur.current = 0;
        viewAll[cur.current].style.border = `2px solid ${activeElementBorderColor}`
        return
      }
      if (cur.current == viewAll.length - 1) {
        if (btn) {
          viewAll[cur.current].style.removeProperty("border");
          btn.focus();
          btn.scrollIntoView(true)
          // btn.style.border = "2px solid red";
        }
      }
      else {
        viewAll[cur.current].style.removeProperty("border");
        if (cur.current != viewAll.length - 1) cur.current = cur.current + 1;
        viewAll[cur.current].style.border = `2px solid ${activeElementBorderColor}`;
        viewAll[cur.current].scrollIntoView(false)
        window.scrollTo({ top: viewAll[cur.current].offsetTop - 200 })
      }
    }
    if (e.key == "ArrowRight") {
      if (cur.current === -1) {
        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
        headerNavSubs.style.removeProperty('box-shadow')
        headerNavSubs.style.removeProperty('outline')
        headerNavProfile.style.boxShadow = `0px 0px 6px #ffffff`;
        cur.current -= 1;
      }
      if (cur.current === -2) return;
    }
    if (e.key == "ArrowLeft") {
      if (cur.current === -2) {
        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
        headerNavProfile.style.removeProperty('box-shadow')
        headerNavSubs.style.boxShadow = `0px 0px 6px #ffffff`;
        cur.current += 1;
      }
    }
    if (e.key == "Enter") {
      if (cur.current == -1) {
        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0].title;
        if (headerNavSubs) {
          navigate(e,`${headerNavSubs}`)
        }
        return;
      }
      else if (cur.current === -2) {
        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1].title;
        if (headerNavProfile) {
          navigate(e,`${headerNavProfile}`)
        }
        return;
      }
      else if (document.activeElement === btn) {
        btn.blur();
        btn.style.removeProperty('border');
        viewAll[cur.current].style.border = `2px solid ${activeElementBorderColor}`;
        (() => {

          getTop10Data();
          viewAll[cur.current].scrollIntoView(true)
          setTimeout(() => {
            viewAll = document.querySelectorAll("[nav-selectable='true']");
          }, 1000);
        })()
      }
      else {

        handleOk(`top-ten-${cur?.current}`,e);
      }
    }
    // else if (e.key === "Backspace") {
    //   const remainingRoutes = JSON.parse(localStorage.getItem("visitedRoutes"));
    //   if (remainingRoutes && remainingRoutes.length == 1) {
    //     localStorage.removeItem("visitedRoutes");
    //     return;
    //   }
    //   e.preventDefault();
    //   navigateBack();
    // }
  }
  const handleOk = (id,e={}) => {
    let state = {
      content_type: document.getElementById(id).getAttribute('contentType'),
      ottplay_id: document.getElementById(id).getAttribute('ottplay_id'),
      provider: document.getElementById(id).getAttribute('providers'),
      _id: document.getElementById(id).getAttribute('_id'),
    }
    let showstate = {
      seo_url: document.getElementById(id).getAttribute('seo_url')
    }
    let path = document.getElementById(id).getAttribute('to');
    let action = `${document.getElementById(id).getAttribute('contenttype')}/${document.getElementById(id).getAttribute('name')}`
    logEvent(`${state?.content_type}_click`, action, `${document.getElementById(id).getAttribute('ottplay_id')}/${document.getElementById(id).getAttribute('providers')}`)

    path.includes('/player') ? navigate(e,path, state) : navigate(e,path, showstate);
  }
  return (
    <>
      {/* <Header /> */}

      <div className='topmoviesWrap'>
        <h2>{`${title || ''}`}</h2>
        {!!top10.length && <ul className='topmoviesReasult'>
          {top10.map((top, id) =>
            <>

              <li key={id} id={`top-ten-${id}`} _id={top._id || ''} nav-selectable="true" contentType={top.content_type || ''} name={top?.name} ottplay_id={top.ottplay_id || ''} providers={top?.where_to_watch[0]?.provider || ''} seo_url={top?.seo_url || ''} to={top.content_type == 'movie' ? `/player?content_type=${top?.content_type}&ottplay_id=${top?.ottplay_id}&provider=${top?.where_to_watch[0]?.provider}&subscriptionType=${top?.where_to_watch[0]?.subscription_type}&_id=${top?._id || ''}` : `/show-details?seo_url=${top?.seo_url}&subscriptionType=${top?.where_to_watch[0]?.subscription_type}`}>
                <a key={id} href={top.content_type == 'movie' ? `/player?content_type=${top?.content_type}&ottplay_id=${top?.ottplay_id}&provider=${top?.where_to_watch[0]?.provider}&subscriptionType=${top?.where_to_watch[0]?.subscription_type}&_id=${top._id || ''}` : `/show-details?seo_url=${top?.seo_url}&subscriptionType=${top?.where_to_watch[0]?.subscription_type}`} >
                  <div className='imagebox'>
                    <Image src={top?.backdrops} width='77' height='47' alt='movie' />
                  </div>
                  <div className='textbox'>
                    <h3>{`${id + 1}. ${top.name}`} </h3>
                    {/* <div className='topMoviesInfo'>
                <span>Hindi</span>
                <span>2005</span>
                <span>Comedy</span>
                <span>Shemaroome</span>
              </div> */}
                  </div>
                </a>
              </li>
            </>)}
        </ul>}
        {loadingmore?<span className='loadingmore'>
        <i><img src="https://images.ottplay.com/static/newImages/new_spinner_mini.svg" alt="" /></i>
        </span>:null}

        {!loadMore && top10?.length && !!nextPage ? (
          <button
            id="loadmrbtn"
            onClick={getTop10Data}
            style={{ color: "black", marginBottom: "30px" }}
          >
            Load More{" "}
          </button>
        ) : null}
      </div>
      {loading ? <div className="loaderPlan"><Loader /></div> : null}
      {/* <Footer /> */}

      <style jsx="true">{`
    .topmoviesWrap {background: #270048;width: 100%;min-height: calc(100vh - 30px) ;padding: 40px 8px;box-sizing: border-box;}
    .topmoviesWrap h2{margin: 0;color: #fff;font-size: 15px;margin-bottom: 10px;font-weight: 600;}
    .topmoviesWrap .topmoviesReasult .imagebox{width: 77px;height: 47px;flex-shrink: 0;background: #510d8b;border-top-left-radius: 5px;border-bottom-left-radius: 5px;overflow:hidden}
    .topmoviesWrap .topmoviesReasult .imagebox img{object-fit: cover;}
    .topmoviesWrap .topmoviesReasult .textbox{width: 100%;padding: 7px;}
    .topmoviesWrap .topmoviesReasult .textbox h3{ white-space: nowrap; width: 130px; overflow: hidden;text-overflow: ellipsis;}
    .topmoviesWrap .topmoviesReasult {margin: 0;padding: 0;list-style: none;margin-bottom: 5px;}
    .topmoviesWrap .topmoviesReasult li{border-radius:4px; overflow:hidden}
    .topmoviesWrap .topmoviesReasult li:not(:last-child){margin-bottom:6px;}
    .topmoviesWrap .topmoviesReasult li a{display:flex;color: #fff;text-decoration: none;background: #3a135b;border-radius: 5px;align-items: center;overflow:hidden;}
    .topmoviesWrap .topmoviesReasult h3{font-size: 12px;line-height: 17px;margin: 0;font-weight: 600;}
    .topmoviesWrap .topmoviesReasult .topMoviesInfo{font-size: 7px;color: #9989B5;line-height: 14px;}
    .topmoviesWrap .topmoviesReasult .topMoviesInfo span:not(:first-child){padding-left:9px;}
    .topmoviesWrap .topmoviesReasult .topMoviesInfo span{position:relative;}
    .topmoviesWrap .topmoviesReasult .topMoviesInfo span:not(:first-child):before{content: '';
    width: 2px;
    height: 2px;
    background: #9989B5;
    position: absolute;
    border-radius: 50%;
    left: 3px;
    top: 5px;}
    .topmoviesWrap button {
      margin: 0;
    height: 30px;
    width: 100%;
    background-color: #521384;
    padding: 4px 7px;
    border-radius: 2px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 700;
    color: #ffffff !important;
    border: 1px solid #6413A7;
    }
    .topmoviesWrap button:focus{
      color: #270048 !important;
      background-color: #29F87E;
      border: 0 !important;
      outline: none !important;
    }
    .imagebox  >  span{
      display: inline !important;
    }
    .topmoviesWrap .topmoviesReasult .imagebox img{
      height:100%;
      width:100%
    }
    .topmoviesWrap .loadingmore {
      display: flex; 
      width:100%;
      justify-content:center;
      color: #836FA5;
    }
     i{
      display: flex; 
      width:100%;
      justify-content:center;
      width: 60px; 
      display: block; 
      line-height: 0; 
    }

    `}</style>
    </>
  )
}

export default Topmovies