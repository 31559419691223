import React, { useContext } from 'react'
import { UserContext } from '../Contexts/userContext'

export default function PackAlertPopup() {
    const User = useContext(UserContext)

    return (
        <>
            <div id='overlay'  className='popupOverly'>

                <div   className="alertPopupWrap">
                    <p className='popupPara'>
                        {User?.user?.paragraph1 ||'Some contents of your'}
                    </p>
                    <h3 className='popupHeading'>
                        {User?.user?.planName || ''}
                    </h3>
                    <p className='popupPara'>
                        {User?.user?.paragraph2 ||'subscription package are not compatible with Jiophone'}
                    </p>
                </div>

            </div>

            <style jsx="true">
                {`
        .alertPopupWrap{
            position: fixed; 
            top: 50%; 
            left: 50%; 
            transform: translate(-50%, -50%);
            padding: 14px 10px; 
            background: #FFFFFF;  
            border-radius: 4px; 
            text-align: center; 
            width: 100%; 
            max-width: 204px; 
            flex-direction: column;
            z-index: 9999;
        }
        .popupHeading{
            font-size:19px;
            line-height:23px;
            font-weight:bold;
            color:#551787;
            margin:0;
        }
        .popupPara{
            font-size:14px;
            line-height:17px;
            font-weight:500;
            color:#270048;
            margin:0;
            padding:2px 0 0px;
        }
        .popupOverly{ display:none;position: fixed; top: 0; right: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.8);  z-index: 999;}
    `}
            </style>
        </>
    )
}
