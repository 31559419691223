import React from 'react'
import { imageLoader } from '../utils/helper'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { defaultImgUrl } from '../site.config';

const Image = ({src='',height='',width='' ,className='',intial=""}) => {
return (
    <LazyLoadImage
    src={imageLoader(src,height,width)}
    className={className || ""}
    alt="Image Alt"
    placeholderSrc={`${defaultImgUrl}?height=${height}&width=${width}&format=jpeg&quality=100`}
  />

)
};
export default Image;
