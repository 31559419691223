
import React, { useContext, useEffect, useRef, useState } from 'react'
import { navigate } from '../navigate'
import FeaturedCarousel from '../Components/featuredCarosel';
import CardContainer from '../Components/cardConatiner';
import Header from '../Components/header';
import Footer from '../Components/footer';
import Cookies from 'js-cookie';
import { UserContext } from '../Contexts/userContext';
import {  activeElementBorderColor, logEvent, removeSpecialCharacter } from '../utils/helper';
import { apiUrl } from '../site.config';
import Loader from '../Components/loader';

export default function ProviderDetail() {

  const { seo_url } = window?.history?.state;
  let rendered = useRef(false)
  let cur = useRef(0)
  let curList = useRef(0)
  let clientId = Cookies.get('clientId') || ''
  const [planName, setPlanName] = useState('Subscribe');
  const [rankingData, setRankingData] = useState([])
  const [loading, setLoading] = useState(false);
  const User = useContext(UserContext)


  let viewAll;
  function homeEvents() {
    rendered.current = false
    viewAll = document.querySelectorAll("[home-selectable='true']");
    if (viewAll.length) {
      document.addEventListener("keydown", handlekey)
      if (viewAll[cur.current].id === 'featuredCarousel') {
        handleactiveInsideWidget(viewAll[cur.current].id);
      }
    }
  }

  useEffect(() => {
    ( () => {
      setLoading(true)
       getSubData();
    })();
    return () => { document.removeEventListener("keydown", handlekey) }
  }, []);

  const getSubData =  () => {
    fetch(`${apiUrl}/v2.2/widgets?menu=${seo_url}`,{ headers: { 'client_id': clientId ,'authorization':"bearer 34B7FBD24B97D7A4CF717D414F2A8"} })
    .then(function (response) {
      return response.json();
    }).then(function (data) {
      console.log(data);
      if (data?.data && data?.data?.length) {
        setTimeout(() => {
          setLoading(false)
          rendered.current = true
          rendered.current && homeEvents();
        }, 4000)
        setRankingData(data.data)
      }
      else {
        setLoading(false)
        setRankingData([])
      }
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });
  }

  const handleactiveInsideWidget = (id, k = "", h = 0) => {
    if (k === "up") {
      window.scrollTo({ top: h })
    }
    else {
      window.scrollTo({ top: h - 90 })
    }
    listItemAll = document.querySelectorAll(`[widget-selectable=${id}]`)
    if (viewAll[cur.current].id === 'featuredCarousel') {
      listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`
      showSlide(curList.current);
    }
    if (listItemAll.length) {
      listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`
    }

  }
  function showSlide(index) {
    if (curList.current === 0) {
      document.getElementById('prevArrow').style.display = 'none';
      document.getElementById('nextArrow').style.display = 'block'
    } 
    if (curList.current === listItemAll.length - 1) {
      document.getElementById('prevArrow').style.display = 'block';
      document.getElementById('nextArrow').style.display = 'none'
    }
    else if(curList.current !== 0 && curList.current !== listItemAll.length - 1) {
      document.getElementById('prevArrow').style.display = 'block'
      document.getElementById('nextArrow').style.display = 'block'

    }


    for (let i = 0; i < listItemAll.length; i++) {
      listItemAll[i].style.display = "none"
    }


    listItemAll[index].style.display = 'block';
  }
  const handlekey = (e) => {
    if (e.key === "ArrowUp") {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
      if (cur.current === -1){
        document.querySelector('.home').style.top="38px";
         return}
      if (cur.current === 0) {
        document.querySelector('.home').style.top="38px";
        let headerNav = document.querySelectorAll('[head-selectable]')[0];
        if (viewAll[cur.current].id === 'featuredCarousel') {
          listItemAll[curList.current].style.removeProperty('border');
        }
        headerNav.style.boxShadow = `0px 0px 6px #ffffff`;
        cur.current -= 1;
        // return;
      }


      else if (cur.current % 2 !== 0) {
        viewAll[cur.current].focus();
        viewAll[cur.current].style.removeProperty("color");
        viewAll[cur.current].style.removeProperty("font-weight");
        if (cur.current != 0) cur.current = cur.current - 1
        handleactiveInsideWidget(viewAll[cur.current].id ,"up", viewAll[cur.current].offsetTop || 0);
      }
      else {
        if (listItemAll.length) listItemAll[curList.current].style.removeProperty("border");
        curList.current = 0;
        viewAll[cur.current - 1].style.color = '#29F87E';
        viewAll[cur.current - 1].style.fontWeight = '700';
        viewAll[cur.current - 1].scrollIntoView();
        if (cur.current != 0) cur.current = cur.current - 1
      }
      if(cur.current === 1 || cur.current==0){
        document.querySelector('.home').style.top="28px";
        
      }


    }

    if (e.key == "ArrowDown") {
      document.getElementsByTagName("body")[0].style.overflow = "hidden"
      if(cur.current===1){
        document.querySelector('.home').style.top="38px";
      }
      if (cur.current === -1 || cur.current === -2) {
        document.querySelector('.home').style.top="88px";
        let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
        if (headerNav) {
          headerNav.style.removeProperty("box-shadow");
          headerNav.style.removeProperty("outline");
        };
        cur.current = 0;
        if (viewAll[cur.current].id === 'featuredCarousel') {
          document.getElementsByTagName("body")[0].style.overflow = "hidden"
          window.scrollTo({ top: 0 })
          handleactiveInsideWidget(viewAll[cur.current].id, "down", viewAll[cur.current].offsetTop || 0);
        }
        return
      };
      if (cur.current == viewAll.length - 1) return;
      else if (cur.current % 2 !== 0) {
        viewAll[cur.current].style.removeProperty("color");
        viewAll[cur.current].style.removeProperty("font-weight");
        if (cur.current != viewAll.length - 1) cur.current = cur.current + 1;
        handleactiveInsideWidget(viewAll[cur.current].id, "down", viewAll[cur.current].offsetTop || 0);
      }

      else {
        if (listItemAll.length) listItemAll[curList.current].style.removeProperty("border");
        curList.current = 0;
        if (cur.current != viewAll.length - 1) cur.current = cur.current + 1;
        viewAll[cur.current].style.color = '#29F87E';
        viewAll[cur.current].style.fontWeight = '700';
        window.scrollTo({top:viewAll[cur.current].offsetTop-70})
        // viewAll[cur.current]?.scrollIntoView(false);
      }
      if (cur.current === -1 || cur.current === -2) {
        document.querySelector('.home').style.top="88px";
       }  

    }
    if (e.key === "ArrowRight") {
      if (cur.current === -2) return;
      if (cur.current === -1) {
        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
        headerNavSubs.style.removeProperty('box-shadow')
        headerNavSubs.style.removeProperty('outline')
        headerNavProfile.style.boxShadow = `0px 0px 6px #ffffff`;
        cur.current -= 1;
      }
      else if (viewAll[cur.current].id === 'featuredCarousel') {
        listItemAll[curList.current].style.removeProperty("border");
        if (curList.current != listItemAll.length - 1) curList.current = curList.current + 1;
        showSlide(curList.current);
        listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`;
      }
      else {
        listItemAll[curList.current].style.removeProperty("border");
        if (curList.current != listItemAll.length - 1) curList.current = curList.current + 1;
        listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`;
      }
    }
    if (e.key == "ArrowLeft") {
      if (cur.current === -2) {
        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
        headerNavProfile.style.removeProperty('box-shadow')
        headerNavSubs.style.boxShadow = `0px 0px 6px #ffffff`;
        cur.current += 1;
      }
      else if (viewAll[cur.current] && viewAll[cur.current].id === 'featuredCarousel') {
        listItemAll[curList.current].style.removeProperty("border");
        if (curList.current != 0) curList.current = curList.current - 1;
        listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`;
        showSlide(curList.current);
      } else {

        listItemAll[curList.current].style.removeProperty("border");
        if (curList.current != 0) curList.current = curList.current - 1;
        listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`;
      }
    }
    if (e.key == "Enter") {
      if (cur.current == -1) {
        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0].title;
        if (headerNavSubs) {
          navigate(e,`${headerNavSubs}`)
        }
        return;
      }
      else if (cur.current === -2) {
        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1].title;
        if (headerNavProfile) {
          navigate(e,`${headerNavProfile}`)
        }
        return;
      }
      let title = viewAll[cur.current].title
      let widgetName = viewAll[cur.current].getAttribute('widgetname')
      let id = viewAll[cur.current].id
      if (title) {
        // navigate('/plans')
        navigate(e,`${title}&seo_url=${seo_url}`)
      }
      else if (id) {
        handleOk(listItemAll[curList.current],e);

      }
    }
  }
  let listItemAll;

  const handleOk = (ele,e={}) => {
    if (viewAll[cur.current].id === 'ottpartners') {
      Cookies.set('providersId', ele.getAttribute('seo_url'))
    }
    let state = {
      content_type: ele.getAttribute('content-type'),
      ottplay_id: ele.getAttribute('ottplay_id'),
      provider: ele.getAttribute('providers'),
      _id: ele.getAttribute('_id')|| '',
    }
    let langState = {
      languageId: ele.getAttribute('lang-id'),
    }
    let showstate = {
      seo_url: ele.getAttribute('seo_url')
    }
    let path = ele.getAttribute('to');
    let eventName = viewAll[cur.current] === 'featuredCarousel' ? 'provider_page_carousel':`${ele.getAttribute('content-type')}_click`

    logEvent(eventName ,`${ele.getAttribute('content-type')}/${ele.getAttribute('name')}`,`${ele.getAttribute('ottplay_id')}/${ele.getAttribute('providers')}`)

    path.includes('/player') ? navigate(e,path, state) : path.includes('/language-detail') ? navigate(e,path, langState) : navigate(e,path, showstate);

  }
  const conditionalRender = (item, id) => {
    switch (item?.content_type) {
      case 'mix_search':
        return <FeaturedCarousel item={item} page={"provider"} seo_url={seo_url} key={id} id={id} uniqueId={'featuredCarousel'} />
      case 'live_tv':
        return <CardContainer item={item} page={"provider"} seo_url={seo_url} longWidget={false} uniqueId={removeSpecialCharacter(item?.template_name||item?.title)} content_type={item?.content_type} key={id} id={id} />
     
      default:
        return <CardContainer item={item} page={"provider"} seo_url={seo_url} longWidget={true} uniqueId={removeSpecialCharacter(item?.template_name||item?.title)} content_type={item?.content_type} key={id} id={id} />

        break;
    }
  }
  return (
    <>
      <div className='home' id="home">
        {/* <Header /> */}
        {
          !!rankingData?.length && rankingData?.map((item, id) => { return conditionalRender(item, id) }
          )
        }
      </div>
      {loading ? <div className="loaderPlan"><Loader /></div> : null}
      {/* <Footer /> */}
      <style jsx="true">{`
     .home{
      background-color: #270048;
      height:calc(100vh-30px);
      width: 100%;
      position:absolute;
      top:28px;
      overflow-x : hidden;
      overflow-y:scroll;
      padding-top:5px;
      padding-bottom:35px;
      }
      
      .space{
        margin-bottom:8px;
      }
      
     `}</style>
    </>
  )
}