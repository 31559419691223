import React from "react";
import Header from "../src/Components/header";
import Route from "./Route";
import Homepage from "./Pages/home";
import TopTen from "./Pages/topTen";
// import Home from "./Pages/homepage";
import GridListingPage from "./Pages/gridListingPage";
import Search from "./Pages/search";
import VideoPlayer from "./Pages/videoPlayer";
import NavMenu from "./Pages/navMenu";
import Login from "./Pages/login";
import OtpScreen from "./Pages/otpScreen";
import ShowDetails from "./Pages/showDetails";
import PlansList from "./Pages/planListing";
import PlanDetails from "./Pages/planDetails";
import AllLanguage from "./Pages/allLanguages";
import PartnerList from "./Pages/partnerList";
import AllPartners from "./Pages/allPartners";
import AllSearchResult from "./Pages/allSearchResults";
import MyProfile from "./Pages/myProfile";
import LangauageDetail from "./Pages/languageDetails";
// import LangauageDetail from "./Pages/langauageDetail";
import ProviderDetail from "./Pages/providerDetail";
import TermsConditions from "./Pages/termsConditons";
import PrivacyPolicy from "./Pages/privacyPolicy";
import LiveTV from "./Pages/liveTv";
import Footer from "./Components/footer";

export default () => {
  return (
    <div className="ui container">
      <Header />
      <Route path="/">
        <Homepage />
      </Route>
      <Route path="/index.html">
        <Homepage />
      </Route>
      <Route path="/livetv-home">
        <LiveTV />
      </Route>
      <Route path="/provider-details">
        <ProviderDetail />
      </Route>
      <Route path="/player">
        <VideoPlayer />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/otp">
        <OtpScreen />
      </Route>
      <Route path="/plans">
        <PlansList />
      </Route>
      <Route path="/re-widget">
        <TopTen type="shows" />
      </Route>
      <Route path="/partners">
        <AllPartners />
      </Route>
      <Route path="/search">
        <Search />
      </Route>
      <Route path="/menu">
        <NavMenu />
      </Route>
      <Route path="/show-details">
        <ShowDetails />
      </Route>
      <Route path="/planDetails">
        <PlanDetails />
      </Route>
      <Route path="/all-languages">
        <AllLanguage />
      </Route>
      <Route path="/partner-list">
        <PartnerList />
      </Route>
      <Route path="/results">
        <AllSearchResult />
      </Route>
      <Route path="/profile">
        <MyProfile />
      </Route>
      <Route path="/language-detail">
        <LangauageDetail />
      </Route>
      <Route path="/gridListingPage">
        <GridListingPage type="hindi_and_regional_entertainment" />
      </Route>
      <Route path="/terms">
        <TermsConditions />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Footer/>
    </div>
  );
};
