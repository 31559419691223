import React from 'react'

export default function Loader() {
  return (
  <>  <span className="loaderWrap">
    <i><img src="https://images.ottplay.com/static/newImages/new_spinner_mini.svg" alt="" /></i>
  </span>
  <style jsx="true">
{`
  .loaderWrap{  
    display: flex; 
    width:100%;
    justify-content:center;
    color: #836FA5;
  }
  .loaderWrap i{
    width: 60px; 
    display: block; 
    line-height: 0; 
  }

`}
  </style>
  </>
  )
}
