import { useEffect, useState } from 'react';

const Route = ({ path, children }) => {
    // state to track URL and force component to re-render on change
    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    useEffect(() => {
        console.log("first",window.location.pathname,path)
        // define callback as separate function so it can be removed later with cleanup function
        const onLocationChange = () => {
            // if (window.location.pathname == '/player') {
            //     document.getElementById('FooterID').style.display='none'           
            //     document.getElementById('HeaderId').style.display='none'
                
            // }
            // else{
            //     document.getElementById('HeaderId').style.display='flex'
            //     document.getElementById('FooterID').style.display='block'

            // }
            // update path state to current window URL
            setCurrentPath(window.location.pathname);
        }

        // listen for popstate event
        window.addEventListener('popstate', onLocationChange);

        // clean up event listener
        return () => {
            window.removeEventListener('popstate', onLocationChange)
        };
    }, [])

    return currentPath === path
    ? children
    : null;
}

export default Route;