import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import Cookies from "js-cookie";
import { navigate } from "../navigate";
import { fetchData } from "../Api/client";
import Loader from "../Components/loader";
import { apiUrl, paymentGateway, subsApi } from "../site.config";
import { hidePopup, logEvent, popupModal, showPopup } from "../utils/helper";
import Purchasealert from "../Components/purchasAlert";
import Failedalert from "../Components/failedAlert";

var interval ;

export default function PlanDetails() {
  const [couponEligible, setCouponEligible] = useState(false)
  const [loading, setLoading] = useState(false);
  const { description, duration, misscallpay_mobile, jioDetails, name, plan_code, plan_discount, providers, recurring_price, sku_id } = JSON.parse(window?.history?.state?.state)
  let clientId = Cookies.get('clientId') || '';
  let auth = Cookies.get('auth') || '';
  let rendered = useRef(false)
  let cur = useRef(0)
  const [paymentLink, setPaymentLink] = useState('')
  let data = {
    "userid": clientId,
    "displayname": "Ottplay",
    "firstname": "Ottplay",
    "lastname": "Ottplay",
    "plancode": plan_code,
    "coupon": "",
    "consent": 1,
    "redirection_url": "https://www.ottplay.com/plans",
    "couponEncrypted": false,
    "paymentGateway": "JIOPAY"
  }
  useEffect(() => {
    window.scrollTo({ top: 0 })
    // document.getElementById('purchasebtn').focus();
  }, [])
  function PlanEvents() {
    rendered.current = false
    if (process.env.REACT_APP_ENV == 'production') {
      // handle the plan purchase on production
      document.getElementById('purchasebtn').focus();
    } else {
      document.getElementById('purchasebtn').focus();
    }
    document.addEventListener("keydown", handlekey)
  }
  useEffect(() => {
    (() => {
      jioDetails?.jiocouponcode && checkEligibility();
      setTimeout(() => {
        rendered.current = true
        rendered.current && PlanEvents();
      }, 3000)
    })();
    return () => { document.removeEventListener("keydown", handlekey);clearInterval(interval) }
  }, []);

  function checkEligibility() {
    let apiURL = `https://subs-api.ottplay.com/api/v3.1/web/coupon/coupon-eligibility?plan_code=${plan_code}&platform=JIOPHONE`
    let checkEligible = fetch(apiURL, { headers: { client_id: clientId, couponcode: 'OTTMAZA1', Authorization: 'Bearer F421D63D166CA343454DD833B599C' } }).then((r) => r.json())
      .then(function (checkEligible) {
        console.log("planDetails", checkEligible);
        if (checkEligible?.success) {
          setCouponEligible(checkEligible?.couponEligible)
        }
        else {
          setCouponEligible(false)
        }
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });

  }

  const handlekey = (e) => {
    switch (e.key) {
      case "ArrowUp":
        if (cur.current == 1) {
          cur.current = 0;
          document.getElementById('TermCond').classList.remove('activeFont');
          document.getElementById('purchasebtn').focus();
        }
        break;
      case "ArrowDown":
        if (cur.current == 0) {
          cur.current = 1;
          document.getElementById('purchasebtn').blur();
          document.getElementById('TermCond').classList.add('activeFont');
        }
        break;
      case "Enter":
        if (document.activeElement === document.getElementById('purchasebtn')) {
          document.getElementById('purchasebtn').blur();
          logEvent('Payment_CTA_clicked_monthly', 'Payment_CTA_clicked_monthly from plan details page', name, plan_code)
          if (paymentGateway==='misscallpay') {   
            console.log("using misscallpay")         
            initiatePaymentCall();
          }
          else{
            console.log("using jiopay")
            purchasePlan(e);
          }
        }
        else if (cur.current == 1) {
          navigate(e,'/terms');
        }
        break
      default:
        break;
    }
  }

  const purchasePlan = (e={}) => {
    try {
      let headers = {
        'authority': 'api2.ottplay.com',
        'accept': 'application/json, text/plain, */*',
        'accept-language': 'en-US,en;q=0.9',
        'apiversion': '1',
        'auth': 'CV2RTCO7OQPU5QNACTGGICTC55VPDM5MJFQFW3I4GEEGVVA7WNB7AZVSNSWYLYRJS45MUX5AWNK3ZUZINYV5RPYH2IUZ3R4RCH7BOJY=',
        'client_id': clientId,
        'content-type': 'application/json;charset=UTF-8',
        'devicetype': 'JIO_PHONE',
        'origin': 'https://www.ottplay.com',
        'pc_type': '0',
        'plan_code': 'NA',
        'referer': 'https://www.ottplay.com/plans?activePlan=ott_power_play_monthly',
        'sec-ch-ua': '"Google Chrome";v="117", "Not;A=Brand";v="8", "Chromium";v="117"',
        'sec-ch-ua-mobile': '?0',
        'sec-ch-ua-platform': '"macOS"',
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'cors',
        'sec-fetch-site': 'same-site',
        authorization: "bearer 34B7FBD24B97D7A4CF717D414F2A8",
        'user-agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36',
      }
      let checkRegisterUrl = `${subsApi}api/payment-service/v4.5/web/purchase`
      let registerResponse =fetch(checkRegisterUrl, { headers: headers, method: "POST", body: JSON.stringify(data) }).then((r) => r.json())
      .then(function(response){
        if (response && response?.success) {
          
          var activity = new window.MozActivity({
            name: "billpay",
            origin:"5d0ea85c269ef1f200f1a6aaf1bdaf6458edc5f1",
            data: {
              type: "text/plain",
              key: "sdk",
              orderInfo: {
                skuId: parseInt(sku_id),
                orderId: response?.order_id
              }
            },
          });
          
          activity.onsuccess = () => {
            console.log( "Pyement Successs");
            setLoading(true)
            purchaseCallback(response?.order_id);
          }
          activity.onerror = () => {
            console.log("Error occurred in payment");
          }
        }
        else{
          
        }
      })
      .catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });


    }
    catch (error) {
      console.error('Error in reCAPTCHA execution:', error);
    }
  }

  const purchaseCallback = (orderId) => {
    let headers = {
      'authority': 'subs-api.ottplay.com',
      'auth': auth,
      'client_id': clientId,
      'content-type': 'application/json;charset=UTF-8',
      'devicetype': 'JIO_PHONE',
      'origin': 'https://www.ottplay.com',
      'pc_type': '0',
      'plan_code': plan_code,
      'sec-fetch-mode': 'cors',
      'sec-fetch-site': 'same-site',
      authorization: "bearer 34B7FBD24B97D7A4CF717D414F2A8",
    }

    let callbackdata = {
      planCode: plan_code,
      order_id: orderId,
      order_status:"payment_captured"
    }
    let checkRegisterUrl = `${subsApi}api/payment-service/v4.5/web/purchase/create`
    let callbackResponse = fetch(checkRegisterUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(callbackdata)
        }).then((r) => r.json())
    .then(function(response){

      if (response?.subscription_id.length) {
        setLoading(false)
        navigate({},'/');
      }
      else{
        document.getElementById("mobileoverlayfailed").style.display = "block";
        setTimeout(() => {
          document.getElementById("mobileoverlayfailed").style.display = "none";
          navigate({}, '/');
        }, 3000);
      }
    })
    .catch((e)=>{
      document.getElementById("mobileoverlayfailed").style.display = "block";
      setTimeout(() => {
        document.getElementById("mobileoverlayfailed").style.display = "none";
        navigate({},'/');
      }, 3000);
      console.log("Getting Error",e)
    })
  }

  const initiatePaymentCall = () => {
    // Create a new MozActivity instance
    var activity = new window.MozActivity({
      name: "dial",
      data: {
        number: `${misscallpay_mobile}`  // Replace with the desired phone number
      }
    });

    // Success handler
    activity.onsuccess = function () {
      console.log("Activity completed successfully");
      popupModal("IVR call process is Initiated",60000)
      interval = setInterval(function () {
        fetch(`${apiUrl}/v4.5/mobile/plansubscribe/getusersubscription`, { headers: { client_id: clientId, 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } })
          .then(function (response) {
            // The API call was successful!
            return response.json();
          }).then(function (subsData) {
            // This is the JSON from our response
            console.log("subsData", subsData);
            if (subsData?.plans?.length) {
              hidePopup();
              navigate({},'/')
              clearInterval(interval);
            }
          }).catch(function (err) {
            // There was an error
            console.warn('Something went wrong.', err);
          });
      }, 9000);
    };

    // Error handler
    activity.onerror = function () {
      console.error("An error occurred during the activity");
    };

  }

  

  return (
    <>
      {/* <Header /> */}
      <div className="planDetailsWrap">
        <div className="planDetailBanner">
          {couponEligible ? <div className="bannerContent">
            You are eligible for <strong>{name}</strong> at just Re {jioDetails?.jiopromoprice} for the first month. Thereafter it will be Rs {plan_discount?.price_after_discount} per month.
          </div> : null}
        </div>
        <div className="planDetailHeading">{name}</div>
        <div className="planDetailDesc">{description}</div>
        <ul className="planDetailPartners">
          {providers?.length && providers.map((item, id) => {
            return (
              <li><img src={item?.logo_url} /></li>

            )
          })}
        </ul>
        {/* <div className="instructionHeading">This is an IVR based payment process.</div> */}
        {/* <div className="planDetailDesc">You will receive a call back within a minute for payment processing.Please follow the instructions given on call</div> */}
        {couponEligible ? <button id="purchasebtn" className="upgradeBtn"><div className="planAmount">
          ₹ {jioDetails?.jiopromoprice} <span>first month,thereafter ₹ {plan_discount?.price_after_discount} per month</span>
        </div></button> :
          <button id="purchasebtn" className="upgradeBtn"><div className="planAmount" style={{ fontSize: '12px' }}>
            ₹ {plan_discount?.price_after_discount} per month
          </div></button>}
        <div className="planDetailsTerms">
          By subscribing plans, you agree to our <span className="terms" id="TermCond">Terms & Conditions</span>
        </div>
      </div>
      <Failedalert />
      <Purchasealert />
      {loading ? <div className="loaderPlan"><Loader /></div> : null}
      {/* <Footer footerValue = ''  navigateTo = '' /> */}
      <style jsx>
        {`
          .planDetailsWrap {
            padding: 35px 7px 30px;
          }
          // .planDetailsWrap button {
          //   margin: 0;
          //   height: 30px;
          //   width: 100%;
          //   background-color: #03f87e;
          //   padding: 4px 7px;
          //   border-radius: 2px;
          //   line-height: 14px;
          //   font-size: 12px;
          //   font-weight: 700;
          //   color: #270048 !important;
          //   border: 0;
          // }
          .planDetailBanner{
            padding-left:15px;
          }
          .bannerContent{
            padding:5px 10px 5px 23px;
            color:#D5C6F3;
            font-size:10px;
            font-weight:300;
            line-height:14px;
            background-color:#3F236E;
            border-radius:3px;
            position:relative;
          }
          .bannerContent::before{
            content:'';
            position:absolute;
            left:-16px;
            top:50%;
            transform:translateY(-50%);
            width:33px;
            height:33px;
            background: url(https://images.ottplay.com/static/jioImages/bannerBellIcon.svg) no-repeat;
            background-size: 33px;
          }
          .planDetailHeading{
            font-size:12px;
            font-weight:700;
            line-height:17px;
            color:#ffffff;
            padding-top:9px;
          }
          .instructionHeading{
            font-size:12px;
            font-weight:700;
            line-height:17px;
            color:#ffffff;
            padding-top:3px;
          }
          .planDetailDesc{
            font-size:10px;
            font-weight:300;
            line-height:14px;
            color:#9989B5;
            padding-bottom:9px;
          }
          .planDetailPartners{
            padding: 0 0 2px;
            margin:0;
            list-style-type:none;
            display:flex;
            align-items:flex-start;
            justify-content:flex-start;
            grid-gap:4px;
            flex-wrap:wrap;
          }
          .planDetailPartners li{
            flex: 0 0 23%;
            border-radius:2px;
          }
          .planDetailPartners li img{
            max-width:48px;
            border-radius:2px;
          }
          .planPricingWrap{
            padding: 4px 0 7px 0;
            border-top: 1px solid;
            border-bottom: 1px solid;
            border-color:rgba(236, 229, 250, 0.3);
          }
          .planAmount{
            font-size: 22px;
            line-height: 18px;
            color: black;
            font-weight: 700;
          }
          .planAmount > span{
            font-size:9px;
            vertical-align:text-top;
          }
          .planAmountDesc{
            font-size:10px;
            line-height:12px;
            color:#9989B5;
            font-weight:300;
          }
          .planDetailsTerms{
            padding:4px 0 10px;
            font-size:8px;
            line-height:12px;
            color:#9989B5;
          }
          .upgradeBtn{
            width:100%;
            position:relative;
            font-size:12px;
            font-weight:700;
            color:#ffffff;
            line-height:15px;
            padding: 9px;
            border:0;
            border-radius:4px;
            background-color:#521384;
            outline:0;
            box-shadow:none;
            margin-top:5px;
            text-align:left;
        }
        .upgradeBtn::after{
            content:'';
            position:absolute;
            top:50%;
            right:10px;
            transform:translateY(-50%);
            background:url(https://images.ottplay.com/static/images/blackRightArrow.png) no-repeat;
            background-size:20px;
            width:20px;
            height:20px;
        }
        #purchasebtn:focus {
            color: #270048;
            background: #29F87E;
            border:2px solid #29F87E;
            outline:none;
        }
        #purchasebtn:focus planAmount{
          color: #270048;
          background: #29F87E;
          border:2px solid #29F87E;
          outline:none;
      }
        .loaderPlan{
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0px;
        background-color: #270048;
        display: flex;
        justify-content: center;
        align-items: center;
        }
        .terms{
          text-decoration: underline;
          cursor: pointer;
        }
        .activeFont {
          font-weight: 700;
          color: rgb(2, 248, 126);
        }
        `}
      </style>
    </>
  );
}
