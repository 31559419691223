import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import { navigate } from "../navigate";
import { UserContext } from "../Contexts/userContext";
import Cookies from "js-cookie";
import { removeCookies, removeLocal } from "../utils/cookieHelper";

export default function NavMenu() {
  const rendered = useRef(null);
  const cur = useRef(0);
  const [loggedIn, setLoggedIn] = useState(Cookies.get('logged_in') || false)
  const User = useContext(UserContext);
  const [planName, setPlanName] = useState(User?.user?.planName || "Subscribe");
  useEffect(() => {
    rendered.current = true;
    rendered.current && menuEvents();
    return () => document.removeEventListener('keydown', handleMenukey)
  }, []);

  let viewAll;

  function menuEvents() {
    rendered.current = false;
    viewAll = document.querySelectorAll("[nav-selectable='true']");
    if (viewAll) {
      viewAll[cur.current].style["background-color"] = "#02F87E";
      viewAll[cur.current].scrollIntoView(false)
      viewAll[cur.current].style["color"] = "#270048";
      viewAll[cur.current].scrollIntoView(false)
      document.addEventListener("keydown", handleMenukey);
    }
  }

  const handleMenukey = (e) => {
    if (e.key === "Backspace") return
    if (e.key === "ArrowUp") {
      if (cur.current === 0) return;
      viewAll[cur.current].style.removeProperty("background-color");
      viewAll[cur.current].style.removeProperty("color");
      viewAll[cur.current - 1].style["background-color"] = "#02F87E";
      viewAll[cur.current - 1].style["color"] = "#270048";
      if (cur.current - 1 > 3) {
        viewAll[cur.current - 1].scrollIntoView(true)
      }
      else viewAll[cur.current - 1].scrollIntoView(false)
      if (cur.current !== 0) cur.current = cur.current - 1;
    }

    if (e.key === "ArrowDown") {
      if (cur.current === viewAll.length - 1) return;
      viewAll[cur.current].style.removeProperty("background-color");
      viewAll[cur.current].style.removeProperty("color");
      if (cur.current !== viewAll.length - 1) cur.current = cur.current + 1;
      viewAll[cur.current].style["background-color"] = "#02F87E";
      viewAll[cur.current].style["color"] = "#270048";
      viewAll[cur.current].scrollIntoView(false)
    }
    if (e.key === "Enter") {
      let title = viewAll[cur.current].getAttribute("to");
      if (title === '/logout') {
        removeCookies('logged_in', 'clientId', 'auth', 'subscribed')
        removeLocal('logged_in', 'clientId', 'auth','value')
        // User?.setUser({...User.user,'planName':'Subscribe'})    
        User.setUser(Object.assign({}, User.user, { 'planName': 'Subscribe' }))

        navigate(e, '/')
      }
      else {
        navigate(e, `${title}`);
      }
    }
  };
  return (
    <>
      {/* <Header page={'menu'} /> */}
      <div className="menuWrap">
        <ul className="navigationMenu">
          {!loggedIn ? (
            <a>
              <li className="navigationLink" nav-selectable="true" to="/login">
                Login
                <span>Press 0</span>
              </li>
            </a>
          ) : null}
          <a >
            <li className="navigationLink" nav-selectable="true" to="/">
              Home
              <span>Press 1</span>
            </li>
          </a>
          <a>
            <li
              className="navigationLink"
              nav-selectable="true"
              to="/livetv-home"
            >
              Live TV
              <span>Press 2</span>
            </li>
          </a>
          <a >
            <li
              className="navigationLink"
              nav-selectable="true"
              to={planName === "Subscribe" ? "/partners" : "/partner-list"}
            >
              OTT Partners{" "}
              <span>Press 3</span>
            </li>
          </a>
          <a >
            <li
              className="navigationLink"
              nav-selectable="true"
              to="/all-languages"
            >
              Languages
              <span>Press 4</span>
            </li>
          </a>
          {loggedIn ? (
            <a >
              <li
                className="navigationLink"
                nav-selectable="true"
                to="/profile"
              >
                My Profile
                <span>Press 5</span>
              </li>
            </a>
          ) : null}
          {loggedIn ? <a>
            <li
              className="navigationLink"
              nav-selectable="true"
              to="/logout"
            >
              Logout
            </li>
          </a> : null}
        </ul>
        <h6>APP Version : 1.0.0.4</h6>
      </div>
      {/* <Footer /> */}
      <style jsx="true">
        {`
          .menuWrap {
            background-color: #270048;
            padding: 50px 15px 53px;
          }
          .menuWrap h6 {
            margin: 0px;
            margin-left: 5px;
            color: #ffffff;
            font-weight: 400;
          }
          .navigationMenu {
            padding: 0;
            margin: 0;
            width: 100%;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            
          }
          .navigationMenu a {
            text-decoration: none;
            margin-bottom:10px;
          }
          .navigationLink {
            background-color: #4d266e;
            border-radius: 6px;
            padding: 15px;
            color: #ffffff;
            font-size: 14px;
            line-height: 19px;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
          }
          .navigationLink a {
            text-decoration: none;
            color: #ffffff;
            font-size: 14px;
            line-height: 19px;
            font-weight: 700;
          }
          .navigationLink span {
            font-size: 10px;
            font-weight: 500;
          }
          .navigationLink:hover {
            background-color: #02f87e;
          }
          .navigationLink:hover a {
            color: #270048;
          }
        `}
      </style>
    </>
  );
}
