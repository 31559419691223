import React, { useEffect, useState, useRef } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import Image from "../Components/Image";
import { navigate } from "../navigate";
import Cookies from "js-cookie";
import { apiUrl } from "../site.config";
import Loader from "../Components/loader";
import { activeBtnColor, logEvent } from "../utils/helper";

const GridListing = ({ type = "", parent = 'Home' }) => {
  let { end_point = '', section = '', template_name = '', title = '', page = 'Home', seo_url = '', pin_it = true } = window?.history?.state;
  const currentRef = useRef(false);
  const cur = useRef(0);
  const pageLimit = useRef(1)
  const [Page, setpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState('item_0')
  const [totalCountNumber, setTotalCountNumber] = useState(0)
  const [loadMore, setLoadMore] = useState(false);
  let logged_in = Cookies.get('logged_in') || false;
  let client_id = Cookies.get('clientId')
  useEffect(() => {
    (() => {
      setLoading(true)
      getData();
    })()
    return () => { document.removeEventListener("keydown", handlekey) }
  }, []);

  const handleActiveBorder = () => {
    currentRef.current = false
    let v = document.getElementById(selectedItem);
    if (v) {
      v.classList.add('active')
      document.addEventListener("keydown", handlekey);
    }
  }

  const handlekey = (e) => {
    e.preventDefault();
    let keyName = e.key;
    let id = document.querySelector('.active')?.id;
    let licount = document.querySelectorAll('li').length;
    if (id) {
      let eleId = parseInt(document.querySelector('.active').id.split('_')[1]);
      switch (keyName) {
        case "ArrowRight":
          if (eleId < licount - 1) {
            handlHorizontal(eleId, eleId + 1)
          }
          break;
        case "ArrowLeft":
          if (eleId > 0) {
            handlHorizontal(eleId, eleId - 1)
          }
          break;
        case "ArrowUp":
          if (eleId > 2) {
            handlHorizontal(eleId, eleId - 3)
          }
          else if (cur.current === 0) {
            document.getElementById(`item_${eleId}`).classList.remove('active')
            let headerNav = document.querySelectorAll('[head-selectable]')[0];
            headerNav.style.outline = `2px solid ${activeBtnColor}`
            cur.current -= 1;
          }
          break;
        case "ArrowDown":
          if (eleId < licount - 3) {
            handlHorizontal(eleId, eleId + 3)
          }
          else if (document.getElementById('loadmrbtn')) {
            document.getElementById(`item_${eleId}`).classList.remove('active')
            document.getElementById('loadmrbtn').focus();
          }
          else if (eleId < licount - 2) {
            handlHorizontal(eleId, eleId + 2)
          }
          else if (eleId < licount - 1) {
            handlHorizontal(eleId, eleId + 1)
          }

          break;
        case "Enter":
          let btn = document.getElementById('loadmrbtn');
          if (document.activeElement === btn) {
            btn.blur();
            btn.style.removeProperty('border');
            document.querySelectorAll('li')[licount - 1].classList.add('active')
            getData();
          } else {

            handleOk(id,e);
          }
          break;

        default:
          break;
      }
    }
    else {
      let btn = document.getElementById('loadmrbtn');
      switch (keyName) {

        case "ArrowRight":
          if (cur.current === -2) return;
          if (cur.current === -1) {
            let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
            let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
            headerNavSubs.style.removeProperty('box-shadow')
            headerNavSubs.style.removeProperty('outline')
            headerNavProfile.style.boxShadow = `0px 0px 6px #ffffff`;
            cur.current -= 1;
          }
          break;
        case "ArrowLeft":
          if (document.activeElement === btn) {
            btn.style.removeProperty("border")
            btn.blur();
            document.querySelectorAll('li')[licount - 1].classList.add('active')
            document.querySelectorAll('li')[licount - 1].scrollIntoView(false)
          }
          else if (cur.current === -2) {
            let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
            let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
            headerNavProfile.style.removeProperty('box-shadow')
            headerNavSubs.style.boxShadow = `0px 0px 6px #ffffff`;
            cur.current += 1;
          }
          break;
        case "ArrowUp":
          if (document.activeElement === btn) {
            btn.style.removeProperty("border")
            btn.blur();
            document.querySelectorAll('li')[licount - 1].classList.add('active')
          }
          break;
        case "ArrowDown":
          if (cur.current === -1 || cur.current === -2) {
            let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
            if (headerNav) {
              headerNav.style.removeProperty("box-shadow");
              headerNav.style.removeProperty("outline");
            };
            cur.current = 0;
            document.querySelectorAll('li')[0].classList.add('active')

            return
          };
          break;
        case "Enter":
          if (document.activeElement === btn) {
            btn.blur();
            btn.style.removeProperty('border');
            document.querySelectorAll('li')[licount - 1].classList.add('active')
            getData();
            document.querySelectorAll('li')[licount - 1].scrollIntoView(false)
          }
          if (cur.current == -1) {
            let headerNavSubs = document.querySelectorAll('[head-selectable]')[0].title;
            if (headerNavSubs) {
              navigate(e,`${headerNavSubs}`)
            }
            return;
          }
          else if (cur.current === -2) {
            let headerNavProfile = document.querySelectorAll('[head-selectable]')[1].title;
            if (headerNavProfile) {
              navigate(e,`${headerNavProfile}`)
            }
            return;
          }
          break;

        default:
          break;
      }
    }
  }


  const handleOk = (id,e={}) => {
    if (type === "partners") {
      // Cookies.set('providersId',document.getElementById(id).getAttribute('seo_url'))
    }
    let state = {
      content_type: document.getElementById(id).getAttribute('contenttype'),
      ottplay_id: document.getElementById(id).getAttribute('ottplay_id'),
      provider: document.getElementById(id).getAttribute('providers'),
      _id: document.getElementById(id).getAttribute('_id'),

    }
    let showstate = {
      seo_url: document.getElementById(id).getAttribute('seo_url')
    }
    let path = document.getElementById(id).getAttribute('to');
    let action = `${document.getElementById(id).getAttribute('contenttype')}/${document.getElementById(id).getAttribute('name')}`
    logEvent(`${state?.content_type}_click`, action, `${document.getElementById(id).getAttribute('ottplay_id')}/${document.getElementById(id).getAttribute('providers')}`)
    path.includes('/player') ? navigate(e,path, state) : navigate(e,path, showstate);
  }
  const handlHorizontal = (currentId, actionId, dir) => {
    document.getElementById(`item_${currentId}`).classList.remove('active')
    document.getElementById(`item_${actionId}`).classList.add('active')
    document.getElementById(`item_${actionId}`).scrollIntoView(false)
    setSelectedItem(`item_${actionId}`)
  }


  const getData = () => {
    setpage((prev) => prev + 1)
    end_point = end_point.slice(0, 1) == '/' ? end_point : `/${end_point}`
    // let result =  fetchData(`${apiUrl}${end_point.trim()}?limit=9&pin_it=${pin_it}&template_name=${template_name}&section=${section}&page=${pageLimit.current}&module_name=${page == 'live' ? 'livetv' : page == 'provider' ? seo_url : 'Home'}`, logged_in ? {
    fetch(`${apiUrl}${end_point.trim()}?limit=9&pin_it=${pin_it}&template_name=${template_name}&section=${section}&page=${pageLimit.current}&module_name=${page == 'live' ? 'livetv' : page == 'provider' ? seo_url : 'Home'}`, {
      headers: {
        'client_id': client_id || '', 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8"
      }
    }).then(function (response) {
      // The API call was successful!
      return response.json();
    }).then(function (result) {
      // This is the JSON from our response
      console.log("gridlisting", result);
      setTotalCountNumber(result?.totalCount)
      setTimeout(() => {
        handleActiveBorder();
        setLoading(false)
      }, 1000);
      if (result?.data?.length && Array.isArray(result?.data)) {
        pageLimit.current = pageLimit.current + 1;
        setpage(Page + 1)
        if (result?.data?.length < 9) setLoadMore(true);
        setData((prev) => [...prev, ...result?.data]);
      } else {
        setData((prev) => [...prev, ...data || []]);
      }
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });
  };

  return (
    <>
      {/* <Header /> */}
      <div className="partnerWrap">
        <h2>{title}</h2>
        {Array.isArray(data) && data?.length ? (
          <>

          <ul className="partnerReasult">
            {data?.map((data, id) =>
              <li id={`item_${id}`} contenttype={data?.content_type || ''} name={data?.name} ottplay_id={data?.ottplay_id || ''} providers={data?.content_type !== "provider" ? data?.where_to_watch[0]?.provider || '' : ''} seo_url={data?.seo_url || ''} to={data?.content_type !== "provider" ? `${[data["content_type"]]}` === 'show' ? `/show-details?seo_url=${data?.seo_url}&subscriptionType=${data?.where_to_watch[0]?.subscription_type}&name=${data?.name}` : `/player?content_type=${data?.content_type}&ottplay_id=${data?.ottplay_id}&provider=${data?.where_to_watch[0]?.provider}&subscriptionType=${data?.where_to_watch[0]?.subscription_type}&_id=${data?._id || ''}&name=${data?.name}` : `/provider-details?seo_url=${data?.seo_url}`} key={id} data-content_type={data?.content_type} ref={currentRef} className="">
                <a>
                  <Image src={data?.content_type === "provider" ? data?.logo_url : data?.posters} width="71" height={`${data?.content_type === "provider" ? "45" : "104"}`} className="potraitImg" />
                </a>
              </li>
            )}
          </ul>
        {!loadMore && (data?.length < totalCountNumber) ? (
          <button
          id="loadmrbtn"
          onClick={getData}
          style={{ color: "black", marginBottom: "30px" }}
          >
            Load More{" "}
          </button>
        ) : null}
        </>
            ) : <div className="noContent">No Content Available </div>}
      </div>
      {loading ? <div className="loaderPlan"><Loader /></div> : null}


      {/* <Footer /> */}

      <style jsx="true">{`
        .partnerWrap {
          background: #270048;
          width: 100%;
          height: calc(100vh - 35px);
          padding: 40px 0px 80px 0px;
          box-sizing: border-box;
          overflow: scroll;
          
        }
        .partnerWrap{
          -ms-overflow-style: none; 
          scrollbar-width: none;
        }
        .partnerWrap::-webkit-scrollbar {
          display: none;
        }
        .noContent {
          color: #e9e4ee;
          text-align: center;
        }
        
        .partnerWrap h2 {
          margin: 0;
          color: #fff;
          font-size: 15px;
          margin-bottom: 10px;
          font-weight: 600;
        }
        .partnerWrap .partnerReasult {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          justify-content: flex-start;         
          flex-wrap: wrap;
        }
        .partnerWrap .partnerReasult li {
          margin-bottom: 8px;
          border-radius: 3px;
          overflow: hidden;
          margin-right:5px;
          flex: 0 0 31%;
        }
        .partnerWrap .partnerReasult li a {
          width: 71px;
          height: ${template_name === "providers" ? "45px" : "104px"};
          display: block;
          border-radius: 3px;
        }
        .partnerWrap .partnerReasult li a >span{
          display:inline !important;
        }
        .partnerWrap .partnerReasult li img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .partnerWrap .partnerReasult li img.landscapeImg{
          height:40px;
        }
        .partnerWrap .partnerReasult li img.potraitImg{
            height:107px;
        } 
        .partnerWrap button {
          margin: 0;
        height: 30px;
        width: 100%;
        background-color: #521384;
        padding: 4px 7px;
        border-radius: 2px;
        line-height: 14px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff !important;
        border: 1px solid #6413A7;
        }
        .partnerWrap button:focus{
          color: #270048 !important;
          background-color: #29F87E;
          border: 0 !important;
          outline: none !important;
        }
      `}</style>
    </>
  );
};

export default GridListing;
