import React, { useEffect, useState } from "react";
import Image from "../Components/Image";
import Title from "../Components/titleHeading";
import { fetchData } from "../Api/client";
import { apiUrl } from "../site.config";
import Cookies from "js-cookie";

function LangCarousel({ item = {}, uniqueId = '', longWidget = false }) {
  const { title, pin_it, end_point, template_name, section, content_type } = item;
  let height = longWidget ? '103' : '44';
  let client_id = Cookies.get("clientId") || ''


  const [widgetData, setWidgetData] = useState([])

  useEffect(() => {
    console.log("lang carousel")
    getLangData();
  }, [])



  function getLangData() {
   fetch(`${apiUrl}/re-service/v1.3/web/language/personalized-languages?limit=4&pin_it=${pin_it}&template_name=${template_name}&section=${section}&page=1&module_name=Home`, { headers: { 'client_id': client_id||'', 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } }).then(function (response) {
      return response.json();
    }).then(function (data) {
      console.log("langcarousel", data);
      if (Array.isArray(data?.rank) && data?.rank.length) {
        setWidgetData(data?.rank.slice(0, 3));
      } else {
        setWidgetData(data?.rank);
      }
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });

    // if (Array.isArray(result?.rank) && result?.rank.length) {
    //   setWidgetData(result?.rank.slice(0, 3));
    // } else {
    //   setWidgetData(result?.rank);
    // }
  }

  return ((Array.isArray(widgetData) && widgetData.length) ?
    <>
      <div className='fixedContainer'>
        <Title title={title} template_name={template_name} section={section} end_point={end_point} />
        <div className='cardContainer' id={uniqueId} home-selectable="true">
          <ul className="allLanguageList">
            {widgetData && widgetData.map((item, id) => {
              return (
                <li key={id} to={`/language-detail?languageId=${item?.language?._id}`} id={`item_${id}`} lang-id={item?.language?._id || '5f510b5fc5916e1534b5cd35'} className="languageSLideBox" widget-selectable={uniqueId}>
                  <h2>{item.language?.icon_text || ""}</h2>
                  <p>{item.language?.name || ""}</p>
                </li>
              )
            })}

          </ul>
        </div>
      </div>
      <div className='space'></div>
      <style jsx="true">
        {`
  .cardContainer{
      display: flex;
      align-items:center;
      padding:0 8px;
  }
  .cardContainer a{
      margin-right:4px;
      border-radius:4px;
  }
  .cardContainer a span{
    display:flex!important;

}
  .card{
      width:33%;
  }
  .allLanguageList {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .languageSLideBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #582484;
    border-radius: 3px;
    text-align: center;
    width: 72px;
    padding: 5px 0;
    margin-right: 4px;
  }
  .languageSLideBox.activeLanguage {
    border: 1px solid #29f87e;
  }
  .languageSLideBox h2 {
    margin: 0;
    font-size: 24px;
    line-height: 9px;
    color: #ffffff;
    font-weight: 700;
    width: 33px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .languageSLideBox.activeLanguage h2 {
    background-color: #29f87e;
    color: #270048;
  }
  .languageSLideBox p {
    margin: 0;
    font-size: 10px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 300;
    padding-top: 0;
  }
  .languageSLideBox.activeLanguage p {
    color: #29f87e;
    font-weight: 700;
  }
  .cardContainer img{
      border-radius:2px;
  }
  
`}
      </style>
    </> : null
  );
}

export default LangCarousel;
