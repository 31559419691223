import { createContext, useState } from "react";
// import { getCookies } from "../utils/cookieHelper";
export const UserContext = createContext();

export default function UserProvider({ children }){
  
  
  let token = false ,logged_in=false
  const [auth, setAuth] = useState(token ? true : null);
  const [user, setUser] = useState({
    planName:'Subscribe',
    paragraph1:"Some contents of your",
    paragraph2:"subscription package are not compatible with JioPhone",
    packname: ""
  });
  
  return (
    <>
      <UserContext.Provider value={{user,setUser,auth,setAuth,logged_in} }>{children}</UserContext.Provider>
    </>
  );
};
